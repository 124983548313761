<template>
    <div class="d-flex flex-column flex-root">
        <div class="page d-flex flex-row flex-column-fluid">
            <Menu></Menu>
            <div class="wrapper d-flex flex-column flex-row-fluid">
                <Header></Header>
                <div id="kt_content" class="container-xxl">
                    <div class="card">
                        <div class="card-header border-0 pt-6">
                            <div class="fv-row mb-7 text-start">
                                <!--begin::Label-->
                                <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <select class="form-select form-select-solid" data-dropdown-parent="#kt_content"
                                    data-placeholder="Seleccione Sucursal" multiple="multiple"
                                    data-close-on-select="false" name="sucursal[]" id="sucursal" v-model="sucursal"
                                    disabled style="width: max-content !important;">
                                    <option></option>
                                    <option v-for="(lstsucursal, index) in listado_sucursal" :key="index"
                                        v-bind:value="lstsucursal.SucursalID">
                                        {{ lstsucursal.Nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="fv-row text-start">
                                <label class="form-label">Fecha:</label>
                                <label id="fechaini" style="display:none"></label>
                                <label id="fechafini" style="display:none"></label>
                                <input class="form-control form-control-solid" placeholder="Ingrese la fecha" id="fecharango" name="fecharango" />
                            </div>
                            <div class="fv-row mb-7 text-bottom my-9">
                                <button type="button" class="btn btn-primary" @click="obtenerIngreso()">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    Filtrar
                                </button>
                            </div>
                            <div class="fv-row mb-7 text-bottom my-9">
                                <button type="button" class="btn btn-primary" @click="accionsaldo('', 'mAgregar')">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->Agregar Ingreso
                                </button>
                            </div>
                            <div class="modal fade" id="kt_modal_pago" tabindex="-1" aria-hidden="true"
                                data-bs-backdrop="static" data-bs-keyboard="false">
                                <div class="modal-dialog modal-dialog-centered mw-650px">
                                    <Ingresomodal v-if="showModal" :Modal="this.TipoModal"
                                        :ListaIngreso="this.lista_ingreso">
                                    </Ingresomodal>
                                </div>
                            </div>
                        </div>
                        <div class="card-body py-4">
                            <div class="table-responsive container">
                                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                    <thead>
                                        <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th class="min-w-125px text-center" scope="col">Sucursal</th>
                                            <th class="min-w-125px text-center" scope="col">Tipo Ingreso</th>
                                            <th class="min-w-125px text-center" scope="col">Nombre</th>
                                            <th class="min-w-125px text-center" scope="col">Descripcion</th>
                                            <th class="min-w-125px text-center" scope="col">Monto</th>
                                            <th class="min-w-100px text-center" scope="col">Fecha</th>
                                            <th class="min-w-100px text-center" scope="col">Accion</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 fw-semibold">
                                        <tr v-for="(listingreso, index) in lista_datosingreso" :key="index">
                                            <td class="text-center">{{ listingreso.NombreSucursal }}</td>
                                            <td class="text-center">{{ listingreso.NombreTipoIngreso }}</td>
                                            <td class="text-center">{{ listingreso.Nombre }}</td>
                                            <td class="text-center">{{ listingreso.Descripcion }}</td>
                                            <td class="text-center">{{ listingreso.Monto }}</td>
                                            <td class="text-center">{{ getDateFormat(listingreso.FechaRecepcion) }}</td>
                                            <td class="text-end min-w-100px">
                                                <div class="d-flex justify-content-center flex-shrink-0">
                                                    <button type="button"
                                                        class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1"
                                                        @click="accionsaldo(listingreso, 'mEditar')">
                                                        <!-- <i class="fas fa-trash"></i> -->
                                                        <i class="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                    <button type="button" title="Eliminar Pago"
                                                        class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1"
                                                        @click="eliminarIngreso(listingreso.IngresoID,index)">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import Ingresomodal from '@/components/Ingresomodal.vue'
import axios from 'axios';
export default {
    name: "Ingreso",
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            TOKEN: '',
            MODIFICADOR: '',
            TipoModal: "",
            showModal: false,
            sucursal: [],
            fechastart: "",
            fechaend: "",
            listado_sucursal: [],
            lista_datosingreso: [],
            sucursal: [],
            lista_ingreso: {},
        }
    },
    components: {
        Header,
        Menu,
        Ingresomodal,
    },
    mounted() {
        this.obtenerPermiso();
        this.obtenerSucursal();
      
    },
    created() {
        this.validacionStorage();
    },
    computed: {

    },
    methods: {
           obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
        validacionStorage() {
            this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
            this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
            if (this.TOKEN === null) {
                window.location.href = "/login"
            }
        },
        getDateFormat(fecha) {
            return moment(fecha).format("YYYY-MM-DD ");
        },
        fechafiltro() {
            this.fechastart = document.getElementById('fechaini').innerHTML;
            this.fechaend = document.getElementById('fechafini').innerHTML;
        },
        fechaoriginal() {
            var start = moment().subtract(7, 'days');
            var end = moment();
            function cb(start, end) {
                $('#fechaini').html(start.format('YYYY-MM-DD'));
                $('#fechafini').html(end.format('YYYY-MM-DD'));
            }
            $('#fecharango').daterangepicker({
                startDate: start,
                endDate: end,
            }, cb)
            cb(start, end);
            this.fechastart = document.getElementById('fechaini').innerHTML;
            this.fechaend = document.getElementById('fechafini').innerHTML;
        },
        obtenerIngreso() {
            this.fechafiltro();
            var sucursal = this.sucursal.toString();
            axios.post(this.BASE_URL + "/ingreso/obtener", { SucursalID: sucursal, FechaInicial: this.fechastart, FechaFinal: this.fechaend }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.lista_datosingreso = response.data.respuesta;
                });
        },
        obtenerSucursal() {
            this.sucursal.push(JSON.parse(localStorage.getItem('sucursal-ID')));
            axios.get(this.BASE_URL + "/sucursal/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_sucursal = response.data.respuesta;
                    this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
                    this.fechaoriginal();
                      this.obtenerIngreso();
                    if (this.RolUser === "Administrador") {
                        $('#sucursal').removeAttr('disabled');
                    }
                    $("#sucursal")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                                multiple: true
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                });
        },
        accionsaldo(lstingreso, modo) {
            this.TipoModal = modo;
            this.lista_ingreso = lstingreso;
            //console.log(this.lista_ingreso);
            this.showModal = true;
            $('#kt_modal_pago').modal('show')
        },
        eliminarIngreso(IngresoID, indice) {
            axios.post(this.BASE_URL + "/ingreso/eliminar", {
                IngresoID: IngresoID,
                UsuarioID: this.MODIFICADOR,
            }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.lista_datosingreso.splice(indice, 1);
                    //window.location.href = "/usuario"
                });
        }
    },
}
</script>