<template>
  <!-- modal usuario -->

     	   <div class="modal-content">
    <!--begin::Modal header-->
    <div class="modal-header" style="height:60px" id="kt_modal_edit_user_header">
      <!--begin::Modal title-->
      <h2 v-if="dTipoModal == ModalEditar" class="fw-bold">Modificar Venta</h2>
      <h2 v-if="dTipoModal == ModalAgregar" class="fw-bold">Agregar Venta</h2>
      <h2 v-if="dTipoModal == ModalDevolucion" class="fw-bold">Devolución</h2>
      <!--end::Modal title-->
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Close-->
    </div>
    <!--end::Modal header-->
    <!--begin::Modal body-->
    <div class="modal-body scroll-y mx-5 mx-xl-5 my-0">
      <!--begin::Form-->
      <form id="kt_modal_edit_user_form" class="form" action="#">
        <!--begin::Scroll-->
        <div
          class="d-flex flex-column scroll-y me-n0 pe-3"
          id="kt_modal_edit_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_edit_user_header"
          data-kt-scroll-wrappers="#kt_modal_edit_user_scroll"
          data-kt-scroll-offset="450px"
          style="max-height: 450px !important"
        >

        <div class="">
                  <div class="row">
                            	<div class="col-md-4 align-items-center position-relative  text-start pe-5 ">
                                <label class="fw-semibold fs-6 mb-2">Sucursal:</label>
                                <select class="form-select form-select-solid" data-dropdown-parent="#kt_accordion_1" data-placeholder="Seleccione Sucursal" name="sucursalm" id="sucursalm" v-model="sucursal" disabled>
                                        <option></option>
                                        <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                                            {{ lstsucursal.Nombre }}
                                        </option>
                                        </select>
                                </div>
                          <div class="col-md-4 text-start">
                                <label class="form-label">Fecha:</label>
                                 <div class="input-group">
                                    <flat-pickr v-model="inputDate" :value="fechamodal" @change="obtenerCompraVenta()" class="form-control form-control-solid"
                                        placeholder="Seleccione Fecha" name="fechaid" id="fechaid" />
                                       
                                </div>
                                <!-- <input class="form-control form-control-solid" placeholder="Pick date rage" id="kt_daterangepicker_3"/> -->
                            </div>
                                 <div class="col-md-4 text-start">
                                <label class="fw-semibold fs-6 mb-2">Tipo Pollo:</label>
                                  <select class="form-select form-select-solid" data-dropdown-parent="#kt_accordion_1" data-placeholder="Seleccione Tipo Pollo" name="pollo" id="pollom" v-model="pollo" disabled>
                                    <option></option>
                                    <option v-for="(lstpollo, index) in listado_pollo" :key="index" :value="lstpollo.TipoPolloID">
                                        {{ lstpollo.Nombre }}
                                    </option>
                                </select>
                             </div>
                  </div>
                           <div class="row mt-4">
                            <div class="col-md-6 text-start" :class="{ 'cboblock': listado_proveedor.length>0 && cbomostrar, 'cbonone': listado_proveedor.length==0 }">
                                <label class="fw-semibold fs-6 mb-2">Compra :</label>
                                <select class="form-select form-select-solid" data-dropdown-parent="#kt_accordion_1" data-placeholder="Seleccione Proveedor" name="proveedor" id="proveedorm" v-model="proveedor" >
                                    <option></option>
                                    <option v-for="(lstproveedor, index) in listado_proveedor" :key="index" :value="lstproveedor.RowID">
                                        {{ lstproveedor.NombreGeneral }}
                                    </option>
                                </select>
                             </div>
                               <div class="text-start"  :class="{ 'col-md-6': listado_proveedor.length>0, 'col-md-12': listado_proveedor.length==0 }">
                                <label class="fw-semibold fs-6 mb-2 ">Cliente:</label>
                                  <select class="form-select form-select-solid" data-dropdown-parent="#kt_accordion_1" data-placeholder="Seleccione Cliente" name="cliente" id="clientem" v-model="cliente" >
                                    <option></option>
                                    <option v-for="(lstcliente, index) in listado_cliente" :key="index" :value="lstcliente.ClienteID">
                                        {{ lstcliente.NombreCompleto }}
                                    </option>
                                </select>
                             </div>
                           </div>

        </div>
        <div>
            <div class="d-flex flex-column-fluid mt-5 text-start">
                <div class="mb-0 ms-0px ">
                <label class="required form-label"># Jabas <span v-if="listado_proveedor.length>0 && cbomostrar" class="ps-4" style="color: #008000cc;">(disponible: {{proveedorjabas}})</span></label>
               <input type="text" id="tjaba" :disabled="listado_guia.length>0" v-model="vjaba" placeholder="# Jabas" class="form-control h-35px" >
            </div>
             <div class="mb-0 ms-5">
                <label class="required form-label"># Pollos <span v-if="listado_proveedor.length>0 && cbomostrar" class="ps-4" style="color: #008000cc;">(disponible: {{proveedorpollos}})</span></label>
              <input type="text" id="tpollo" :disabled="listado_guia.length>0 || vjaba=='' " v-model="vpollo" @keyup="calcularpromedio" placeholder="# Pollos" class="form-control h-35px" >
            </div>
             <div class="mb-0 ms-5">
                <label class="required form-label">Peso Bruto</label>
               <input type="text" id="tbruto" :disabled="listado_guia.length>0 || vpollo==''" v-model="vbruto" @keyup="KeyUpBruto" placeholder="Peso Bruto" class="form-control h-35px" >
            </div>
             <div class="mb-0 ms-5">
                <label class="required form-label">Peso Tara</label>
               <input type="text" id="ttara" :disabled="listado_guia.length>0 || vbruto==''" v-model="vtara" @keyup="KeyUpTara" placeholder="Peso Tara" class="form-control h-35px" >
            </div>
            </div>
             <div class="d-flex flex-column-fluid mt-5 text-start">
                <div class="ms-0">
                    <label class="required form-label">Peso Neto</label>
                    <input type="text" id="tneto" placeholder="Peso Neto" v-model="vneto" class="form-control h-35px" disabled >
                </div>
                 <div class="ms-5">
                    <label class="required form-label">Precio</label>
                    <input type="text" id="tprecio" v-model="vprecio" @keyup="KeyUpPrecio" placeholder="Precio" class="form-control h-35px" >
                </div>
                <div class="ms-5">
                    <label class="required form-label">Importe</label>
                    <input type="text" id="timporte" placeholder="Importe" v-model="vimporte" class="form-control h-35px " disabled>
                </div>
                <div class="ms-5">
                    <label class="required form-label">Promedio</label>
                    <input type="text" id="tpromedio" placeholder="Promedio" v-model="vpromedio" class="form-control h-35px" disabled>
                </div>
                
                
                
            </div>
        </div>
                <!--begin::Accordion-->
            <div class="accordion my-10" id="kt_accordion_1">
                <div class="accordion-item" v-if="dTipoModal == ModalAgregar || dTipoModal == ModalEditar ">
                    <h2 class="accordion-header" id="kt_accordion_1_header_2">
                        <button class="accordion-button fs-4 fw-semibold p-3 " type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_2" aria-expanded="false" aria-controls="kt_accordion_1_body_2">
                        Datos de la Guia
                        </button>
                    </h2>
                    <div id="kt_accordion_1_body_2" class="accordion-collapse collapse show" aria-labelledby="kt_accordion_1_header_2" data-bs-parent="#kt_accordion_1">
                        <div class="accordion-body">
                            <div class="table-responsive" style="max-height: 300px;"> 
                                <table id="kt_datatable_example_2" class="table table-row-bordered table-row-dashed gy-5 tabla-guia">
                                    <thead>
                                        <tr class="fw-semibold fs-6 text-gray-800">
                                            <th class="max-w-50px">N° Jabas</th>
                                            <th class="min-w-50px">N° Pollos</th>
                                            <th class="min-w-50px">Peso Bruto</th>
                                            <th class="min-w-50px">Peso Tara</th>
                                            <th class="min-w-50px">Acciones</th>
                                        </tr>
                                    </thead>
                      <tbody v-if="dTipoModal == ModalAgregar">
                        <tr v-for="(lstguia, index) in listado_guia" :key="index" >
                            <td class=" min-w-50px" ><label class="Jabas controlnone" :id='"jaba"+index' >  0</label> <input type="text"  class="inputedit w-11 form-control"   :id='"ijaba"+index' value="0">  </td>
                            <td class=" min-w-50px" ><label class="Pollos controlnone" :id='"pollo"+index' >0</label> <input type="text" class="inputedit w-11 form-control"  :id='"ipollo"+index' value="0"> </td>
                            <td class=" min-w-50px" ><label class="PesoBruto controlnone" :id='"pesob"+index' >0</label><input type="text" class="inputedit w-11 form-control"  :id='"ipesob"+index' value="0"> </td>
                            <td class=" min-w-50px" ><label class="PesoTara controlnone" :id='"pesot"+index' >0</label> <input type="text" class="inputedit w-11 form-control"  :id='"ipesot"+index' value="0"> </td>
                            <td class="min-w-50px"><div class="d-flex justify-content-center flex-shrink-0">
                              <button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 controlnone" :id='"btnedit"+index' @click="editGuia(index)"><i class="fas fa-edit"></i></button>
                                <button type="button" class="btn btn-success btn-hover-scale btn-sm btn-icon me-1 " :id='"btnconfirm"+index' @click="confirmaredit(index)" ><i class="fas fa-check"></i></button>
                                <button type="button" @click="resGuia(index)" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" > <i class="fas fa-trash"></i></button >
                                </div>
                            </td> 
                        </tr>
                    </tbody>
    <tbody v-if="dTipoModal == ModalEditar">
        <tr v-for="(lstguia, index) in listado_guia" :key="index" >
            <td class=" min-w-50px"><label class="Jabas"     :id='"jaba"+index' >{{lstguia.CantidadJabas}}</label><input type="text" class="inputedit w-11 form-control controlnone"   :id='"ijaba"+index' value="0">  </td>
            <td class=" min-w-50px"><label class="Pollos"    :id='"pollo"+index'>{{lstguia.CantidadPollo}}</label><input type="text" class="inputedit w-11 form-control controlnone"  :id='"ipollo"+index' value="0"> </td>
            <td class=" min-w-50px"><label class="PesoBruto" :id='"pesob"+index'>{{lstguia.PesoBruto}}</label><input type="text" class="inputedit w-11 form-control controlnone"  :id='"ipesob"+index' value="0"> </td>
            <td class=" min-w-50px"><label class="PesoTara"  :id='"pesot"+index'>{{lstguia.PesoTara}}</label><input type="text" class="inputedit w-11 form-control controlnone"  :id='"ipesot"+index' value="0"> </td>
            <td class="min-w-50px"><div class="d-flex justify-content-center flex-shrink-0">
                <button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 " :id='"btnedit"+index' @click="editGuia(index)"><i class="fas fa-edit"></i></button>
                <button type="button" class="btn btn-success btn-hover-scale btn-sm btn-icon me-1 controlnone" :id='"btnconfirm"+index' @click="confirmaredit(index),btnModificarGuia(lstguia,index)" ><i class="fas fa-check"></i></button>
                <button type="button" @click="resGuia(index),eliminarGuia(lstguia)" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" > <i class="fas fa-trash"></i></button >
                </div>
            </td> 
        </tr>
  
    </tbody>
       <tfoot>
        <tr class="fw-bold fs-6" @click="addGuia()">
            <th colspan="5" class="text-nowrap align-start py-0"> <div class="lbl__foto">
                                       
                                  <label  class="lbl__foto js-labelFile">
                                          <span class="js-fileName"> Agregar <i   class="fas fa-add icon_add_foto"></i>
                                        </span>
                                    </label>
                                  
                                  </div> 
                                  </th>
        </tr>
    </tfoot>
</table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_1_header_3">
                        <button class="accordion-button fs-4 fw-semibold collapsed p-3" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_3" aria-expanded="false" aria-controls="kt_accordion_1_body_3">
                        Imágenes de Referencia
                        </button>
                    </h2>
                    <div id="kt_accordion_1_body_3" class="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_3" data-bs-parent="#kt_accordion_1">
                        <div class="accordion-body">
                             
                              <div id="page-wrapper" class="gridfotos" v-if="dTipoModal == ModalAgregar || dTipoModal == ModalDevolucion">
                                <div class="form-group addfile" v-for="(lstimagen, index) in listado_imagen" :key="index">
                                  <div class="resfile" @click="resImagen(index,lstimagen)">
                                    
                                     <span class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-dismiss="click" title="Remove avatar">
                                    <i class="bi bi-x fs-2"></i>
                                     </span>
                                  </div>
                              
                                     <img v-if="!modidev"  v-bind:src="url_original+lstimagen.FileBase64" id="imagenbb" alt="" style="border-radius: 15px !important; height: 100%; width: 100%;" width='100%' height='100%'>      
                                    <img v-if="modidev" v-bind:src="lstimagen.URL" id="imagenbb" alt="" style="border-radius: 15px !important; height: 100%; width: 100%;" width='100%' height='100%'>      
                                
                                  </div> 
                              </div>
                               <div id="page-wrapper" class="gridfotos" v-if="dTipoModal == ModalEditar">
                                <div class="form-group addfile" v-for="(lstimagen, index) in listado_imagen" :key="index">
                                  <div class="resfile" @click="resImagen(index,lstimagen)">
                                     <span class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-dismiss="click" title="Remove avatar">
                                    <i class="bi bi-x fs-2"></i>
                                     </span>
                                  </div>
                                     <img v-bind:src="lstimagen.URL" id="imagenbb" alt="" style="border-radius: 15px !important; height: 100%; width: 100%;" width='100%' height='100%'>      
                                  </div> 
                              </div>
                              
                                 
                                      <div class="lbl__foto">
                                        <input type="file" name="myfile" id="file" accept="image/*" @change="seleccionar_archivo($event)" class="input-file" />
                                  <label for="file" id="circulo__add"  class="lbl__foto js-labelFile">
                                          <span class="js-fileName"> Agregar <i   class="fas fa-add icon_add_foto"></i>
                                        </span>
                                    </label>
                                  
                                  </div> 
                            
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Accordion-->
  </div>

        <!--end::Scroll-->
        <!--begin::Actions-->
        <div class="text-center pt-5">
          <button @click="cerrarModal()" type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">
            Cancelar
          </button>
          <button v-if="dTipoModal == ModalEditar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="alertDialogMultiple()">
            <span class="indicator-label" >Modificar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button v-if="dTipoModal == ModalAgregar" type="button" class="btn btn-primary" data-kt-users-modal-action="button" @click="alertDialogMultiple()">
            <span class="indicator-label">Registrar</span>
            <span class="indicator-progress" >Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
            <button v-if="dTipoModal == ModalDevolucion" type="button" class="btn btn-info" data-kt-users-modal-action="button" @click="alertDialogMultiple()">
            <span class="indicator-label" v-if="!modidev">Registrar Devolución</span>
            <span class="indicator-label" v-if="modidev">Modificar Devolución</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Modal body-->
  </div>
</template>
<script lang="js">
import Calendar from 'primevue/calendar';
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from 'axios'
export default defineComponent( {
  name: 'Ventamodal',
  components: {
    Menu,
    Header,
    flatPickr,
    Calendar,
  },
     props: [
    "TipoModal",
    "CodigoMovimiento",
    "VentaID",
    "CantidadJabas",
    "CantidadPollo",
    "PesoBruto",
    "PesoTara",
    "PesoNeto",
    "Promedio",
    "Precio",
    "Importe",
    "SucursalID",
    "ClienteID",
    "TipoPolloID",
    "Fecha",
    "DevolucionID",
    "StockIDE",
    "CompraIDE",
    ],

  data() {
    return {
         BASE_URL: process.env.VUE_APP_BASE_URL,
         TOKEN:'',
         MODIFICADOR:'',
         Buscar:'',
         dTipoModal:"",
         sucursal:"",
        pollo:"",
         cliente:"",
        proveedor:"",
        listado_sucursal:[],
        listado_cliente:[],
        RolUser:"",
        ModalAgregar: "mAgregar",
        ModalEditar: "mEditar",
        ModalDevolucion:"mDevolucion",
        dCodigoMovimiento:"",
        dCodigoDevolucion:"",
        dDevolucionID:null,
        showcontenedor:false,
        modidev:false,
        guia_final:[],
        precio:0,
        tara:0,
        fechamodal:null,
        aprobacion:false,
        año:'',
        mes:'',
        dia:'',
        devolucion:[],
        vjaba:'',
        vpollo:'',
        vbruto:'',
        vtara:'',
        vneto:'',
        vprecio:'',
        vimporte:'',
        vpromedio:'',
        
        // listado_guia: [{ id: 0, CantidadJabas: 0 , CantidadPollo:0,PesoBruto:0,PesoTara:0}],
         listado_guia: [],
         listado_imagen:[],
         listado_proveedor:[],
          url_imagen: "",
           url_original:"",
          tipo_imagen:"",
        imgTemporal: false,
        listado_imagenfinal:[],
        listado_pollo:[],

        ValorSumar:false,
        inputDate:null,
        proveedorjabas:0,
        proveedorpollos:0,
        cbomostrar:false,
        registro:false,
        CompraID:null,
        StockID:null,
    }
  },
  mounted() {
    this.obtenerPermiso();
    this.asignacionVariable();
    this.obtenerSucursal();
   this.obtenerCliente();
   this.obtenerPollo();
    this.fechaVenta();
    // this.obtenerProveedor();
  
  },
 created() {
this.validacionStorage();
  this.año = new Date().getFullYear();
	this.dia = new Date().getDate();
	this.mes = new Date().getMonth() + 1;
	this.fechamodal = this.año + "-" + this.mes + "-" + this.dia;

  
 },
 watch: {
    inputDate: function (newValue) {
      this.fechamodal=newValue;
      this.obtenerCompraVenta();
      }
    },
 

methods: {
      	validacionStorage(){
              // this.storage_sucursal=JSON.parse(localStorage.getItem('sucursal-ID'));
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
               obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
    asignacionVariable(){
                this.dTipoModal =this.TipoModal;
                   if(this.TipoModal==this.ModalEditar){
                      var fecha_hoy = moment().format("YYYY-MM-DD ")
                      var fecha_venta= moment(this.Fecha).format("YYYY-MM-DD ")
                      var RolUser = JSON.parse(localStorage.getItem('RolNombre'));
                    // if (fecha_hoy== fecha_venta || RolUser==="Administrador") {
                    //       	$('#pollom').removeAttr('disabled');
                    //     }

                    this.sucursal = this.SucursalID;
                    this.pollo = this.TipoPolloID;
                     this.cliente=this.ClienteID
                    this.dCodigoMovimiento = this.CodigoMovimiento;
                    this.dVentaID = this.VentaID;
                    this.obtenerGuia(this.dCodigoMovimiento);
                    this.obtenerImagen(this.dCodigoMovimiento);
                    this.vjaba=this.CantidadJabas;
                    this.vpollo=this.CantidadPollo;
                    this.vbruto=this.PesoBruto;
                    this.vtara=this.PesoTara;
                    this.vneto=this.PesoNeto;
                    this.vprecio=this.Precio;
                    this.vimporte=this.Importe;
                    this.vpromedio=this.Promedio;
                    this.fechamodal = this.Fecha;
                    this.calculoSucursal();

                    
                   }
                   if (this.TipoModal==this.ModalDevolucion) {
                   
                    this.sucursal = this.SucursalID;
                    this.pollo = this.TipoPolloID;
                    this.cliente=this.ClienteID
                    this.dCodigoMovimiento = this.CodigoMovimiento;
                    this.dDevolucionID=this.DevolucionID;
                    this.vprecio = this.Precio;
                    if (this.dDevolucionID) {
                       this.obtenerDevolucion();
                    }
                   }
               },
                alertDialogMultiple() {
                var text, icon, txticon, coloricon=""
                if (!this.modidev) {
                  text="Estás seguro de que deseas Registrar la devolución? ";
                    icon="info";
                    txticon="Si, Registrar";
                    coloricon="btn fw-bold btn-info";
                }
                  if (this.modidev) {
                    text="Estás seguro de que deseas Modificar? ";
                    icon="info";
                    txticon="Si, Modificar";
                    coloricon="btn fw-bold btn-info";
                  }else if(this.dTipoModal== "mAgregar"){
                    text="Estás seguro de que deseas Registrar?";
                    icon="question";
                    txticon="Si, Registrar!";
                    coloricon="btn fw-bold btn-primary";

                  }else if(this.dTipoModal== "mEditar"){
                     text="Estás seguro de que deseas Modificar?";
                    icon="warning";
                    txticon="Si, Modificar!";
                    coloricon="btn fw-bold btn-warning";

                  }


      Swal.fire({
         text: text,
        icon: icon,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: txticon,
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: coloricon,
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {

          if (this.TipoModal==this.ModalAgregar) {
             this.imprimirGuia();
          }else if (this.TipoModal==this.ModalDevolucion) {
            if (this.modidev) {
              this.ModificarDevolucion()
            }else{
              this.RegistrarDevolucion();
            }

          }else if (this.dTipoModal== "mEditar") {
            this.modificarVenta()
          }
             
         
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    alertaExitosa(){
       Swal.fire({
            text: "Operación Exitosa!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            if (this.TipoModal !=this.ModalDevolucion) {
            this.cerrarModal()
             if (result.isConfirmed) {
            
            // window.location.href = "/venta"
             }
            }
           
          });
    },
    obtenerDevolucion(){
    axios.post(this.BASE_URL+"/devolucion/obtener",{DevolucionID: this.dDevolucionID},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
            this.devolucion = response.data.respuesta
          if (this.devolucion.length>0) {
             this.dCodigoMovimiento =  this.devolucion[0].CodigoMovimiento
              this.pollo = this.devolucion[0].TipoPolloID
              this.vjaba = this.devolucion[0].CantidadJabas
              this.vpollo = this.devolucion[0].CantidadPollo
              this.vbruto = this.devolucion[0].PesoBruto
              this.vtara = this.devolucion[0].PesoTara
              this.vneto = this.devolucion[0].PesoNeto
              this.vpromedio = this.devolucion[0].Promedio
              this.vimporte = this.devolucion[0].Importe
              this.modidev= true
              this.dCodigoDevolucion = this.devolucion[0].CodigoDevolucion;
              
              const cod = this.devolucion[0].CodigoDevolucion
              this.obtenerImagen(cod);
          }
             
            
            
        });
    },
    RegistrarDevolucion(){
     let devolucion={}
      devolucion.CodigoMovimiento = this.dCodigoMovimiento;
      devolucion.TipoPolloID = this.pollo
      devolucion.CantidadJabas = this.vjaba
      devolucion.CantidadPollo = this.vpollo
      devolucion.PesoBruto = this.vbruto
      devolucion.PesoTara = this.vtara
      devolucion.PesoNeto = this.vneto
      devolucion.Precio=this.vprecio;
      devolucion.Importe=this.vimporte
      devolucion.Promedio = this.vpromedio
      devolucion.UsuarioID = this.MODIFICADOR
    axios.post(this.BASE_URL+"/devolucion/insertar",devolucion,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
          const CodigoDevolucion = response.data.respuesta[0].Valor
          if (!this.aprobacion) {
            this.registrarImagenDevolucion(CodigoDevolucion);
          }else{
            this.alertaExitosa();
          }  
        });

    },
      ModificarDevolucion(){
     let devolucion={}
      devolucion.DevolucionID = this.dDevolucionID;
      devolucion.TipoPolloID = this.pollo;
      devolucion.CantidadJabas = this.vjaba;
      devolucion.CantidadPollo = this.vpollo;
      devolucion.PesoBruto = this.vbruto;
      devolucion.PesoTara = this.vtara;
      devolucion.PesoNeto = this.vneto;
      devolucion.Precio=this.vprecio;
      devolucion.Importe=this.vimporte
      devolucion.Promedio = this.vpromedio;
      devolucion.UsuarioID = this.MODIFICADOR;
    axios.post(this.BASE_URL+"/devolucion/actualizar",devolucion,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
          const CodigoDevolucion = response.data.respuesta[0].Valor
          if (!this.aprobacion) {
            this.registrarImagenDevolucion(CodigoDevolucion);
          }else{
            this.alertaExitosa();
          }
          

            
        });

    },
    registrarImagenDevolucion(cod){
      let imagen={};

      for (let index = 0; index < this.listado_imagen.length; index++) {
            imagen.CodigoMovimiento = cod;
            imagen.FileBase64 = this.listado_imagen[index].FileBase64;
            imagen.Extension = this.listado_imagen[index].Extension;
            imagen.UsuarioID = this.MODIFICADOR;
        axios.post(this.BASE_URL+"/movimiento/imagen/insertar",imagen,{ headers: {
                          'Content-Type': 'application/json',
                          'Authorization':  this.TOKEN
                      }})
              .then((response) => {
                this.alertaExitosa();
              });
}
    },
      obtenerGuia(cod){
      axios.post(this.BASE_URL+"/guia/obtener",{ CodigoMovimiento: cod},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
          this.listado_guia = response.data.respuesta   
        });
      },
      
      obtenerCompraVenta(){
        this.listado_proveedor=[];
        this.proveedorjabas=0;
        this.proveedorpollos=0;
        this.proveedor=null;
        if (this.sucursal && this.fechamodal && this.pollo && this.cbomostrar) {
           let payload={
        SucursalID: this.sucursal,
        Fecha: this.fechamodal,
        TipoPolloID: this.pollo,
        }

      axios.post(this.BASE_URL+"/compra/obtener/para/venta",payload,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
          this.listado_proveedor=[];
          this.listado_proveedor= response.data.respuesta;

            if (this.TipoModal==this.ModalEditar) {
               if (this.CompraIDE != null) {
               
                      this.listado_proveedor.forEach(ele => {
                        if (ele.CompraID == this.CompraIDE) {
                           this.proveedor= ele.RowID;
                           this.obtenerStockProveedor(this.proveedor);
                        }
                      });
                    }else if (this.StockIDE != null) {
                        this.listado_proveedor.forEach(ele => {
                        if (ele.StockID == this.StockIDE) {
                           this.proveedor= ele.RowID;
                           this.obtenerStockProveedor(this.proveedor);
                        }
                      });
                    }
            }


           $("#proveedorm")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.obtenerStockProveedor(this.proveedor);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
 
        });
        }else{
          return;
        }
       
      },
        obtenerImagen(cod){
               axios.post(this.BASE_URL+"/movimiento/imagen/obtener",{ CodigoMovimiento: cod},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
          this.listado_imagen = response.data.respuesta
          if (this.listado_imagen.length>=1) {
            this.aprobacion = true;
          }else{
            this.aprobacion = false;
          }
            
        });
        },
      fechaVenta(){
          $("#fechaid").flatpickr({
						maxDate: this.fechamodal,
					})
           },
           calculoSucursal(){
            this.listado_sucursal.forEach(ele => {
              if (ele.SucursalID == this.sucursal) {
                if (ele.TipoSucursal==0) {
                  this.cbomostrar=true; 
                }else{
                  this.cbomostrar=false; 
                }
              }
              
            });
            if (this.TipoModal==this.ModalEditar) {
              this.obtenerCompraVenta();

              
            }
           },
    	    obtenerSucursal() {
		if (this.TipoModal==this.ModalAgregar) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal-ID'))
    }
      axios
        .get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
				if (this.RolUser==="Administrador") {
						 $('#sucursalm').removeAttr('disabled');
					
				}
        this.calculoSucursal();
    
          $("#sucursalm")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
                this.obtenerCliente(sucursal);
                this.cambioavicola();
                 this.obtenerPollo();
                //  this.obtenerProveedor();
                this.calculoSucursal();
                 this.obtenerCompraVenta();
                  
            

            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            })
        });
    },
    cambioavicola(){
              this.listado_sucursal.forEach(ele => {
                  if (ele.SucursalID == this.sucursal) {
                    if (ele.Operacion == 1) {
                      this.ValorSumar = true;
                    }else{
                       this.ValorSumar = false;
                    }
                  }
                 if (this.ValorSumar) {
                this.vneto=  Number(this.vbruto)+Number(this.vtara);
                  }else{
                      this.vneto=  Number(this.vbruto)- Number(this.vtara)
                  }
                      // this.calculoInput()
                
                
                });
    },
     obtenerCliente() {
      axios
        .post(this.BASE_URL+"/cliente/obtener/porsucursal",{
          SucursalID: this.sucursal},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_cliente = response.data.respuesta;
  
 		
    
    $("#clientem")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
    
    obtenerStockProveedor(id){
      this.proveedorjabas=0;
      this.proveedorpollos=0;
      this.listado_proveedor.forEach(ele => {
        if (ele.RowID == id) {
          this.proveedorjabas= ele.DiferenciaJabas;
          this.proveedorpollos= ele.DiferenciaPollos;
          this.CompraID=ele.CompraID;
          this.StockID=ele.StockID;
          
        }
        
      });

    },
     obtenerPollo() {
		// if (this.TipoModal==this.ModalAgregar) {
    //   this.sucursal = JSON.parse(localStorage.getItem('sucursal-ID'))
    // }
      axios
        .post(this.BASE_URL+"/tipopollo/obtener/porsucursal",{SucursalID:this.sucursal},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_pollo = response.data.respuesta;
						$('#pollom').removeAttr('disabled');
	
				
    
          $("#pollom")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.obtenerCompraVenta();
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            })  
        });
    },
    resImagen(index,{MovimientoImagenID}){
  this.listado_imagen.splice(index,1)
      if (this.TipoModal==this.ModalEditar || this.modidev) {
            axios.post(this.BASE_URL+"/movimiento/imagen/eliminar", {MovimientoImagenID: MovimientoImagenID,UsuarioID:this.MODIFICADOR},{ headers: {
                          'Content-Type': 'application/json',
                          'Authorization':  this.TOKEN
                }})
              .then((response) => {
                if (this.TipoModal==this.ModalDevolucion) {
                  this.obtenerImagen(this.dCodigoDevolucion);
                }else{
                    this.obtenerImagen(this.dCodigoMovimiento);
                }
            
              });
      }
    },
    addGuia() {
      this.listado_guia.push({id:this.listado_guia.length , CantidadJabas: 0 , CantidadPollo:0,PesoBruto:0,PesoTara:0 })
   },
    resGuia: function (index) {
      this.listado_guia.splice(index,1)
    },
    editGuia(index){
        if (this.TipoModal==this.ModalAgregar) {

   
    const jaba= document.getElementById("jaba"+index).innerHTML
    const pollo= document.getElementById("pollo"+index).innerHTML
    const pesob= document.getElementById("pesob"+index).innerHTML
    const pesot= document.getElementById("pesot"+index).innerHTML
    const btnedit= document.getElementById("btnedit"+index)
    // ocultamos los label
    document.getElementById("jaba"+index).style.display="none";
    document.getElementById("pollo"+index).style.display="none";
    document.getElementById("pesob"+index).style.display="none";
    document.getElementById("pesot"+index).style.display="none"
     btnedit.style.display="none"
    //mostramos los input con sus valores
    document.getElementById("ijaba"+index).style.display="block"
    document.getElementById("ipollo"+index).style.display="block"
    document.getElementById("ipesob"+index).style.display="block"
    document.getElementById("ipesot"+index).style.display="block"
      document.getElementById("btnconfirm"+index).style.display="block"
     document.getElementById("ijaba"+index).value=jaba
     document.getElementById("ipollo"+index).value=pollo
     document.getElementById("ipesob"+index).value=pesob
    document.getElementById("ipesot"+index).value=pesot
    } else if (this.TipoModal==this.ModalEditar) {
      const jaba= document.getElementById("jaba"+index).innerHTML
    const pollo= document.getElementById("pollo"+index).innerHTML
    const pesob= document.getElementById("pesob"+index).innerHTML
    const pesot= document.getElementById("pesot"+index).innerHTML
    const btnedit= document.getElementById("btnedit"+index)
    // ocultamos los label
    document.getElementById("jaba"+index).style.display="none";
    document.getElementById("pollo"+index).style.display="none";
    document.getElementById("pesob"+index).style.display="none";
    document.getElementById("pesot"+index).style.display="none"
     btnedit.style.display="none"
    //mostramos los input con sus valores
    document.getElementById("ijaba"+index).style.display="block"
    document.getElementById("ipollo"+index).style.display="block"
    document.getElementById("ipesob"+index).style.display="block"
    document.getElementById("ipesot"+index).style.display="block"
      document.getElementById("btnconfirm"+index).style.display="block"
     document.getElementById("ijaba"+index).value=jaba
     document.getElementById("ipollo"+index).value=pollo
     document.getElementById("ipesob"+index).value=pesob
    document.getElementById("ipesot"+index).value=pesot

}
},


    confirmaredit(index){
    const jaba= document.getElementById("ijaba"+index).value
    const pollo= document.getElementById("ipollo"+index).value
    const pesob= document.getElementById("ipesob"+index).value
    const pesot= document.getElementById("ipesot"+index).value
    //ocultamos los input con sus valores
    document.getElementById("ijaba"+index).style.display="none"
    document.getElementById("ipollo"+index).style.display="none"
    document.getElementById("ipesob"+index).style.display="none"
    document.getElementById("ipesot"+index).style.display="none"
    document.getElementById("btnconfirm"+index).style.display="none"
        // mostramos los labelcon sus valores
    document.getElementById("jaba"+index).style.display="block"
    document.getElementById("pollo"+index).style.display="block"
    document.getElementById("pesob"+index).style.display="block"
    document.getElementById("pesot"+index).style.display="block"
    document.getElementById("btnedit"+index).style.display="block"
//asignacion de values
     document.getElementById("jaba"+index).innerHTML=jaba;
     document.getElementById("pollo"+index).innerHTML=pollo;
     document.getElementById("pesob"+index).innerHTML=pesob;
      document.getElementById("pesot"+index).innerHTML=pesot;
    
    this.calculoInput();
    },
    calculoInput(){
            var totaljabas=0, totalpollos=0,totalbruto=0,totaltara=0, totalneto=0;
            document.querySelectorAll('.tabla-guia tbody tr').forEach(function(e){
            var columnas=e.querySelectorAll("td");
            var jabas=parseFloat(columnas[0].textContent);
            var pollos=parseFloat(columnas[1].textContent);
            var bruto=parseFloat(columnas[2].textContent);
             var tara=parseFloat(columnas[3].textContent);
             totaljabas+=jabas
             totalpollos+=pollos
             totalbruto+=bruto
             totaltara+=tara
     
           
        });
           
         this.vjaba=totaljabas;
         this.vpollo= totalpollos;
         this.vbruto=totalbruto.toFixed(2);
         this.vtara=totaltara.toFixed(2);
          if (this.ValorSumar) {
                totalneto=  Number(this.vbruto)+Number(this.vtara);
             }else{
                totalneto=  Number(this.vbruto)- Number(this.vtara);
             }
         this.vneto = totalneto.toFixed(2);
         this.vimporte= Math.round(this.vneto*this.vprecio).toFixed(2);
         this.vpromedio= (this.vneto/this.vpollo).toFixed(2);
        //  this.calcularStockJaba();
        //  this.calcularStockPollo();
    },
    calcularStockJaba(){
      if ( this.cbomostrar) {
        if (this.proveedorjabas == 0) {
          this.errorStockNulo();
        }else if (Number(this.vjaba)> this.proveedorjabas) {
          this.registro=false;
          this.errorStock("Jabas");
          }else{
            this.registro=true;
            // this.calcularStockPollo();
          }
        
         
      }else{
        this.registro=true;
        // this.calcularStockPollo();
      }
     
    },
    calcularStockPollo(){
      if (this.cbomostrar) {
        if (this.proveedorjabas == 0) {
          this.errorStockNulo();
        }else if (Number(this.vpollo)> this.proveedorpollos) {
            this.registro=false;
            this.errorStock("Pollos");
            } else{
            this.registro=true;
            // this.calcularStockJaba();
            }
      }else{
         this.registro=true;
          // this.calcularStockJaba();
      }

    },
    KeyUpPrecio(){
        this.calcularImporte()
        this.calcularpromedio()
    },
      KeyUpTara(){
        if (this.ValorSumar) {
          this.vneto=  Number(this.vbruto)+ Number(this.vtara);
        }else{
           this.vneto=  Number(this.vbruto)- Number(this.vtara);
        }
        
        this.promedio= ( this.vneto/this.vpollo).toFixed(2);
        this.calcularImporte()
        this.calcularpromedio()
      },
       KeyUpBruto(){
        if (this.ValorSumar) {
          this.vneto=  Number(this.vbruto)+ Number(this.vtara);
        }else{
           this.vneto=  Number(this.vbruto)- Number(this.vtara);
        }
       this.promedio= ( this.vneto/this.vpollo).toFixed(2)
       this.calcularImporte()
       this.calcularpromedio()
      },
      calcularpromedio(){
        // this.calcularStockPollo();
        this.vpromedio= (this.vneto/this.vpollo).toFixed(2);  
       this.calcularImporte()
      },
    calcularImporte(){
        this.vimporte=Math.round(this.vneto*this.vprecio).toFixed(2);
    },
    imprimirGuia(){
        let guia=[];
        let Venta={};
        document.querySelectorAll('.tabla-guia tbody tr').forEach(function(e){
         let fila = {
            CantidadJabas: e.querySelector('.Jabas').innerText,
            CantidadPollo: e.querySelector('.Pollos').innerText,
            PesoBruto: e.querySelector('.PesoBruto').innerText,
            PesoTara: e.querySelector('.PesoTara').innerText
       
        }
            guia.push(fila);
        
        });

        let CantidadJabas= document.getElementById("tjaba").value;
        let CantidadPollo= document.getElementById("tpollo").value;
        let PesoBruto= document.getElementById("tbruto").value;
        let PesoTara= document.getElementById("ttara").value;
        let PesoNeto= document.getElementById("tneto").value;
        let Promedio= document.getElementById("tpromedio").value;
        let Precio= document.getElementById("tprecio").value;
        let Importe= document.getElementById("timporte").value;
        
        if (this.cbomostrar && this.proveedor) {
          Venta.StockID=this.StockID;
          Venta.CompraID=this.CompraID;
        }else{
          Venta.StockID=null;
          Venta.CompraID=null;
        }
                Venta.Fecha = this.fechamodal;
                Venta.ClienteID = this.cliente;
                Venta.CantidadJabas= CantidadJabas;
                Venta.CantidadPollo= CantidadPollo;
                Venta.PesoBruto= PesoBruto;
                Venta.PesoTara= PesoTara;
                Venta.PesoNeto= PesoNeto;
                Venta.Promedio= Promedio;
                Venta.Precio= Precio;
                Venta.Importe= Importe;
                Venta.TipoPolloID=this.pollo;
                Venta.Guias = guia;
                Venta.Imagenes=this.listado_imagen;
                Venta.UsuarioID = this.MODIFICADOR;
                
                if (Venta.ClienteID && Venta.TipoPolloID && Venta.CantidadJabas && Venta.CantidadPollo && Venta.PesoBruto && Venta.PesoTara && Venta.Precio && Venta.UsuarioID) {
                       this.registrarVenta(Venta);
                  }else{
                    this.alertValidacion()
                    return
                  }  
       
    },
     registrarVenta: function (Venta) {
   axios.post(this.BASE_URL+"/venta/insertar/todo", Venta,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
          this.alertaExitosa();
        });
     
    },
    modificarVenta(){
      var Venta={}
       Venta.VentaID= this.dVentaID,
        Venta.ClienteID= this.cliente,
        Venta.TipoPolloID= this.pollo,
        Venta.CantidadJabas= this.vjaba,
        Venta.CantidadPollo= this.vpollo,
        Venta.PesoBruto= this.vbruto,
        Venta.PesoTara= this.vtara,
        Venta.PesoNeto= this.vneto,
        Venta.Promedio= this.vpromedio,
        Venta.Precio= this.vprecio,
        Venta.Importe= this.vimporte,
        Venta.UsuarioID= this.MODIFICADOR
          axios.post(this.BASE_URL+"/venta/actualizar", Venta,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
           this.alertaExitosa();
           this.obtenerGuia(this.dCodigoMovimiento)
        });
    },
    btnModificarGuia({GuiaID},index){
    const jaba= document.getElementById("ijaba"+index).value
    const pollo= document.getElementById("ipollo"+index).value
    const pesob= document.getElementById("ipesob"+index).value
    const pesot= document.getElementById("ipesot"+index).value
      if((GuiaID==="" || GuiaID===undefined) && this.registro){

        axios.post(this.BASE_URL+"/guia/insertar", {CodigoMovimiento: this.dCodigoMovimiento,CantidadJabas:jaba, CantidadPollo: pollo,PesoBruto:pesob,PesoTara:pesot,UsuarioID:this.MODIFICADOR},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
          this.modificarVenta();
          //  this.obtenerGuia(this.dCodigoMovimiento)
        });
      }else if (GuiaID!="" && this.registro) {
         axios.post(this.BASE_URL+"/guia/actualizar", {GuiaID: GuiaID,CantidadJabas:jaba, CantidadPollo: pollo,PesoBruto:pesob,PesoTara:pesot,UsuarioID:this.MODIFICADOR},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
          this.modificarVenta();
          //  this.obtenerGuia(this.dCodigoMovimiento)
        });
      }else{
        this.errorStock("lo permitido");
      }

    },
    eliminarGuia({GuiaID}){
     axios.post(this.BASE_URL+"/guia/eliminar", {GuiaID: GuiaID,UsuarioID:this.MODIFICADOR},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
          this.calculoInput();
          this.modificarVenta();
        });
    },
    seleccionar_archivo(event) {
     
    //      this.calcularImagen(event);
    // },
    //    calcularImagen(event) {

        let imgg={};
      const file = event.target.files[0];
      var reader = new FileReader();
    this.imgTemporal = true;
     reader.onload =(e)=>{
      this.url_imagen= e.target.result.replace(/^data:image\/[a-z]+;base64,/, "");
      this.url_original= "data:"+file.type+";base64,";
        imgg.FileBase64=this.url_imagen;
     this.tipo_imagen= file.type.replace("image/", "");
      imgg.Extension= this.tipo_imagen
        this.listado_imagen.push(imgg)
        if(this.TipoModal==this.ModalEditar ){
       axios.post(this.BASE_URL+"/movimiento/imagen/insertar", {CodigoMovimiento: this.dCodigoMovimiento,FileBase64: this.url_imagen,Extension: this.tipo_imagen,UsuarioID:this.MODIFICADOR},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
       this.obtenerImagen(this.dCodigoMovimiento);
        });
        }
        if (this.modidev) {
             axios.post(this.BASE_URL+"/movimiento/imagen/insertar", {CodigoMovimiento: this.dCodigoDevolucion,FileBase64: this.url_imagen,Extension: this.tipo_imagen,UsuarioID:this.MODIFICADOR},{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
       this.obtenerImagen(this.dCodigoDevolucion);
        });
        }
  

     };
      reader.readAsDataURL(file)


       
},

resFoto() {
      const image = $('#imagenbb')
      const btnadd = $('#circulo__add')
      const btnfoto = $('#btnResFoto')
      image.css({ display: 'none' })
      btnadd.css({display:'block'})
      btnfoto.css({ display: 'none' })
    },

        cerrarModal() {
      $("#kt_modal_venta").modal("hide");
      this.modidev= false;
      this.$parent.showModal = false;
      this.$parent.obtenerPermiso()
      this.$parent.obtenerVenta();
      // window.location.href = "/venta"
    },
	    alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
    errorStock(variable){
          Swal.fire({
            text: "El stock no puede exceder "+variable,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
    errorStockNulo(){
          Swal.fire({
            text: "Seleccione un proveedor con stock disponible",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
	

}
})
</script>
<style scoped>
.inputedit{
    /* display: none; */
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.controlnone{
    display: none;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + .js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.input-file + .js-labelFile .icon:before {
  content: "";
}
.input-file + .js-labelFile.has-file .icon:before {
  content: "";
  color: #5aac7b;
}
.resfile{
 display: flex;
 width: 100%;
 justify-content: end;
 position: absolute;
}
.icon_add_foto{
  border: 1px solid;
  border-radius: 50px !important;
  padding: 1px;
  color: #8a8a8a;
}
.gridfotos{
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.addfile{
    
        width: 95% !important;
        border: 1px solid #8a8a8a;
    height: 115px;
     border-radius: 15px !important;
     cursor: pointer;
     position: relative;
    }

    .lbl__foto{
      cursor: pointer;
    height: 100%;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#8a8a8a;
    }

 .cboblock{
  display: block;
 }
.cbonone{
  display: none;
}
</style>