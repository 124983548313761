<template>
  <div v-if="showcontenedor"  class="d-flex flex-column flex-root">
    
    <div class="page d-flex flex-row flex-column-fluid">
            <Menu></Menu>
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header></Header>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div id="kt_content_container" class="container-xxl">

            <div class="card bg-body-white card-xl-stretch mb-xl-8">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                        <div class="align-items-center position-relative my-1 mr-2">
                            <label class="fs-6 fw-semibold mb-2">Sucursal</label>
                             <select class="form-select form-select-solid" name="sucursal" id="sucursal" v-model="sucursal" disabled  data-control="select2" data-placeholder="Seleccione Sucursal" data-hide-search="true">
                          <option></option>
                          <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
                                {{ lstsucursal.Nombre }}
                              </option>
                          </select>
                        </div>
                         <div class="align-items-center position-relative my-1 mr-2 ms-5 text-left" v-if="selectCombo">
                            <label class="fs-6 fw-semibold mb-2 ">Fecha</label>
                             <!-- <input class="form-control form-control-solid" placeholder="Pick date rage" id="fecharango" name="fecharango"/> -->
                            <div v-if="FechaMes" >
                                  <!-- <Calendar   :value="FechaMesValue" placeholder="Seleccione Fecha" v-model="FechaMesValue" view="month" dateFormat="mm/yy" /> -->
                            </div>
                            <div v-if="FechaDia">
                              <flat-pickr v-model="FechaDiaValue" :config="config" class="form-control text-center"
                                        placeholder="Seleccione Fecha" name="date" id="date" />
                               <!-- <Calendar inputId="basic" v-model="FechaDiaValue" placeholder="Seleccione Fecha"  dateFormat="dd/mm/yy" autocomplete="off" /> -->
                            </div>
                        
                        </div>
                      
                        <div class="align-items-center position-relative my-1 ms-5">
                            <button type="button" @click="generarReporte()"  class="btn btn-light-primary mt-9" tooltipPosition="top" pTooltip="Buscar"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                
                     <div class="card-toolbar" v-if="vistaReporte">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                             <button type="button"  @click="DescargarPDF(),this.descargar=true"  class="btn btn-light-primary me-3"><i class="fa-sharp fa-solid fa-download"></i>Descargar Reporte</button>
                        </div>
                    </div>
                   
                </div>
            </div> 



            <ProgressSpinner aria-label="Basic ProgressSpinner" v-if="loading"/>
            <div class="card" v-if="vistaReporte && !loading">
                 <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>GENERAL</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                   <DataTable :value="ReporteDataGeneral" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="CajaFinal" header="Caja Inicial" style="width:20%;"></Column>
                        <Column  header="Saldo Clientes" style="width:20%;">
                        <template #body>
                            <span>{{TotalMontoSaldoCliente}}</span>
                        </template>
                        </Column>
                        <Column  header="Saldo Proveedores" style="width:20%;">
                        <template #body>
                            <span>{{TotalMontoSaldoProveedor}}</span>
                        </template>
                        </Column>
                          <Column  header="Ingresos" style="width:20%;">
                        <template #body>
                            <span>{{TotalMontoIngreso}}</span>
                        </template></Column>
                         <Column header="Caja Final" style="width:20%;">
                          <template #body>
                            <span>{{(TotalSaldoFinal).toFixed(2)}}</span>
                        </template>
                         </Column>
                  </DataTable>
                   </div>
                     <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>SALDO CLIENTE</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                   <DataTable id="tabla_cliente" :value="ReporteDataSaldoCliente" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="Fecha" footer="TOTAL SALDO CLIENTE:" header="Fecha" style="width:30%;"></Column>
                        <Column field="Nombre" footer="" header="Nombre" style="text-aligne: center;width:30%;"></Column>
                        <Column header="Saldo Final" style="width:20%;">
                         <template #body="slotProps" >
                            <div :class="{'color-green': slotProps.data.SaldoFinal>=0 ,'color-red': slotProps.data.SaldoFinal<0  }">
                            <span class="span-width">{{slotProps.data.SaldoFinal}}</span> <span v-if="slotProps.data.SaldoFinal>0"><i class="fa-solid fa-arrow-up" style="color:green;"></i></span> <span v-if="slotProps.data.SaldoFinal<0 "><i class="fa-solid fa-arrow-down" style="color:red;"></i></span>
                            </div>
                            
                        </template>
                        <template #footer >
                            <div class="color-color">
                            <span>{{TotalMontoSaldoCliente}}</span>
                            </div>
                            
                        </template>
                        </Column>
                  </DataTable>
                   </div>
                   
                     <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>SALDO PROVEEDOR</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                   <DataTable id="tabla_proveedor" :value="ReporteDataSaldoProveedor" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                       <Column field="Fecha" footer="TOTAL SALDO PROVEEDOR:" header="Fecha" style="width:30%;"></Column>
                        <Column field="Nombre" footer="" header="Nombre" style="text-aligne: center;width:30%;"></Column>
                        <Column  header="Saldo Final" style="width:20%;">
                         <template #body="slotProps" >
                            <div :class="{'color-green': slotProps.data.SaldoFinal>=0 ,'color-red': slotProps.data.SaldoFinal<0  }">
                            <span class="span-width">{{slotProps.data.SaldoFinal}}</span> <span v-if="slotProps.data.SaldoFinal>0"><i class="fa-solid fa-arrow-up" style="color:green;"></i></span> <span v-if="slotProps.data.SaldoFinal<0 "><i class="fa-solid fa-arrow-down" style="color:red;"></i></span>
                            </div>
                            
                        </template>
                        <template #footer >
                            <div class="color-color">
                            <span>{{TotalMontoSaldoProveedor}}</span>
                            </div>
                            
                        </template></Column>
                  </DataTable>
                   </div>
                   <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>INGRESOS</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                      <DataTable :value="ReporteDataIngreso" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                          <Column field="Nombre" footer="TOTAL INGRESOS:" header="Nombre" style="width:30%;"></Column>
                        <Column field="Descripcion" footer="" header="Descripción" style="text-aligne: center;width:30%;"></Column>
                        <Column field="Modalidad" footer="" header="Modalidad" style="width:20%;"></Column>
                        <Column field="Monto" :footer="TotalMontoIngreso" header="Monto" style="width:20%;"></Column>
                  </DataTable>
          
                   </div> 
             
            </div>
              <div class="card" id="id-elemento-pdf" v-if="descargar">
                 <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>GENERAL</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                   <DataTable :value="ReporteDataGeneral"  style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="CajaFinal" header="Caja Inicial" style="width:20%;"></Column>
                        <Column  header="Saldo Clientes" style="width:20%;">
                        <template #body>
                            <span>{{TotalMontoSaldoCliente}}</span>
                        </template>
                        </Column>
                        <Column  header="Saldo Proveedores" style="width:20%;">
                        <template #body>
                            <span>{{TotalMontoSaldoProveedor}}</span>
                        </template>
                        </Column>
                          <Column  header="Ingresos" style="width:20%;">
                        <template #body>
                            <span>{{TotalMontoIngreso}}</span>
                        </template></Column>
                         <Column header="Caja Final" style="width:20%;">
                          <template #body>
                            <span>{{(TotalSaldoFinal).toFixed(2)}}</span>
                        </template>
                         </Column>
                  </DataTable>
                   </div>
                     <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>SALDO CLIENTE</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                   <DataTable id="tabla_cliente" :value="ReporteDataSaldoCliente"  style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="Fecha" footer="TOTAL SALDO CLIENTE:" header="Fecha" style="width:30%;"></Column>
                        <Column field="Nombre" footer="" header="Nombre" style="text-aligne: center;width:30%;"></Column>
                        <Column header="Saldo Final" style="width:20%;">
                         <template #body="slotProps" >
                            <div :class="{'color-green': slotProps.data.SaldoFinal>=0 ,'color-red': slotProps.data.SaldoFinal<0  }">
                            <span class="span-width">{{slotProps.data.SaldoFinal}}</span> <span v-if="slotProps.data.SaldoFinal>0"><i class="fa-solid fa-arrow-up" style="color:green;"></i></span> <span v-if="slotProps.data.SaldoFinal<0 "><i class="fa-solid fa-arrow-down" style="color:red;"></i></span>
                            </div>
                            
                        </template>
                        <template #footer >
                            <div class="color-color">
                            <span>{{TotalMontoSaldoCliente}}</span>
                            </div>
                            
                        </template>
                        </Column>
                  </DataTable>
                   </div>
                   
                     <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>SALDO PROVEEDOR</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                   <DataTable id="tabla_proveedor" :value="ReporteDataSaldoProveedor"  style="width: 100%" v-if="TablaDiaVenta">
                       <Column field="Fecha" footer="TOTAL SALDO PROVEEDOR:" header="Fecha" style="width:30%;"></Column>
                        <Column field="Nombre" footer="" header="Nombre" style="text-aligne: center;width:30%;"></Column>
                        <Column  header="Saldo Final" style="width:20%;">
                         <template #body="slotProps" >
                            <div :class="{'color-green': slotProps.data.SaldoFinal>=0 ,'color-red': slotProps.data.SaldoFinal<0  }">
                            <span class="span-width">{{slotProps.data.SaldoFinal}}</span> <span v-if="slotProps.data.SaldoFinal>0"><i class="fa-solid fa-arrow-up" style="color:green;"></i></span> <span v-if="slotProps.data.SaldoFinal<0 "><i class="fa-solid fa-arrow-down" style="color:red;"></i></span>
                            </div>
                            
                        </template>
                        <template #footer >
                            <div class="color-color">
                            <span>{{TotalMontoSaldoProveedor}}</span>
                            </div>
                            
                        </template></Column>
                  </DataTable>
                   </div>
                   <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>INGRESOS</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                      <DataTable :value="ReporteDataIngreso"  style="width: 100%" v-if="TablaDiaVenta">
                          <Column field="Nombre" footer="TOTAL INGRESOS:" header="Nombre" style="width:30%;"></Column>
                        <Column field="Descripcion" footer="" header="Descripción" style="text-aligne: center;width:30%;"></Column>
                        <Column field="Modalidad" footer="" header="Modalidad" style="width:20%;"></Column>
                        <Column field="Monto" :footer="TotalMontoIngreso" header="Monto" style="width:20%;"></Column>
                  </DataTable>
          
                   </div> 
             
            </div>
            <div class="card" style="height: 400px" v-if="!vistaReporte">
           <h3 style="margin: auto;">Seleccione los filtros</h3>
            </div>
            <div class="card" style="height: 400px" v-if="vistaReporte && VistaSinData">
                     <h3 style="margin: auto;">No se ha encontrado información para estos datos Seleccionados,
                                              Por favor intentar con otros datos!</h3></div>
					</div>
				</div>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
// import Examplemodal from '@/components/Examplemodal.vue'
import axios from 'axios'




export default {
  name: 'rpt_caja_general',
  components: {
    Menu,
    Header,
    flatPickr,
    DataTable,
    Column,
    ProgressSpinner,
    // Examplemodal
  },
  data() {
    return {
         BASE_URL: process.env.VUE_APP_BASE_URL,
         TipoModal:"",
         TOKEN:'',
         MODIFICADOR:'',
         Buscar:'',
         UsuarioID:"",
         showModal: false,
         showcontenedor:false,
         listado_sucursal:[],
         sucursal:null,
         RolUser:'',

        selectCombo:true,
         TipoFecha:'',
         FechaMes:false,
         FechaDia:true,
         FechaMesValue:null,
         FechaDiaValue:null,
         ReporteMes:{
            SucursalID:null,
            Mes:null,
            Anio:null,
            ProveedorID:null,
         },
         ReporteDia:{
          SucursalID:null,
            Fecha:null,
         },
         loading:false,
         vistaReporte:false,
         VistaSinData:true,
         TablaMesCompra: false,
         TablaMesVenta:false,

         TablaDiaCompra:false,
         TablaDiaventa:false,

         ReporteDataGeneral:[],
         ReporteDataSaldoCliente:[],
         ReporteDataSaldoProveedor:[],
         ReporteDataPagoOtros:[],
         ReporteDataIngreso:[],
         TipoSucursal:null,



         TotalMontoSaldoCliente:null,
         TotalMontoSaldoProveedor:null,
         TotalSaldoFinal:null,

         TotalMontoIngreso:null,
           descargar:false,
  
    }
  },
  mounted() {
    this.obtenerPermiso();
    this.obtenerSucursal();

  },
watch: {
    // whenever question changes, this function will run
    FechaMesValue: function (newValue) {
      this.ReporteMes.Mes=moment(newValue).month()+1
      this.ReporteMes.Anio=moment(newValue).year()
      },
      FechaDiaValue: function (newValue) {
        this.ReporteDia.Fecha=moment(newValue).format("YYYY-MM-DD")
      }
    },
  

 created() {
 this.validacionStorage();
 },
 
computed:{

    },

methods: {

getDateFormat(date){
return moment(date).format("DD-MM-YYYY")
},
   obtenerSucursal() {
    this.sucursal= JSON.parse(localStorage.getItem('sucursal-ID'));
    this.ReporteMes.SucursalID = this.sucursal;
    this.ReporteDia.SucursalID = this.sucursal;
      axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
        //   this.listado_sucursal = response.data.respuesta;
        this.listado_sucursal=[];
        response.data.respuesta.forEach(ele => {
            if (ele.TipoSucursal == 0) {
                this.listado_sucursal.push(ele);
            }
            
        });
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
					if (this.RolUser ==="Administrador") {
						 $('#sucursal').removeAttr('disabled');
				}

          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
				multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            this.ReporteMes.SucursalID= this.sucursal
            this.ReporteDia.SucursalID= this.sucursal
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);

            });
         
            $("#tipoFecha")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
			        	multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.seleccionTipo(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
        
        });
       
         
      
    },
        generarReporte(){
       
          if(this.FechaDia){
            console.log(this.ReporteDia);
            if (this.ReporteDia.SucursalID != null && this.ReporteDia.Fecha != null) {

                this.loading=true;
              axios.post(this.BASE_URL + "/reporte/caja/general",this.ReporteDia, { headers: { 'Authorization': this.TOKEN } })
              .then((response) => {
                        this.vistaReporte=true;
                        this.ReporteDataGeneral=[],this.ReporteDataSaldoCliente=[],this.ReporteDataSaldoProveedor=[],this.ReporteDataPagoOtros=[],this.ReporteDataIngreso=[];;
                        this.ReporteDataGeneral=response.data.General;
                        this.ReporteDataSaldoCliente=response.data.SaldoCliente;
                        this.ReporteDataSaldoProveedor=response.data.SaldoProveedor;
                         this.ReporteDataIngreso=response.data.Ingreso;

                      if (response.data) {
                          this.VistaSinData=false;
                          this.TablaMesCompra = false;
                          this.TablaMesVenta = false;
                          this.TablaDiaCompra = true;
                          this.TablaDiaVenta = true;
                          this.calculoReporte();
                          this.loading=false;      
                      }else{
                        this.TablaMesCompra=false,
                        this.TablaMesVenta=false,
                        this.TablaDiaCompra=false,
                        this.TablaDiaVenta=false,
                        this.VistaSinData=true;
                        this.loading=false;
                      }
              });
            
            }else{
              this.TablaMesCompra=false,
              this.TablaMesVenta=false,
              this.TablaDiaCompra=false,
              this.TablaDiaVenta=false,
              this.vistaReporte=false;
              this.alertaErrorPeticion();
            }
       
          }else{
             this.alertaErrorPeticion();
          }
    
    },
    DescargarPDF(){
            this.loading = true;
            setTimeout(function() { 
              var element = document.getElementById('id-elemento-pdf')
                  var opt = {
            margin:       0.2,
            filename:     'CajaGeneral.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 4 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
          };
          html2pdf().from(element).set(opt).save();
            }, 100);
             setTimeout(() => {
              this.descargar=false;
               this.descargar=false;
               this.loading = false;
             }, 2000);
          
        },
    calculoReporte(){
                    // this.GeneralCajaInicial=0,this.GeneralCajaFinal= 0,this.GeneralIngreso=0,this.GeneralEgreso=0;
                    this.TotalMontoSaldoCliente=0, this.TotalMontoSaldoProveedor=0, this.TotalMontoOtros=0,this.TotalSaldoFinal =0,this.TotalMontoIngreso=0;
                    //  if (this.ReporteDataGeneral.length>0) {
                    //     this.GeneralCajaInicial=this.ReporteDataGeneral[0].CajaInicial;
                    //     this.GeneralCajaFinal= this.ReporteDataGeneral[0].CajaFinal;
                    //     this.GeneralIngreso=this.ReporteDataGeneral[0].Ingreso;
                    //     this.GeneralEgreso=this.ReporteDataGeneral[0].Egreso; 
                    // }
                    if (this.ReporteDataSaldoCliente.length>0) {
                        this.ReporteDataSaldoCliente.forEach(ele => {
                             ele.Fecha= this.getDateFormat(ele.Fecha);
                             this.TotalMontoSaldoCliente= this.TotalMontoSaldoCliente + parseInt(ele.SaldoFinal);
                        });
                    }  
                    if (this.ReporteDataSaldoProveedor.length>0) {                    
                        this.ReporteDataSaldoProveedor.forEach(ele => {
                             ele.Fecha= this.getDateFormat(ele.Fecha);
                            this.TotalMontoSaldoProveedor=this.TotalMontoSaldoProveedor + parseInt(ele.SaldoFinal);
                        });
                    }  
                    if (this.ReporteDataIngreso.length>0) {                    
                        this.ReporteDataIngreso.forEach(ele => {
                             ele.Fecha= this.getDateFormat(ele.Fecha);
                            this.TotalMontoIngreso=this.TotalMontoIngreso + parseInt(ele.Monto);
                        });
                    }
                      this.TotalSaldoFinal = this.ReporteDataGeneral[0].CajaFinal +this.TotalMontoIngreso+ this.TotalMontoSaldoCliente +this.TotalMontoSaldoProveedor;
                    // if (this.ReporteDataPagoOtros.length>0) {
                    //     this.ReporteDataPagoOtros.forEach(ele => {
                    //         // this.NombreDescripcion = ele.Nombre + ele.Descripcion;
                    //          this.TotalMontoOtros=this.TotalMontoOtros + parseInt(ele.Monto);
                    //     });
                    // }  
    },
    seleccionTipo(event){
      this.selectCombo = true;
      var TipoID = event.target.value;
      if (TipoID == 1) {
           this.FechaMes = false;
           this.FechaDia = true;
      }else if (TipoID == 2) {
           this.FechaDia = false;
           this.FechaMes = true;
      }
    },
 
  	validacionStorage(){
      this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
      this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
        if(this.TOKEN ===  null){
          this.$router.push('/login');
      }
	
    },
    obtenerPermiso(){
        var pathname = window.location.pathname,firstObj=null, lista_mod=[];
        lista_mod= JSON.parse(localStorage.getItem('Modulos'))
        var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
        firstObj = (results.length > 0) ? results[0] : null;
        if(firstObj== null || firstObj== ""){
         this.showcontenedor=false
         window.location.href = "/home"
         }else{
                  this.showcontenedor=true
          }
           },
	alertaErrorPeticion(){
    Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })

},

}
}
 
</script>
<script>



</script>
<style>
.span-width{
    display: inline-flex;
    width: 100px !important;
}
.color-color{
    padding: 8px;
    width: 100%;
}
.color-green{
    /* background-color: #09a75947; */
    padding: 8px;
    width: 100%;
}
.color-red{
    /* background-color: #d62d208c; */
    padding: 8px;
    width: 100%;
}
.p-calendar .p-component{
  background: #f5f8fa !important;
  border: none !important;
}
#tabla_cliente td{
    padding: 0px !important;
}
#tabla_proveedor td{
    padding: 0px !important;
}


@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

</style>
