<template>
     <div class="modal-content">
    <!--begin::Modal header-->
    <div class="modal-header" id="kt_modal_edit_user_header">
      <!--begin::Modal title-->
      <h2 v-if="dTipoModal == ModalEditar" class="fw-bold">Modificar Cliente</h2>
      <h2 v-if="dTipoModal == ModalAgregar" class="fw-bold">Agregar Cliente</h2>
      <!--end::Modal title-->
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Close-->
    </div>
    <!--end::Modal header-->
    <!--begin::Modal body-->
    <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
      <!--begin::Form-->
      <form id="kt_modal_edit_cliente_form" class="form" action="#">
        <!--begin::Scroll-->
        <div
          class="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_edit_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_edit_user_header"
          data-kt-scroll-wrappers="#kt_modal_edit_user_scroll"
          data-kt-scroll-offset="300px"
          style="max-height: 300px !important"
        >
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
            <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_cliente_form"
              data-placeholder="Seleccione Sucursal" name="sucursalcbo" id="sucursalcbo" v-model="this.Cliente.SucursalID">
              <option></option>
              <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                {{ lstsucursal.Nombre }}
              </option>
            </select>
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Zona:</label>
          <select class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_cliente_form"
              data-placeholder="Seleccione Zona"
              name="zonaid"
              id="zonaid"
              v-model="this.Cliente.ZonaID"
            >
              <option></option>
              <option v-for="(lstzona, index) in listado_zona" :key="index" :value="lstzona.ZonaID">
                {{ lstzona.Nombre }}
              </option>
            </select> 
          </div>
            <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Tipo de Documento:</label>
          <select
              class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_cliente_form"
              data-placeholder="Seleccione Tipo Documento"
              name="documento"
              id="documento"
              v-model="this.Cliente.TipoDocumentoID"
            >
              <option></option>
              <option v-for="(lstdocumento, index) in listado_tipo_documento" :key="index" :value="lstdocumento.TipoDocumentoID">
                {{ lstdocumento.Nombre }}
              </option>
            </select> 
            <!-- <select v-if="dTipoModal == ModalEditar" class="form-select form-select-solid" data-control="select2" data-allow-clear="true" disabled>
              <option selected>{{ dNombreCargo }}</option>
            </select> -->
            <!--end::Input-->
          </div>
           <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2" >Número Documento:</label>
            <!--end::Label-->
            <!--begin::Input-->
         <input type="text" class="form-control form-control-solid" v-model="this.Cliente.NumeroDocumento"/>
            <!-- <input v-if="TipoModal == this.ModalAgregar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" v-bind:value="CodigoUsuario"/> -->
            <!-- <input v-if="TipoModal == this.ModalEditar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" :value="this.CodigoUsuario"/> -->
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2" v-if="this.Cliente.TipoDocumentoID != 4">Nombre:</label>
            <label class="required fw-semibold fs-6 mb-2" v-if="this.Cliente.TipoDocumentoID == 4">Razón Social:</label>
           <!--end::Label-->
            <!--begin::Input-->
         <input  type="text" class="form-control form-control-solid" v-model="this.Cliente.Nombre"/>
            <!-- <input v-if="TipoModal == this.ModalAgregar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" v-bind:value="CodigoUsuario"/> -->
            <!-- <input v-if="TipoModal == this.ModalEditar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" :value="this.CodigoUsuario"/> -->
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <div class="fv-row mb-7 text-start" v-if="this.Cliente.TipoDocumentoID != 4">
            <!--begin::Label-->
            <label class="fs-6 fw-semibold form-label mt-3">
              <span class="required">Apellidos:</span>
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <input type="text" v-model="this.Cliente.Apellidos" class="form-control form-control-solid"/>
             <!--end::Input-->
          </div>
              <!--begin::Input group-->
            <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="fw-semibold fs-6 mb-2">Alias:</label>
            <!--end::Label-->
            <!--begin::Input-->
            <input type="text" v-model="this.Cliente.Alias" class="form-control form-control-solid"/>
           
            <!-- <select v-if="dTipoModal == ModalEditar" class="form-select form-select-solid" data-control="select2" data-allow-clear="true" disabled>
              <option selected>{{ dNombreCargo }}</option>
            </select> -->
            <!--end::Input-->
          </div>
        <!--begin::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="fw-semibold fs-6 mb-2">Dirección Domicilio:</label>
            <!--end::Label-->
            <!--begin::Input-->
         <input type="text" class="form-control form-control-solid" v-model="this.Cliente.Direccion"/>
            <!-- <input v-if="TipoModal == this.ModalAgregar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" v-bind:value="CodigoUsuario"/> -->
            <!-- <input v-if="TipoModal == this.ModalEditar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" :value="this.CodigoUsuario"/> -->
            <!--end::Input-->
          </div>
            <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="fw-semibold fs-6 mb-2">Dirección Local:</label>
         <input type="text" v-model="this.Cliente.DireccionLocal" class="form-control form-control-solid"/>
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="fs-6 fw-semibold form-label mt-3">
              <span >Telefono:</span>
            </label>
            <input type="text" v-model="this.Cliente.Telefono" class="form-control form-control-solid"/>
          </div>
            <div class="fv-row mb-7 text-start">
            <label class="fs-6 fw-semibold form-label mt-3">
              <span>Correo:</span>
            </label>
            <input type="text" v-model="this.Cliente.Correo" class="form-control form-control-solid"/>
          </div>

        </div>
        <!--end::Scroll-->
        <!--begin::Actions-->
        <div class="text-center pt-15">
          <button @click="cerrarModal()" type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">
            Cancelar
          </button>
          <button v-if="dTipoModal == ModalEditar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Modificar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button v-if="dTipoModal == ModalAgregar" type="button" class="btn btn-primary" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Registrar</span>
            <span class="indicator-progress" >Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Modal body-->
  </div>
</template>
<script>
    import { defineComponent } from "vue";
    import axios from "axios";
    //import Usuario from '@/views/Usuario.vue'
    export default defineComponent({
    name: "Clientemodal",
    props: [
        "TipoModal",
        "ClienteID",
        "SucursalID",
        "ZonaID",
        "TipoDocumentoID",
        "NumeroDocumento",
        "Nombre",
        "Apellidos",
        "Alias",
        "Telefono",
        "Direccion",
        "DireccionLocal",
        "Correo",
    ],
   data() {
    return {
        BASE_URL: process.env.VUE_APP_BASE_URL,
        ModalAgregar: "mAgregar",
        ModalEditar: "mEditar",
        listado_sucursal:[],
        listado_tipo_documento:[],
        Cliente: {
          ClienteID: null,
          SucursalID: null,
          ZonaID: null,
          TipoDocumentoID: null,
          NumeroDocumento: null,
          Nombre: null,
          Apellidos: null,
          Alias: null,
          Telefono: null,
          Direccion: null,
          DireccionLocal: null,
          Correo: null,
          UsuarioID: null,
          },
        
      //  listado_zona:[{ZonaID:1, Nombre:"trapecio"},{ZonaID:2, Nombre:"Centro"},{ZonaID:3, Nombre:"San Luis"}],
      listado_zona:[],
         TOKEN:'',
         MODIFICADOR:'',
    }
   },
   created() {
    this.validacionStorage();

   },
   mounted() {

    this.asignacionVariable();
    this.obtenerTipoDocumento();
    this.obtenerZona();
    this.obtenerSucursal();
   },
   methods: {
    	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
    asignacionVariable(){
        this.dTipoModal =this.TipoModal;
        this.Cliente.UsuarioID= this.MODIFICADOR;
             if(this.TipoModal==this.ModalEditar){
                  this.Cliente.ClienteID= this.ClienteID;
                  this.Cliente.SucursalID= this.SucursalID;
                  this.Cliente.ZonaID= this.ZonaID;
                  this.Cliente.TipoDocumentoID= this.TipoDocumentoID;
                  this.Cliente.NumeroDocumento= this.NumeroDocumento;
                  this.Cliente.Nombre= this.Nombre;
                  this.Cliente.Apellidos= this.Apellidos;
                  this.Cliente.Alias= this.Alias;
                  this.Cliente.Telefono= this.Telefono;
                  this.Cliente.Direccion= this.Direccion;
                  this.Cliente.DireccionLocal= this.DireccionLocal;
                  this.Cliente.Correo= this.Correo;
                  this.Cliente.UsuarioID= this.MODIFICADOR;
                  $('#sucursalcbo').prop('disabled', true);

        }
      },
    obtenerSucursal(){
        axios
        .get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;


          $("#sucursalcbo")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
    
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
        
        });
    },
    obtenerTipoDocumento(){
      axios.get(this.BASE_URL+'/tipodocumento/listar',{headers:{'Authorization': this.TOKEN}})
                .then((response) => {
                    this.listado_tipo_documento = response.data.respuesta
            $("#documento")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
            // 
                });
    },
    obtenerZona(){
        axios.get(this.BASE_URL+'/zona/listar',{headers:{'Authorization': this.TOKEN}})
                .then((response) => {
                    this.listado_zona = response.data.respuesta
        $("#zonaid")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });

        });
    },
    validacionCampos(){
        if (this.TipoModal == this.ModalAgregar) {
            if(this.Cliente.SucursalID == ""||this.Cliente.ZonaID == ""||this.Cliente.TipoDocumentoID == ""||this.Cliente.NumeroDocumento == ""|| this.Cliente.Nombre == "" ){
            this.alertValidacion();
            }else{
            this.alerDialogRegistrar();
             }
         }else if(this.TipoModal == this.ModalEditar){
            if(this.Cliente.SucursalID == ""||this.Cliente.ZonaID == ""||this.Cliente.TipoDocumentoID == ""||this.Cliente.NumeroDocumento == ""|| this.Cliente.Nombre == ""){
               this.alertValidacion();
             }else{
         this.alerDialogModificar();
        }
        }
    },
    alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
    alerDialogRegistrar() {
      Swal.fire({
        text: "Estás seguro de que deseas Registrar? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Registrar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.registrarCliente();
          Swal.fire({
            text: "Se Registró correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
           
             if (result.isConfirmed) {
             this.cerrarModal()
            // window.location.href = "/cliente"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
            // not Remove current row
          
          });
        }
      });
    },
    registrarCliente: function () {
      axios
        .post(this.BASE_URL+"/cliente/insertar", this.Cliente,{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
    
        });
   
    },
     alerDialogModificar() {
 
         
      Swal.fire({
        text: "Estás seguro de que deseas Modificar? ",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Modificar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-warning",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.modificarCliente();
          Swal.fire({
            text: "Se modificó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
                   this.cerrarModal()
          //  window.location.href = "/cliente"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
       
          });
        }
      });
    },
    modificarCliente: function () {
      axios.post(this.BASE_URL+"/cliente/actualizar", this.Cliente,{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
  
             
        });
    },
    cerrarModal() {
      $("#kt_modal_cliente").modal("hide");
      this.$parent.showModal = false;
      this.$parent.obtenerPermiso();
      this.$parent.obtenerCliente();
    },
   },
    })

    </script>