<template>
  <div class="modal-content">
    <div class="modal-header" id="kt_modal_edit_user_header">
      <h2 v-if="TipoModal == ModalEditar" class="fw-bold">Modificar Usuario</h2>
      <h2 v-if="TipoModal == ModalAgregar" class="fw-bold">Agregar Usuario</h2>
      <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
          </svg>
        </span>
      </div>
    </div>
    <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
      <form id="kt_modal_edit_user_form" class="form" action="#">
        <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_edit_user_scroll" data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_edit_user_header" data-kt-scroll-wrappers="#kt_modal_edit_user_scroll"
          data-kt-scroll-offset="300px" style="max-height: 300px !important">
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
            <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione Sucursal" name="country2" id="country2" v-model="country2">
              <option></option>
              <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                {{ lstsucursal.Nombre }}
              </option>
            </select>
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Personal:</label>
            <select v-if="TipoModal == this.ModalAgregar" class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione al Personal" name="cbopersonal" id="cbopersonal" v-model="cbopersonal">
              <option></option>
              <option v-for="(lstpersonal, index) in listado_personal" :key="index" :value="lstpersonal.PersonalID">
                {{ lstpersonal.NombreCompleto }}
              </option>
            </select>
            <select v-if="TipoModal == this.ModalEditar" class="form-select form-select-solid" data-control="select2" 
            data-dropdown-parent="#kt_modal_edit_user_form" data-placeholder="Seleccione al Personal" data-allow-clear="true"
            disabled>
              <option selected> {{ UserNombre }}</option>
            </select>
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="fs-6 fw-semibold form-label mt-3">
              <span class="required">Código(DNI)</span>
              <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Es el código del usuario para el Login."></i>
            </label>
            <input type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" value="" disabled/>
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Contraseña:</label>
            <div class="fv-row" data-kt-password-meter="true">
              <div class="mb-1">
                <div class="position-relative mb-3">
                  <input class="form-control form-control-lg form-control-solid" type="password"
                    placeholder="" value="" id="txtcontraseña" name="txtcontraseña" autocomplete="off"/>
                  <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                    <i class="bi bi-eye-slash fs-2" v-if="iconoMostrar" @click="mostrarPass('mostrar')"></i>
                    <i class="bi bi-eye fs-2" v-if="!iconoMostrar" @click="mostrarPass('ocultar')"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Rol:</label>
            <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione el Rol" name="cborol" id="cborol" v-model="idRolEditable">
              <option></option>
              <option v-for="(lstrol, index) in listado_rol" :key="index" v-bind:value="lstrol.RolID">
                {{ lstrol.Nombre }}
              </option>
            </select>
          </div>
        </div>
        <div class="text-center pt-15">
          <button @click="cerrarModal()" type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">
            Cancelar
          </button>
          <button v-if="TipoModal == ModalEditar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Modificar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button v-if="TipoModal == ModalAgregar" type="button" class="btn btn-primary" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Registrar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
//import Usuario from '@/views/Usuario.vue'
export default defineComponent({
  name: "Usermodal",
  props: [
    "Modal",
    "idduser",
    "UserNombre",
    "Sucursal",
    "Codigo",
    "Password",
    "RolID",
    "RolNombre",
  ],

  data() {
    return {
    BASE_URL: process.env.VUE_APP_BASE_URL,
      idRolEditable: "",
      idNombreRol: "",
      UsuarioID: "",
      CodigoUsuario: "",
      Contraseña: "",
      ModalAgregar: "mAgregar",
      ModalEditar: "mEditar",
      TipoModal: "",
      country2: "",
      cbopersonal: "",
      cborol2: "",
      //  modal: null,
      form: null,
      listado_rol: [],
      listado_sucursal: [],
      listado_personal: [],
       TOKEN:'',
       MODIFICADOR:'',
      iconoMostrar:true,

    };
  },
  created() {
  this.validacionStorage();
  },
  mounted() {
    this.obtenerRol();
    this.obtenerSucursal();
  this.asignacionVariable()
  },


  methods: {

          	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
            parseoDNI(idpersonal){
            const DNI=[];
            var results = this.listado_personal.filter(function (personal) { return personal.PersonalID == idpersonal});
            var firstObj = (results.length > 0) ? results[0] : null;
            DNI.push(firstObj);
            DNI.forEach(object =>{
            document.getElementById("txtcodigo").value = object.NumeroDocumento;
              })
              
            },
   asignacionVariable(){
          this.idRolEditable = this.RolID;
          this.CodigoUsuario = this.Codigo;
          this.Contraseña = this.Password;
          this.idNombreRol = this.RolNombre;
          this.TipoModal = this.Modal;
          this.UsuarioID = this.idduser;
          this.country2=this.Sucursal;
          this.cbopersonal=this.UserNombre;

        if(this.TipoModal==this.ModalEditar){
             document.getElementById("txtcodigo").value=this.CodigoUsuario;
              document.getElementById("txtcontraseña").value=this.Contraseña;
                  $('#country2').prop('disabled', true);
                    $('#cbopersonal').prop('disabled', true);
        }
   },
      mostrarPass(accion){
          var input = document.getElementById("txtcontraseña");
        if (accion == 'mostrar') {
          input.type = "text";
          this.iconoMostrar = false;
        }else if(accion == 'ocultar'){
          input.type = "password";
          this.iconoMostrar = true;
        }
      },

    validacionCampos(){
         this.CodigoUsuario = document.getElementById("txtcodigo").value;
      this.Contraseña = document.getElementById("txtcontraseña").value;
        if (this.TipoModal == this.ModalAgregar) {
            if(this.cbopersonal == ""|| this.idRolEditable == "" || this.CodigoUsuario == "" || this.Contraseña == ""){
            this.alertValidacion();
            }else{
            this.alerDialogRegistrar();
             }
        }else if(this.TipoModal == this.ModalEditar){
            if(this.UsuarioID == ""|| this.idRolEditable == "" || this.CodigoUsuario == "" || this.Contraseña == ""){
               this.alertValidacion();
             }else{
            this.alerDialogModificar();
        }
        }
     
    },
    alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
    alerDialogRegistrar() {
      Swal.fire({
        text: "Estás seguro de que deseas Registrar? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Registrar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.registrarUsuario();
          Swal.fire({
            text: "Se Registró correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
            window.location.href = "/usuario"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    alerDialogModificar() {
        this.CodigoUsuario = document.getElementById("txtcodigo").value;
      this.Contraseña = document.getElementById("txtcontraseña").value;
         
      Swal.fire({
        text: "Estás seguro de que deseas Modificar? ",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Modificar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-warning",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.modificarUsuario();
          Swal.fire({
            text: "Se modificó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
           window.location.href = "/usuario"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    registrarUsuario: function () {
    this.$parent.obtenerPermiso()
      axios
        .post(this.BASE_URL+"/usuario/insertar", {
          PersonalID: this.cbopersonal,
          RolID: this.idRolEditable,
          Codigo: this.CodigoUsuario,
          Contraseña: this.Contraseña,
          UsuarioID: this.MODIFICADOR,
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
        });
    },

    modificarUsuario: function () {
      this.$parent.obtenerPermiso()
      axios
        .post(this.BASE_URL+"/usuario/actualizar", {
          UsuarioID: this.UsuarioID,
          RolID: this.idRolEditable,
          Codigo: this.CodigoUsuario,
          Contraseña: this.Contraseña,
          UsuarioActualizacionID: this.MODIFICADOR,
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
        });
    },

    obtenerSucursal() {
      axios
        .get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
          $("#country2")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.listado_personal = [];
              this.obtenerPersonal(this.country2);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
          $("#cbopersonal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
             this.parseoDNI(this.cbopersonal)
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              //e.params.data.element.parentElement.dispatchEvent(event);
            });
        });
    },
    obtenerPersonal() {
      axios
        .post(
          this.BASE_URL+"/personal/obtener/porsucursal",
          { SucursalID: this.country2 },{headers:{'Authorization': this.TOKEN}}
        )
        .then((response) => {
          this.listado_personal = response.data.respuesta;
        });
    },
    obtenerRol: function () {
      axios
        .get(this.BASE_URL+"/rol/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
             this.listado_rol = response.data.respuesta;
            
          $("#cborol")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            });
        });
    },
    cerrarModal() {
      $("#kt_modal_edit_user").modal("hide");
      this.$parent.showModal = false;
      this.$parent.obtenerPermiso()
    },
  },
});
</script>