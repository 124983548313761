import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Usuario from '../views/Usuario.vue'
import Personal from '../views/Personal.vue'
import Cliente from '../views/Cliente.vue'
import Proveedor from '../views/Proveedor.vue'
import Rol from '../views/Rol.vue'
import Zona from '../views/Zona.vue'
import Cargo from '../views/Cargo.vue'
import Acceso from '../views/ModuloRol.vue'
import Error from '../views/Invalido.vue'
import Compra from '../views/Compra.vue'
import Saldo from '../views/Saldo.vue'
import DevolucionCompra from  '../views/DevolucionCompra.vue'
import Venta from '../views/Venta.vue'
import Pago from "../views/PagoProveedor.vue";
import PagoPersonal from "../views/PagoPersonal.vue";
import PagoOtro from "../views/PagoOtros.vue";
import Ingreso from "../views/Ingreso.vue";
import CobroCliente from "../views/CobroCliente.vue";
import rpt_compra_venta from "../views/rpt_compra_venta.vue";
import rpt_compra_venta_proveedor from "../views/rpt_compra_venta_proveedor.vue";
import rpt_utilidad_neta from "../views/rpt_utilidad_neta.vue";
import rpt_merma_stock from "../views/rpt_merma_stock.vue"
import rpt_kardex from "../views/rpt_kardex.vue"
import stock from "../views/Stock.vue";
import muerto from "../views/Muerto.vue";
import rpt_caja_diario from "../views/rpt_caja.vue"
import rpt_caja_general from "../views/rpt_caja_general.vue"
const routes = [
  {
    path:'/',
    component: Login
  },
  {
    path:'/login',
    name: 'login',
    component: Login
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: Error,
    meta: {
      requiresAuth: false
    }
  },
  
  {
    path:'/error',
    name: 'Error',
    component: Error,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
   // meta:{requiresAuth: true}
  },
  
  {
    path: '/usuario',
    name: 'usuario',
    component: Usuario,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: Personal,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/cliente',
    name: 'cliente',
    component: Cliente,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  ,
  {
    path: '/proveedor',
    name: 'proveedor',
    component: Proveedor,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/rol',
    name: 'rol',
    component: Rol,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  ,
  {
    path: '/zona',
    name: 'zona',
    component: Zona,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  ,
  {
    path: '/cargo',
    name: 'cargo',
    component: Cargo,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  ,
  {
    path: '/acceso',
    name: 'acceso',
    component: Acceso,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  } ,
  {
    path: '/compra',
    name: 'Compra',
    component: Compra,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  ,
  {
    path: '/saldo',
    name: 'Saldo',
    component: Saldo,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  } ,
  {
    path: '/devolucion_compra',
    name: 'Devolucion_compra',
    component: DevolucionCompra,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/venta',
    name: 'Venta',
    component: Venta,
  },
  {
    path: '/devolucion_venta',
    name: 'Devolucion_venta',
    component: DevolucionCompra,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/pago',
    name: 'pago',
    component: Pago,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/pago_personal',
    name: 'pago_personal',
    component: PagoPersonal,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/pago_otro',
    name: 'pago_otro',
    component: PagoOtro,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  ,
  {
    path: '/ingreso',
    name: 'ingreso',
    component: Ingreso,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/abono',
    name: 'abono',
    component: CobroCliente,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  ,
  {
    path: '/rpt_compra_venta',
    name: 'rpt_compra_venta',
    component: rpt_compra_venta,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  
  {
    path: '/rpt_compra_venta_proveedor',
    name: 'rpt_compra_venta_proveedor',
    component: rpt_compra_venta_proveedor,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/utilidad_neta',
    name: 'rpt_utilidad_neta',
    component: rpt_utilidad_neta,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/rpt_merma_stock',
    name: 'rpt_merma_stock',
    component: rpt_merma_stock,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/rpt_kardex',
    name: 'rpt_kardex',
    component: rpt_kardex,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/stock',
    name: 'stock',
    component: stock,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/muerto',
    name: 'muerto',
    component: muerto,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/rpt_caja_diario',
    name: 'rpt_caja_diario',
    component: rpt_caja_diario,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  },
  {
    path: '/rpt_caja_general',
    name: 'rpt_caja_general',
    component: rpt_caja_general,
    // meta:{requiresAuth: true}
    // component: () => import( '../views/Usuario.vue')
  }
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from, next)=>{
  if (to.matched.some(route=>route.meta.requiresAuth)) {
    if (!store.state.loggedIn) {
      next('/login')
    }else{
     next('/home')
    }
  }else{
   next()
  }
})
export default router
