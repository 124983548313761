<template>
    	<div class="d-flex flex-column flex-root">
			<!--begin::Login-->
			<div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
				<!--begin::Aside-->
				<div class="login-aside banerizquierdo d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style="background-image: url(resources/assets/media/bg/bg-4.jpg);">
					<!--begin: Aside Container-->
					<div class="d-flex flex-row-fluid flex-column justify-content-between">
						<!--begin: Aside header-->
						<a href="#" class="flex-column-auto mt-5 pb-lg-0 pb-10">
							<img src="resources/assets/media/logos/logo-letter-1.png" class="imglogo-login" alt="" />
						</a>
						<!--end: Aside header-->
						<!--begin: Aside content-->
						<div class="flex-column-fluid d-flex flex-column justify-content-center">
							<h3 class="font-size-h1 mb-5 text-white">Welcome to Metronic!</h3>
							<p class="font-weight-lighter text-white opacity-80">The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme framework for next generation web apps.</p>
						</div>
						<!--end: Aside content-->
				
					</div>
					<!--end: Aside Container-->
				</div>
				<!--begin::Aside-->
				<!--begin::Content-->
				<div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
				
					<!--begin::Content body-->
					<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
						<!--begin::Signin-->
						<div class="login-form login-signin">
							<div class="text-center mb-10 mb-lg-20">
								<h1 >Inicio Sesión</h1>
								<p class="text-muted font-weight-bold">Ingrese su usuario y contraseña</p>
							</div>
							<!--begin::Form-->
							<form class="form" novalidate="novalidate" id="kt_login_signin_form">
								<div class="form-group">
									<input class="form-control form-control-solid h-auto inputlogin" type="text" placeholder="Username" id="txtusuario" name="txtusuario" value="" autocomplete="off" />
								</div>
								<div class="form-group">
									<input class="form-control form-control-solid h-auto inputlogin" type="password" placeholder="Password" id="txtpassword" name="txtpassword" value="" autocomplete="off" />
								</div>
								<!--begin::Action-->
								<div class="form-group justify-content-center align-items-center">
								
									<button type="button" id="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3" @click="ingresarLogin()">Ingresar</button>
								</div>
								<!--end::Action-->
							</form>
							<!--end::Form-->
						</div>
						<!--end::Signin-->
					
			
					</div>
					<!--end::Content body-->
				
				
				</div>
				<!--end::Content-->
			</div>
			<!--end::Login-->
		</div>
</template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex';
export default {
    
    data() {
        return {
              BASE_URL: process.env.VUE_APP_BASE_URL,
            txtuser:"",
            txtpass:"",
            data_login:[],
			listado_db:[],

        }
    },
	created() {
		//this.validacionStorage();
	},
    methods: {
       ingresarLogin(){
        this.txtuser=document.getElementById("txtusuario").value;
        this.txtpass=document.getElementById("txtpassword").value
         axios.post(this.BASE_URL+"/usuario/acceso",{Codigo:this.txtuser,Contraseña:this.txtpass})
        .then((response) => {
          this.data_login = response.data.respuesta;
		 this.data_login.forEach(object =>{
			localStorage.setItem('user-nombre',JSON.stringify(object.NombreCompleto))
	    	localStorage.setItem('user-ID',JSON.stringify(object.UsuarioID))
		  	localStorage.setItem('TOKEN',JSON.stringify(object.TOKEN))
		    localStorage.setItem('RolNombre',JSON.stringify(object.RolNombre))
			localStorage.setItem('sucursal-ID',JSON.stringify(object.SucursalID))
		
			this.validacionStorage();
		});
        });
        
       } ,
	   	validacionStorage(){
			const TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
			if(TOKEN ===  null){
			 
			}else{
				this.mockLogin()
				 window.location.href = "/home"
			}
		},
		...mapActions([
			'mockLogin'
		])
    },
}
</script>


<style scoped>
.login{
    height: 100vh;
}
.banerizquierdo{
    width: 40%;
}

.inputlogin{
    width: 400px !important;
    margin-top: 20px;
    margin-bottom: 20px;
}
.imglogo-login{
width: 100px;
}
@media screen and (max-width: 400px) {

}
@media screen and (max-width: 991px) {
    .banerizquierdo{
    width: 100%;
}
.inputlogin{
    width: 300px !important;
    margin-top: 20px;
    margin-bottom: 20px;
}
}
</style>