<template>
    <div class="modal-content">
        <!-- INICIO::CABECERA DEL MODAL (TITULO Y BOTON DE "X") -->
        <div class="modal-header" id="kt_modal_rol_header">
            <!-- INICIO::TITULO DE TEXTO -->
            <h2 v-if="this.vtipomodal==this.modalmodificar" class="fw-bold">{{this.modalmodificar}}</h2>
            <h2 v-else class="fw-bold">{{this.modalagregar}}</h2>
            <!-- FIN::TITULO DE TEXTO -->
            <!-- <h2 class="fw-bold">{{ vusuarioId }}</h2>
            <h2 class="fw-bold">{{ vnombre }}</h2> -->
            <!-- INICIO::ICONO DE CERRAR MODAL -->
            <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                <span class="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                            transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                            fill="currentColor" />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </div>
            <!-- FIN::ICONO DE CERRAR MODAL -->
        </div>
        <!-- FIN::CABECERA DEL MODAL (TITULO Y BOTON DE "X") -->
        <!-- INICIO::CAJAS DE TEXTOS NOMBRE, USUARIO EIDTOR Y BOTONES-->
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <form id="kt_modal_accion_rol" class="form" action="#">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_edit_user_scroll" data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_edit_user_header"
                    data-kt-scroll-wrappers="#kt_modal_edit_user_scroll" data-kt-scroll-offset="300px"
                    style="max-height: 300px !important">
                    <!-- INICIO::CAJA DE TEXTO NOMBRE -->
                    <div class="fv-row mb-7 text-start">
                        <!-- INICIO::TEXTO NOMBRE ROL-->
                        <label class="fs-6 fw-semibold form-label mt-3">
                            <span class="required">Nombre</span>
                            <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip"
                                title="Ingrese el nombre del Rol"></i>
                        </label>
                        <!-- FIN::TEXTO NOMBRE ROL-->
                        <!-- INICIO::CAJA DE TEXTO NOMBRE ROL-->
                        <input v-if="this.vtipomodal==this.modalmodificar" type="text" class="form-control form-control-solid" name="txtnombre" id="txtnombre"
                            v-bind:value=vnombre />
                        <input v-else type="text" class="form-control form-control-solid" name="txtnombre" id="txtnombre" autocomplete="off"
                            />
                        <!-- FIN::CAJA DE TEXTO NOMBRE ROL-->
                    </div>
                    <!-- FIN::CAJA DE TEXTO NOMBRE -->
                    <!-- INICIO::CAJA DE TEXTO USUARIO -->
                 
                    <!-- FIN::CAJA DE TEXTO USUARIO -->
                </div>
                <!-- INICIO::BOTONES DE CANCELAR Y GUARDAR -->
                <div class="text-center pt-15">
                    <!-- INICIO::BOTON CANCELAR -->
                    <button @click="cerrarModal()" type="reset" class="btn btn-light me-3"
                        data-kt-users-modal-action="cancel">
                        Cancelar
                    </button>
                    <!-- FIN::BOTON CANCELAR -->
                    <!-- INICIO::BOTON MODIFICAR -->
                    <button v-if="this.vtipomodal==this.modalmodificar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="validacionCampos()">
                        <span class="indicator-label">Modificar</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                    <!-- FIN::BOTON MODIFICAR -->
                    <!-- INICIO::BOTON AGREGAR -->
                    <button v-else type="button" class="btn btn-primary"
                        data-kt-users-modal-action="button" @click="validacionCampos()">
                        <span class="indicator-label">Registrar</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                    <!-- FIN::BOTON AGREGAR -->
                </div>
                <!-- FIN::BOTONES DE CANCELAR Y GUARDAR -->
            </form>
        </div>
        <!-- FIN::CAJAS DE TEXTOS NOMBRE, USUARIO EIDTOR Y BOTONES -->
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            Nombre: "",
            RolID:"",
            UsuarioID:"",
            modalmodificar:"Modificar Rol",
            modalagregar:"Agregar Rol",
            TOKEN:'',
            MODIFICADOR :'',
        }
    },
    props: [
        "vrolId",
        "vusuarioId",
        "vnombre",
        "vtipomodal",
        "NombreUsuario"
    ],
    created() {
     this.validacionStorage();
    },
    methods: {
          	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
       validacionCampos(){
       this.Nombre = document.getElementById("txtnombre").value;
        if (this.vtipomodal==this.modalagregar) {
            if(this.Nombre == ""){
            this.alertValidacion();
            }else{
            this.alerDialogRegistrar();
             }
        }else if(this.vtipomodal==this.modalmodificar){
            if(this.Nombre == ""){
               this.alertValidacion();
             }else{
            this.alerDialogModificar();
        }
        }
     
    },
    alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
        // INICIO::METODO CERRAR MODAL
        cerrarModal() {
            $("#kt_modal_rol").modal("hide");
            this.$parent.showModal = false;
        },
        // FIN::METODO CERRAR MODAL
        // INICIO::ALERTA DE MODIFICAR
        alerDialogRegistrar() {
      Swal.fire({
        text: "Estás seguro de que deseas Registrar? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Registrar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.insertarRol();
          Swal.fire({
            text: "Se Registró correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
            window.location.href = "/rol"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
            // not Remove current row
          });
        }
      });
    },
       alerDialogModificar() {
 
         
      Swal.fire({
        text: "Estás seguro de que deseas Modificar? ",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Modificar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-warning",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.modificarRol();
          Swal.fire({
            text: "Se modificó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
           window.location.href = "/rol"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {

          });
        }
      });
    },
        // FIN::ALERTA DE MODIFICAR
        // INICIO::METODO DONDE SE MODIFACARA EL ROL EN LA BD
        modificarRol(){
            this.Nombre = document.getElementById("txtnombre").value;
            let direccion = this.BASE_URL+"/rol/actualizar";
            axios.post(direccion, { RolID: this.vrolId, Nombre:this.Nombre, UsuarioID: this.MODIFICADOR },{headers:{'Authorization': this.TOKEN}}).then(response => {

            })
        },
        // INICIO::METODO DONDE SE MODIFACARA EL ROL EN LA BD
        insertarRol(){
            
            let direccion = this.BASE_URL+"/rol/insertar";
            axios.post(direccion, {Nombre:this.Nombre, UsuarioID: this.MODIFICADOR},{headers:{'Authorization': this.TOKEN}}).then(response => {

            })
        }
    },
    

    // props : [
    //     vrolId,
    //     vusuarioId,
    //     vnombre
    // ]

}
</script>