<template>
    <div class="d-flex flex-column flex-root">
        <div class="page d-flex flex-row flex-column-fluid">
            <Menu></Menu>
            <div class="wrapper d-flex flex-column flex-row-fluid">
                <Header></Header>
                <div id="kt_content" class="container-xxl">
                    <div class="card">
                        <div class="card-header border-0 pt-6">
                            <div class="fv-row mb-7 text-start">
                                <!--begin::Label-->
                                <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <select class="form-select form-select-solid"
                                data-dropdown-parent="#kt_content"
                                data-placeholder="Seleccione Sucursal" multiple="multiple" 
                                  data-close-on-select="false"
                                name="sucursal[]" id="sucursal" v-model="sucursal" disabled style="width: max-content !important;">
                                <option></option>
                                <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
                                  {{ lstsucursal.Nombre }}
                                </option>
                                </select>
                            </div>
							<div class="fv-row text-start" >
                                <label class="form-label">Fecha:</label>
								<label id="fechaini" style="display:none" ></label>
								<label id="fechafini" style="display:none"></label>
								<input class="form-control form-control-solid" placeholder="Ingrese la fecha" id="fecharango" name="fecharango"/>
                               
                            </div>
                            <div class="fv-row mb-7 text-bottom my-9">
                                <button type="button" class="btn btn-primary"
                                    @click="obtenerPagos()">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                   Filtrar
                                </button>
                            </div>
                            <div class="fv-row mb-7 text-bottom my-9">
                                <button type="button" class="btn btn-primary" @click="accionsaldo('','agregar')">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->Agregar Pago
                                </button>
                            </div>
                            <div class="modal fade" id="kt_modal_pago" tabindex="-1" aria-hidden="true"
                                data-bs-backdrop="static" data-bs-keyboard="false">
                                <div class="modal-dialog modal-dialog-centered mw-650px">
                                    <PagoPersonalmodal v-if="showModal"
                                    :TipoModal="this.TipoModal"
                                    :ListaObjeto ="this.lista_objeto">
                                    </PagoPersonalmodal>
                                </div>
                            </div>
                        </div>
                        <div class="card-header border-0 pt-6">
                            <div class="card-title">
                                <!--begin::Search-->
                                <div class="d-flex align-items-center position-relative my-1">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                                                rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                    <input type="text" data-kt-user-table-filter="search"
                                        class="form-control form-control-solid w-250px ps-14" v-model="buscarsaldo"
                                        placeholder="Buscar Nombre" />
                                           <div class="fv-row ms-5 text-bottom my-9" v-if="btnValidar">
                                          <button type="button" class="btn btn-primary" @click="alertaCheck()">
                                          Validar 
                                          </button>
                                              </div>
                                </div>
                                <!--end::Search-->
                            </div>
                        </div>
                        <div class="card-body py-4">
                            <div class="table-responsive container">
                                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                    <thead>
                                        <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th class="max-w-60px text-center">Validar</th>
                                            <th class="min-w-125px text-center" scope="col">Nombre</th>
                                            <th class="min-w-125px text-center" scope="col">Modalidad Pago</th>
                                            <th class="min-w-120px text-center" scope="col">Numero Operacion</th>
                                            <th class="min-w-120px text-center" scope="col">Monto</th>
                                            <th class="min-w-125px text-center" scope="col">Sucursal</th>
                                            <th class="min-w-120px text-center" scope="col">Fecha Pago</th>
                                            <th class="min-w-120px text-center" scope="col">Fecha Registro</th>
                                            <th class="min-w-125px text-center" scope="col">Registro</th>
                                            <th class="min-w-125px text-center" scope="col">Accion</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 fw-semibold">
                                        <tr v-for="(listapago, index) in BuscarSaldo" :key="index">
                                            <td class="text-center"><span v-if="listapago.Conformidad == 1" title="Confirmado por el Administrador"><i class="fas fa-user-check"></i></span><input type="checkbox" v-if="listapago.Conformidad != 1" :checked="listapago.Conformidad == 1" class="custom-control-input" @change="checkValidar(listapago.PagoID,$event.target.checked)" :id='"jaba"+index' style="cursor: pointer"></td>
                                            <td class="text-center fs-6">{{ listapago.Nombre }}</td>
                                            <td class="text-center">{{ listapago.NombreModalidadPago }}</td>
                                            <td class="text-center">{{ listapago.NumeroOperacion }}</td>
                                            <td class="text-center">S/{{ listapago.Monto }}</td>
                                            <td class="text-center fs-8">{{ listapago.NombreSucursal }}</td>
                                            <td class="text-center">{{getDateFormat(listapago.Fecha)}}</td>
                                            <td class="text-center">{{getDateFormat(listapago.FechaRegistro)}}</td>
                                            <td class="text-center fs-8">{{ listapago.UsuarioRegistroNombre }}</td>
                                            <td>
                                                <div class="d-flex justify-content-center flex-shrink-0">
                                                    <button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1" @click="accionsaldo(listapago,'editar')">
                                                        <!-- <i class="fas fa-trash"></i> -->
                                                        <i class="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                  <button type="button" title="Eliminar Pago" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" @click="alertaEliminar(listapago.PagoID)">
                                                  <i class="fas fa-trash"></i>
                                                  </button >  
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import PagoPersonalmodal from '@/components/Pagopersonalmodal.vue'
import axios from 'axios';
export default defineComponent( {
    name: "Pago",
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASE_URL,
      TOKEN: '',
      MODIFICADOR: '',
      showModal: false,
      buscarsaldo:'',
      listado_tipopago: [],
      listado_sucursal:[],
      listado_datospago:[],
      fechastart:"",
		  fechaend:"",
      sucursal:[],
      RolUser:"",
      TipoModal:'',
      lista_objeto:{},
      btnValidar:false,
          arrayValidar: {
            UsuarioID:null,
            ArrayPagoID:[],
      },
    }
  },
  components: {
        Header,
        Menu,
        PagoPersonalmodal,
  },
    mounted() {
    this.obtenerSucursal();
    },
    created() {
    this.validacionStorage();
    },

    computed: {
     BuscarSaldo() {
      return this.listado_datospago.filter(item => {
      return item.Nombre.toLowerCase().includes(this.buscarsaldo.toLowerCase())
      //item.NombreCompleto.toLowerCase().includes(this.BuscarUsuario.toLowerCase())
      });
    },
    },
  methods: {
  validacionStorage() {
    this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
    this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
    if (this.TOKEN === null) {
      window.location.href = "/login"
    }
  },
  	getDateFormat(fecha){
	return moment(fecha).format("YYYY-MM-DD ");
	},
  fechafiltro(){
		this.fechastart= document.getElementById('fechaini').innerHTML;
		this.fechaend = document.getElementById('fechafini').innerHTML;
	},
	fechaoriginal(){
	  var start = moment().subtract(7, 'days');
		var end = moment();
    function cb(start, end) {
			$('#fechaini').html(start.format('YYYY-MM-DD'));
			$('#fechafini').html(end.format('YYYY-MM-DD'));
		}
		$('#fecharango').daterangepicker({
			startDate: start,
			endDate: end,
    }, cb)
		cb(start, end);
			this.fechastart= document.getElementById('fechaini').innerHTML;
			this.fechaend = document.getElementById('fechafini').innerHTML;
		},
  obtenerSucursal(){
    this.fechafiltro();
    this.sucursal.push(JSON.parse(localStorage.getItem('sucursal-ID')));
    axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
    .then((response) => {
      this.listado_sucursal = response.data.respuesta;
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
			this.fechaoriginal();
      this.obtenerPagos();
		  if (this.RolUser ==="Administrador") {
				$('#sucursal').removeAttr('disabled');
			}
      $("#sucursal")
      .select2()
      .on("select2:select", (e) => {
      const event = new Event("change", {
      bubbles: true,
      cancelable: true,
			multiple:true
      });
      e.params.data.element.parentElement.dispatchEvent(event);
      })
      .on("select2:unselect", (e) => {
        const event = new Event("change", {
          bubbles: true,
          cancelable: true,
        });
        e.params.data.element.parentElement.dispatchEvent(event);
      });
         
    });
  },
  obtenerPagos(){
    this.fechafiltro();
    var sucursal = this.sucursal.toString();
    axios.post(this.BASE_URL + "/pago/obtener",{ SucursalID: sucursal, FechaInicial: this.fechastart, FechaFinal: this.fechaend, TipoPagoID:3}, { headers: { 'Authorization': this.TOKEN } })
    .then((response) => {
      this.listado_datospago = response.data.respuesta;         
    });
  },
    eliminarPago(PagoID){
    axios.post(this.BASE_URL + "/pago/eliminar",{ PagoID: PagoID, UsuarioID: this.MODIFICADOR}, { headers: { 'Authorization': this.TOKEN } })
    .then((response) => {  
      this.obtenerPagos();  
    });
  },
  checkValidar(id,check){
    if (check) {
        this.arrayValidar.ArrayPagoID.push(id);
    }else{
       this.arrayValidar.ArrayPagoID =  this.arrayValidar.ArrayPagoID.filter(ele => ele != id)
    }
    if (this.arrayValidar.ArrayPagoID.length>=1) {
        this.btnValidar= true;
        this.arrayValidar.UsuarioID = this.MODIFICADOR
    }else{
        this.btnValidar = false;
    }

  },
   ValidarCobranza(){
    axios.post(this.BASE_URL + "/pago/insertar/conformidad",this.arrayValidar, { headers: { 'Authorization': this.TOKEN } })
    .then((response) => {  
        this.arrayValidar.ArrayPagoID = []
        this.btnValidar = false;
      this.obtenerPagos();  
    });
  },
  alertaCheck(){
     Swal.fire({
        text: "Estás seguro de que deseas Confirmar el pago? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Confirmar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
        this.ValidarCobranza();
          Swal.fire({
            text: "Se Confirmó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          })
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se confirmó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          })
        }
      });
  },
  alertaEliminar(PagoID){
        Swal.fire({
        text: "Estás seguro de que deseas Eliminar? ",
        icon: "error",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-danger",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.eliminarPago(PagoID);
          Swal.fire({
            text: "Se Eliminó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
               this.obtenerPagos(); 
             }
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se eliminó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
      },
  accionsaldo(lstpersonal, modo) {
     this.lista_objeto = null;
    this.TipoModal = modo;
    this.lista_objeto=lstpersonal;
    this.showModal = true;
    $('#kt_modal_pago').modal('show')
  },

},
})
</script>