<template>
<div v-if="showcontenedor"  class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <Menu></Menu>

      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <Header></Header>
        	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
						<!--begin::Container-->
						<div id="kt_content_container" class="container-xxl">
							<!--begin::Products-->
							<div class="card card-flush">
								<!--begin::Card header-->
								<div class="card-header align-items-center py-5 gap-2 gap-md-5">
									<!--begin::Card title-->
									<div class="card-title">
										<!--begin::Search-->
										<div class="d-flex align-items-center position-relative mt-9">
											<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
											<span class="svg-icon svg-icon-1 position-absolute ms-4">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
													<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
												</svg>
											</span>
											<!--end::Svg Icon-->
											<input type="text" data-kt-ecommerce-order-filter="search" class="form-control form-control-solid w-250px ps-14" placeholder="Buscar por cliente" v-model="Buscar"/>
										</div>

										<!--end::Search-->
										<!--begin::Export buttons-->
										<div id="kt_ecommerce_report_sales_export" class="d-none"></div>
										<!--end::Export buttons-->
									</div>
									<!--end::Card title-->
									<!--begin::Card toolbar-->
									<div class="card-toolbar flex-row-fluid justify-content-end gap-5">
												<div class="align-items-center position-relative  text-start  w-250px">
										<label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
										
										<select class="form-select form-select-solid"
										data-dropdown-parent="#kt_content"
										data-placeholder="Seleccione Sucursal" multiple="multiple" 
											data-close-on-select="false"
										name="sucursal[]" id="sucursal" v-model="sucursal" disabled>
										<option></option>
										<option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
											{{ lstsucursal.Nombre }}
										</option>
										</select>
									</div>
										<!--begin::Daterangepicker-->
									<div class="fv-row text-start" >
                                        <label class="form-label">Fecha:</label>
										<label id="fechaini" style="display:none" ></label>
										<label id="fechafini" style="display:none"></label>
										 <input class="form-control form-control-solid" placeholder="Pick date rage" id="fecharango" name="fecharango"/>
                               
                                    </div>
								  <button type="button" class="btn btn-primary mt-8"  @click="obtenerStock()">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
							<span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
								<!--end::Svg Icon-->Filtrar
							</button>
										<!--begin::Export dropdown-->
								  <button type="button" class="btn btn-primary mt-8"  @click="AbrirModal('', 'mAgregar',''),obtenerPermiso()">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
								<span class="svg-icon svg-icon-2">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
									<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
									<rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
								</svg>
								</span>
								<!--end::Svg Icon-->Agregar Stock
							</button>
								
										<!--end::Export dropdown-->
							<div class="modal fade" id="kt_modal_stock" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                  				<div class="modal-dialog modal-dialog-centered mw-750px">
									   <Stockmodal
									    v-if="showModal"
                                        :lista_stock="this.lista_stock"
										:TipoModal="this.TipoModal"
										:ProveedorID="this.ProveedorID"
										:SucursalID="this.SucursalID"
										:StockID="this.StockID"
										:CantidadJabas="this.CantidadJabas"
										:CantidadPollo="this.CantidadPollo"
										:PesoBruto="this.PesoBruto"
										:PesoTara="this.PesoTara"
										:PesoNeto="this.PesoNeto"
										:Promedio="this.Promedio"
										:Precio="this.Precio"
										:Importe="this.Importe"
										:Fecha="this.Fecha"
										></Stockmodal>
								</div>
							</div>
									</div>
									<!--end::Card toolbar-->
								</div>
								<!--end::Card header-->
								<!--begin::Card body-->
								<div class="card-body pt-0">
									<div class="table-responsive">
									<!--begin::Table-->
									<table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_report_sales_table">
										<!--begin::Table head-->
										<thead>
											<!--begin::Table row-->
											<tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
												<th class="min-w-100px">Proveedor</th>
												<th class="min-w-100px">Sucursal</th>
												<th class="text-center min-w-75px">Fecha</th>
												<th class="text-center min-w-75px"># Jabas Entrada</th>
												<th class="text-center min-w-75px"># Pollo Entrada</th>
												<th class="text-center min-w-75px">Peso Bruto Entrada</th>
												<th class="text-center min-w-100px">Peso Tara Entrada</th>
                                                <th class="text-center min-w-100px">Peso Neto Entrada</th>
                                                <th class="text-center min-w-100px">Precio Entrada</th>
                                                <th class="text-center min-w-100px">Importe</th>
                                                <th class="text-center min-w-100px">Promedio Entrada</th>
												<th class="text-center min-w-100px">Acciones</th>
											</tr>
											<!--end::Table row-->
										</thead>
										<!--end::Table head-->
										<!--begin::Table body-->
										<tbody class="fw-semibold text-gray-600" v-for="(lststock, index) in buscarStock" :key="index">
											<!--begin::Table row-->
											<tr>
											<td class="d-flex align-items-center" style="border: none;">
												<div class="d-flex flex-column ps-2">
												{{lststock.NombreProveedor}}
												</div>
											</td>
												<td><div class="badge badge-light-success fw-bold text-center">{{ lststock.NombreSucursal }}</div></td>
												<!--end::Date=-->
												<!--begin::No Orders=-->
												<td class="text-center pe-0">{{getDateFormat(lststock.Fecha)}}</td>
												<td class="text-center pe-0">{{lststock.CantidadJabasEntrada}}</td>
                                                <td class="text-center pe-0">{{lststock.CantidadPolloEntrada}}</td>
                                                <td class="text-center pe-0">{{lststock.PesoBrutoEntrada}}</td>
                                                <td class="text-center pe-0">{{lststock.PesoTaraEntrada}}</td>
                                                <td class="text-center pe-0">{{lststock.PesoNetoEntrada}}</td>
												<td class="text-center pe-0">{{lststock.PrecioEntrada}}</td>
												<td class="text-center">{{lststock.ImporteEntrada}}</td>
                                                <td class="text-center">{{lststock.PromedioEntrada}}</td>
												<!--end::Total=-->
												 <td class="text-end">
													<div class="d-flex justify-content-center flex-shrink-0">
													<button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 " @click="AbrirModal(lststock, 'mEditar','')">
													<i class="fa-sharp fa-solid fa-download"></i>
													</button>
													<button v-if="lststock.Salida == 1" type="button" class="btn btn-info btn-hover-scale btn-sm btn-icon me-1 " @click="AbrirModal(lststock, 'mEditarSalida','')">
													<i class="fa-sharp fa-solid fa-upload"></i>
													</button>
													<!-- <button type="button" @click="AlertEliminarStock(lststock.StockID)" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" >
													<i class="fas fa-trash"></i>
													</button > -->
												</div>
												</td>
											</tr>
										</tbody>
										<!--end::Table body-->
									</table>
									</div>
								</div>
							</div>
						</div>
						<!--end::Container-->
					</div>
      </div>
    </div>
</div>
</template>
<script lang="js">
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
 import Stockmodal from '@/components/Stockmodal.vue'
import axios from 'axios'
export default defineComponent( {
  name: 'Stock',
  components: {
    Menu,
    Header,
    Stockmodal
  },
  data() {
    return {
         BASE_URL: process.env.VUE_APP_BASE_URL,
         TipoModal:"",
         TOKEN:'',
         MODIFICADOR:'',
         Buscar:'',
         UsuarioID:"",
        showModal: false,
        showcontenedor:false,
        sucursal:[],
		RolUser:"",
		listado_sucursal:[],
		listado_stock:[],
		CodigoMovimiento:"",
		StockID:"",
		ProveedorID:"",
		SucursalID:"",
		CantidadJabas:"",
		CantidadPollo:"",
		PesoBruto:"",
		PesoTara:"",
		PesoNeto:"",
		Promedio:"",
		Precio:"",
		Importe:"",
		DevolucionID:null,
	   	fechastart:"",
		fechaend:"",
		año:'',
		dia:'',
		mes:'',
		TipoPolloID:'',
		Fecha:'',
        lista_stock:{},
    }
  },
 mounted() {

    this.obtenerPermiso();
	this.obtenerSucursal();
  },
 created() {

	 this.validacionStorage();
 },
 
computed:{
       buscarStock() {
     return this.listado_stock.filter(item => {
                return item.NombreProveedor.toLowerCase().includes(this.Buscar.toLowerCase());
                 });   
       }
    },

methods: {
    obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
    },

           	
	fechafiltro(){
		this.fechastart= document.getElementById('fechaini').innerHTML;
		this.fechaend = document.getElementById('fechafini').innerHTML;
	},
		fechaoriginal(){
			var start = moment().subtract(7, 'days');
			var end = moment();
				
			function cb(start, end) {
				 $('#fechaini').html(start.format('YYYY-MM-DD'));
				  $('#fechafini').html(end.format('YYYY-MM-DD'));
			}
			$('#fecharango').daterangepicker({
				startDate: start,
				endDate: end,
			
			}, cb)

			cb(start, end);
				this.fechastart= document.getElementById('fechaini').innerHTML;
				this.fechaend = document.getElementById('fechafini').innerHTML;
		},
	validacionStorage(){
                this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
	
              },

		   getDateFormat(fecha){
			   return moment(fecha).format("YYYY-MM-DD ");
		   },
		   obtenerStock(){
			this.fechafiltro();
			var sucursalString = this.sucursal.toString();
      		axios.post(this.BASE_URL+"/stock/obtener",{SucursalID: sucursalString, FechaInicial: this.fechastart, FechaFinal: this.fechaend},{headers:{'Authorization': this.TOKEN}})
        	.then((response) => {
			this.listado_stock=[];
          	this.listado_stock = response.data.respuesta;
		});
		   },
	    obtenerSucursal() {
			this.sucursal.push(JSON.parse(localStorage.getItem('sucursal-ID')));
      		axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        	.then((response) => {
         	 this.listado_sucursal = response.data.respuesta;
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
			this.fechaoriginal();
			this.obtenerStock();
				if (this.RolUser ==="Administrador") {
					$('#sucursal').removeAttr('disabled');
				}
			
          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
				multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
	AlertEliminarStock(valorID){
		Swal.fire({
        text: "Estás seguro de que deseas Eliminar? ",
        icon: "error",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-danger",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
			this.EliminarStock(valorID);
          Swal.fire({
            text: "Se Eliminó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
            
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se eliminó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {

          });
        }
      });
	},
	   EliminarStock(ValorID){
        axios.post(this.BASE_URL+"/stock/eliminar", {StockID: ValorID,UsuarioID: this.MODIFICADOR
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
			this.obtenerStock();
        });

      },

	   AbrirModal(lista_stock,mTipo){
		var fecha_hoy = moment().format("YYYY-MM-DD ")
		var fecha_stock= moment(lista_stock.Fecha).format("YYYY-MM-DD ")
		var RolUser = JSON.parse(localStorage.getItem('RolNombre'));
	 	this.TipoModal = mTipo;
		// this.SucursalID = SucursalID;
		// this.ProveedorID= ProveedorID;
		// this.StockID = StockID;
		// this.CantidadJabas=CantidadJabas;
		// this.CantidadPollo=CantidadPollo;
		this.lista_stock=lista_stock;
		// this.PesoTara=PesoTara;
		// this.PesoNeto=PesoNeto;
		// this.Promedio=Promedio;
		// this.Precio=Precio;
		// this.Fecha=Fecha;
		// this.Importe=Importe;
		if (mTipo==="mEditar" && fecha_hoy== fecha_stock || RolUser==="Administrador") {
		this.showModal=true
         $('#kt_modal_stock').modal('show')
		}else{
			 Swal.fire({
            text: "No puede modificar, consulte con su Administrador",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
		}

		if(mTipo==="mAgregar"){
        this.showModal=true
         $('#kt_modal_stock').modal('show')
		}
      // this.Sucursal = SucursalID

       },

}
})
	
</script>