<template>
  <div v-if="showcontenedor" class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <Menu></Menu>
        <div class="wrapper d-flex flex-column flex-row-fluid" >
        <Header></Header>
         <div  class="container-xxl" id="kt_content">
          <!--begin::Card-->
          <div class="card">
            <!--begin::Card header-->
            <div class="card-header border-0 pt-6">
              <!--begin::Card title-->
              <div class="card-title">
                <!--begin::Search-->
                <div class="d-flex align-items-center position-relative my-1">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span class="svg-icon svg-icon-1 position-absolute ms-6 mt-8">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"/>
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input
                    type="text"
                    class="form-control form-control-solid w-250px ps-14 mt-8"
                    placeholder="Buscar Proveedor"
                    v-model="Buscar"/>
                </div>
                <!--end::Search-->
              </div>
              <!--begin::Card title-->
              <!--begin::Card toolbar-->
              <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                <!--begin::Toolbar-->
              <div class="align-items-center position-relative  text-start  w-250px">
										<label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
										<select class="form-select form-select-solid" data-dropdown-parent="#kt_content"
										data-placeholder="Seleccione Sucursal" multiple="multiple" data-close-on-select="false"
										name="sucursal[]" id="sucursal" v-model="sucursal" disabled>
										<option></option>
										<option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
											{{ lstsucursal.Nombre }}
										</option>
										</select>
							</div>

						<button type="button" class="btn btn-primary mt-8"  @click="obtenerProveedor()">
							<span class="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                  </svg>
              </span>
				      Filtrar
							</button>
                <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                  <!--begin::Add user-->
                  <button type="button" class="btn btn-primary mt-8" @click="AbrirModal('', 'mAgregar'),obtenerPermiso()">
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
                    <span class="svg-icon svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                      </svg>
                    </span>
                    <!--end::Svg Icon-->Agregar Proveedor
                  </button>
                  <!--end::Add user-->
                </div>
                <!--begin::Modal - Add task-->
                <div class="modal fade" id="kt_modal_proveedor" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                  <!--begin::Modal dialog-->
                  <div class="modal-dialog modal-dialog-centered mw-650px">
                    <Proveedormodal
                      v-if="showModal"
                      :ProveedorID="this.ProveedorID"
                      :TipoDocumentoID="this.TipoDocumentoID"
                      :TipoModal="this.TipoModal"
                      :SucursalID="this.SucursalID"
                      :Nombre="this.Nombre"
                      :NumeroDocumento="this.NumeroDocumento"
                      :Telefono="this.Telefono"
                      :Direccion="this.Direccion"
                      :Correo="this.Correo"
                    ></Proveedormodal>
                  </div>
                </div>
                <!--end::Modal - Add task-->
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body py-4">
              <!--begin::Table-->
              <div class="table-responsive">
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                  <!--begin::Table head-->
                  <thead>
                    <!--begin::Table row-->
                    <tr class=" text-center text-muted fw-bold fs-7 text-uppercase gs-0">
                      <th class="min-w-130px">Proveedor</th>
                      <th class="min-w-115px">Documento</th>
                      <th class="min-w-115px">Dirección</th>
                      <th class="min-w-115px">Teléfono</th>
                      <th class="min-w-115px">Sucursal</th>
                      <th class="text-center min-w-100px">Acciones</th>
                    </tr>
                    <!--end::Table row-->
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody class="text-gray-600 fw-semibold">
                    <!--begin::Table row-->
                    <tr v-for="(lstproveedor, index) in buscarProveedor" :key="index">
                      <!--begin::User=-->
                      <td class="d-flex align-items-center">
                        <!--begin:: Avatar -->
                        <div class=" symbol symbol-circle symbol-50px overflow-hidden me-3">
                          <a >
                            <div class="symbol-label">
                              <img  src="resources/assets/media/avatars/cliente.jpg" class="w-100"/>
                            </div>
                          </a>
                        </div>
                        <!--end::Avatar-->
                        <!--begin::User details-->
                        <div class="d-flex flex-column">
                          <a class="text-gray-800 text-hover-primary mb-1">{{}}{{ lstproveedor.Nombre }}</a>
                         <span>{{lstproveedor.Correo}}</span> 
                        </div>
                        <!--begin::User details-->
                      </td>
                      <!--end::User=-->

                      <!--begin::Role=-->
                      <td class="text-center">{{lstproveedor.NombreTipoDocumento}} <br> {{ lstproveedor.NumeroDocumento }}</td>

                      <!--end::Role=-->
                      <td class="text-center">{{ lstproveedor.Direccion }}</td>
                      <!--begin::Last login=-->
                 
                     
                      <!--begin::Joined-->
                      <td class="text-center">{{ lstproveedor.Telefono }}</td>
                      <!--begin::Joined-->
                     <td><div class="badge badge-light-success fw-bold text-center">{{ lstproveedor.NombreSucursal }}</div></td>
                
                      <!--begin::Action=-->
                      <td class="text-end">
                              <div class="d-flex justify-content-center flex-shrink-0">
                         <button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 " @click="AbrirModal(lstproveedor, 'mEditar')">
                         <i class="fas fa-edit"></i>
                        </button>
                        <button type="button" @click="AlertEliminarProveedor(lstproveedor)" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" >
                         <i class="fas fa-trash"></i>
                        </button >
                      </div>
            
                      </td>

                      <!--end::Action=-->
                    </tr>
                    <!--end::Table row-->
                  </tbody>
                  <!--end::Table body-->
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>

        </div>
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
import Proveedormodal from '@/components/Proveedormodal.vue'
import axios from 'axios'

export default defineComponent( {
  name: 'Proveedor',
  components: {
    Menu,
    Header,
    Proveedormodal
  },
data() {return{
      BASE_URL: process.env.VUE_APP_BASE_URL,
    Buscar:'',
   // listado_proveedor:[{SucursalID:1,RUC:"20601234567",NombreSucursal:"Avicola 1",RazonSocial:"Proveedor de pollos SAC",Telefono:"932735295",Direccion:"pardo",Correo:"proveedor@pro.pe"}],
      listado_proveedor:[],
      TipoModal:"",
        showModal: false,
      SucursalID:"",
        Nombre:"",
        TipoDocumentoID:"",
        NumeroDocumento:"",
        Telefono:"",
        Direccion:"",
        Correo:"",
        ProveedorID:"",
        RUC:"",
        TOKEN:'',
        MODIFICADOR:'',
        showcontenedor:false,
        sucursal:[],
        listado_sucursal:[],
        
        }
    },
   computed: {
         buscarProveedor() {
     return this.listado_proveedor.filter(item => {
                return item.Nombre.toLowerCase().includes(this.Buscar.toLowerCase());
                 });   
       }
   },
   mounted() {
    this.obtenerPermiso();
    this.obtenerSucursal();
   },
   created() {
    this.validacionStorage();
   },
    methods: {
           obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
      	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
        getDateFormat(fecha) {
    return moment(fecha).format("DD/MM/YYYY hh:mm a");
  },
        obtenerProveedor: function(){
         var sucursalString = this.sucursal.toString()
         axios.post(this.BASE_URL+"/proveedor/obtener/porsucursal",{SucursalID: sucursalString},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_proveedor = response.data.respuesta;
        });
        },
  obtenerSucursal() {
			this.sucursal.push(JSON.parse(localStorage.getItem('sucursal-ID')));
      axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
 			    this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
          this.obtenerProveedor();
					if (this.RolUser ==="Administrador") {
						 $('#sucursal').removeAttr('disabled');
				}
			
          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
			        	multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
    AlertEliminarProveedor({ProveedorID}){
          Swal.fire({
        text: "Estás seguro de que deseas Eliminar? ",
        icon: "error",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-danger",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.EliminarProveedor({ProveedorID});
          Swal.fire({
            text: "Se Eliminó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
            
           window.location.href = "/proveedor"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se eliminó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
      },
    EliminarProveedor: function({ProveedorID}){
        this.ProveedorID = ProveedorID
        axios.post(this.BASE_URL+"/proveedor/eliminar", {
          ProveedorID: this.ProveedorID,
          UsuarioID: this.MODIFICADOR,
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
            window.location.href = "/proveedor"
        });

      },
     AbrirModal({ProveedorID,Nombre,TipoDocumentoID,SucursalID,RazonSocial,NumeroDocumento,Telefono,Direccion,Correo},TipoModal){
        this.ProveedorID=ProveedorID
        this.TipoModal = TipoModal
        this.SucursalID=SucursalID
         this.TipoDocumentoID=TipoDocumentoID
        this.Nombre=Nombre
        this.NumeroDocumento=NumeroDocumento
        this.Telefono=Telefono
        this.Direccion=Direccion
        this.Correo=Correo
      
        this.showModal=true
         $('#kt_modal_proveedor').modal('show')
      },
    },
})
</script>