<template>
    <div class="modal-content">
        <div class="modal-header" id="kt_modal_rol_header" style="padding-top: 3px; padding-bottom: 3px;">
            <h3 class="fw-bold">Agregar Ingreso</h3>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
                <span class="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                            transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                            fill="currentColor" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="modal-body scroll-y mx-5 mx-xl-15">
            <form id="kt_modal_edit_user_form" class="form" action="#">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_edit_user_scroll" data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_edit_user_header"
                    data-kt-scroll-wrappers="#kt_modal_edit_user_scroll" data-kt-scroll-offset="300px"
                    style="max-height: 300px !important">
                    <div class="row">
                        <div v-if="(this.Modal == this.ModalAgregar)" class="col-sm-6 mb-7 text-start">
                            <label class="form-label">Sucursal:</label>
                            <select class="form-select form-select-solid"
                                data-dropdown-parent="#kt_modal_edit_user_form" data-placeholder="Seleccione Sucursal"
                                name="sucursalm" id="sucursalm" v-model="this.Ingreso.SucursalID" >
                                <option></option>
                                <option v-for="(lstsucursal, index) in listado_sucursal" :key="index"
                                    :value="lstsucursal.SucursalID">
                                    {{ lstsucursal.Nombre }}
                                </option>
                            </select>
                        </div>
                        <div v-else class="col-sm-6 mb-7 text-start">
                            <label class="form-label">Sucursal:</label>
                            <select class="form-select form-select-solid"
                                data-dropdown-parent="#kt_modal_edit_user_form" data-placeholder="Seleccione Sucursal"
                                name="sucursalm" id="sucursalm" v-model="this.Ingreso.SucursalID" disabled>
                                <option></option>
                                <option v-for="(lstsucursal, index) in listado_sucursal" :key="index"
                                    :value="lstsucursal.SucursalID">
                                    {{ lstsucursal.Nombre }}
                                </option>
                            </select>
                        </div>
                        <div v-if="(this.Modal == this.ModalAgregar)" class="col-sm-6 fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Fecha:</label>
                            <div class="input-group">
                                <flat-pickr v-model="date" :config="config" class="form-control"
                                    placeholder="Seleccione Fecha" name="date" id="fecha" />
                            </div>
                        </div>
                        <div v-if="(this.Modal == this.ModalEditar)" class="col-sm-6 fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Fecha:</label>
                            <div class="input-group">
                                <span class="input-group-text">{{ getDateFormat(this.Ingreso.FechaRecepcion) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="(this.Modal == this.ModalAgregar)" class="fv-row mb-7 text-start">
                        <label class="form-label">Tipo Ingreso:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
                            data-placeholder="Seleccione Tipo Ingreso" name="tipoingreso" id="tipoingreso"
                            v-model="this.Ingreso.TipoIngresoID">
                            <option></option>
                            <option v-for="(lsttipoingreso, index) in listado_tipoingreso" :key="index"
                                :value="lsttipoingreso.TipoIngresoID">
                                {{ lsttipoingreso.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div v-else class="fv-row mb-7 text-start">
                        <label class="form-label">Tipo Ingreso:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
                            data-placeholder="Seleccione Tipo Ingreso" name="tipoingreso" id="tipoingreso"
                            v-model="this.Ingreso.TipoIngresoID" disabled>
                            <option></option>
                            <option v-for="(lsttipoingreso, index) in listado_tipoingreso" :key="index"
                                :value="lsttipoingreso.TipoIngresoID">
                                {{ lsttipoingreso.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="fv-row mb-7 text-start">
                        <label class="form-label">Nombre:</label>
                        <input type="text" class="form-control form-control-solid " v-model="this.Ingreso.Nombre"
                            autocomplete="off" />
                    </div>
                    <div class="fv-row mb-7 text-start">
                        <label class="form-label">Descripcion:</label>
                        <input type="text" class="form-control form-control-solid " v-model="this.Ingreso.Descripcion"
                            autocomplete="off" />
                    </div>
                    <div class="fv-row mb-7 text-start">
                        <label class="form-label">Monto:</label>
                        <div class="input-group input-group-solid mb-5">
                            <span class="input-group-text">S/.</span>
                            <input type="number" class="form-control" v-model="this.Ingreso.Monto"
                                aria-label="Amount (to the nearest dollar)" autocomplete="off" />
                        </div>
                    </div>
                </div>
                <div class="text-center pt-15">
                    <button @click="cerrarModal()" type="reset" class="btn btn-light me-3"
                        data-kt-users-modal-action="cancel">
                        Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" data-kt-users-modal-action="button"
                        @click="validacioncampos()">
                        <span class="indicator-label">Registrar</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from 'axios';
export default {
    props: [
        "Modal",
        "ListaIngreso",
    ],
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            TOKEN: '',
            MODIFICADOR: '',
            listado_sucursal: [],
            listado_tipoingreso: [],
            sucursalm: "",
            tipoingreso: "",
            descripcion: "",
            monto: "",
            TipoModal: "",
            Nombre: "",
            ModalAgregar: "mAgregar",
            ModalEditar: "mEditar",
            date: null,
            Fecha: "",
            Ingreso: {
                IngresoID: "",
                SucursalID: null,
                TipoIngresoID: null,
                Nombre: "",
                Descripcion: "",
                Monto: "",
                FechaRecepcion: "",
                UsuarioID: "",
            }
        }
    },
    components: {
        flatPickr
    },
    created() {
        this.validacionStorage();
        this.año = new Date().getFullYear();
        this.dia = new Date().getDate();
        this.mes = new Date().getMonth() + 1;
        this.date = this.año + "-" + this.mes + "-" + this.dia;
    },
    mounted() {
        this.obtenerSucursal();
        this.obtenerTipoIngreso();
        this.fechaModal();
        this.obtenerIngreso();
        this.capturarfecha();
    },
    methods: {
        capturarfecha() {
            $("#fecha").flatpickr({
                maxDate: this.date,
            })
        },
        getDateFormat(fecha) {
            return moment(fecha).format("YYYY-MM-DD ");
        },
        fechaModal() {
            //console.log("fdfgdfgfg" + this.Fecha);
            $("#fechaIngreso").daterangepicker({
                singleDatePicker: true,
                showDropdowns: true,
                minYear: 1901,
                maxYear: parseInt(moment().format("YYYY"), 10)
            }, function (start, end, label) {
                this.Ingreso.FechaRecepcion = moment(start).format("YYYY-MM-DD ")
            }
            );
        },
        obtenerIngreso() {
            //console.log("holaaa "+this.ListaIngreso);
            this.Ingreso.IngresoID = this.ListaIngreso.IngresoID;
            this.Ingreso.SucursalID = this.ListaIngreso.SucursalID;
            this.Ingreso.TipoIngresoID = this.ListaIngreso.TipoIngresoID;
            this.Ingreso.Nombre = this.ListaIngreso.Nombre;
            this.Ingreso.Descripcion = this.ListaIngreso.Descripcion;
            this.Ingreso.Monto = this.ListaIngreso.Monto;
            this.Ingreso.FechaRecepcion = this.ListaIngreso.FechaRecepcion;
            this.Ingreso.UsuarioID = this.MODIFICADOR;
        },
        validacionStorage() {
            this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
            this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
            if (this.TOKEN === null) {
                this.$router.push('/login');
            }
        },
        validacioncampos() {
            //console.log(this.Modal);
            this.TipoModal = this.Modal;
            if (this.TipoModal == this.ModalAgregar) {
                if (this.Ingreso.SucursalID != null && this.Ingreso.TipoIngresoID != null && this.Ingreso.Descripcion != ""
                    && this.Ingreso.Monto != "" && this.Ingreso.Nombre != "" && this.date != null) {
                    console.log("entrooooooo");
                    this.alerDialogRegistrar();
                } else {
                    console.log("holaaaa");
                    this.alertValidacion();
                }
            } else if (this.TipoModal == this.ModalEditar) {
                this.alerDialogModificar();
            }
        },
        alerDialogRegistrar() {
            Swal.fire({
                text: "Estás seguro de que deseas Registrar? ",
                icon: "question",
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Si, Registrar!",
                cancelButtonText: "No, Cancelar",
                customClass: {
                    confirmButton: "btn fw-bold btn-primary",
                    cancelButton: "btn fw-bold btn-active-light-primary",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.registrarIngreso();
                    Swal.fire({
                        text: "Se Registró correctamente",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then((result) => {
                        this.cerrarModal()
                        if (result.isConfirmed) {
                            window.location.href = "/ingreso"
                        }
                    });
                } else if (result.dismiss === "cancel") {
                    Swal.fire({
                        text: "No se agregó",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then(function () {
                    });
                }
            });
        },
        alerDialogModificar() {
            Swal.fire({
                text: "Estás seguro de que deseas Modificar? ",
                icon: "warning",
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Si, Modificar!",
                cancelButtonText: "No, Cancelar",
                customClass: {
                    confirmButton: "btn fw-bold btn-warning",
                    cancelButton: "btn fw-bold btn-active-light-primary",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.modificarIngreso();
                    Swal.fire({
                        text: "Se modificó correctamente",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then((result) => {
                        this.cerrarModal()
                        if (result.isConfirmed) {
                            window.location.href = "/ingreso"
                        }
                    });
                } else if (result.dismiss === "cancel") {
                    Swal.fire({
                        text: "No se modificó",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then(function () {
                    });
                }
            });
        },
        modificarIngreso() {
            axios.post(this.BASE_URL + "/ingreso/actualizar", {
                IngresoID: this.Ingreso.IngresoID,
                Nombre: this.Ingreso.Nombre,
                Descripcion: this.Ingreso.Descripcion,
                Monto: this.Ingreso.Monto,
                UsuarioID: this.MODIFICADOR,
            }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                });
        },
        registrarIngreso() {
            axios
                .post(this.BASE_URL + "/ingreso/insertar", {
                    SucursalID: this.Ingreso.SucursalID,
                    TipoIngresoID: this.Ingreso.TipoIngresoID,
                    Nombre: this.Ingreso.Nombre,
                    Descripcion: this.Ingreso.Descripcion,
                    Monto: this.Ingreso.Monto,
                    FechaRecepcion: this.date,
                    UsuarioID: this.MODIFICADOR,
                }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                });
        },
        alertValidacion() {
            Swal.fire({
                text: "Por favor llenar todos los campos",
                icon: "info",
                buttonsStyling: false,
                confirmButtonText: "Aceptar!",
                customClass: {
                    confirmButton: "btn fw-bold btn-info",
                },
            })
        },
        cerrarModal() {
            $("#kt_modal_pago").modal("hide");
            this.$parent.showModal = false;
        },
        obtenerTipoIngreso() {
            axios.get(this.BASE_URL + "/tipoingreso/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_tipoingreso = response.data.respuesta;
                    //console.log(this.listado_tipoingreso);
                    $("#tipoingreso")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                });
        },
        obtenerSucursal() {
            axios.get(this.BASE_URL + "/sucursal/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_sucursal = response.data.respuesta;
                    this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
                    //this.listado_proveedor = [];
                    if (this.RolUser == "Administrador") {
                        //$('#sucursalm').removeAttr('disabled');
                    }
                    $("#sucursalm")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                            //this.listado_proveedor = [];
                            //this.obtenerProveedor();
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                });
        },
    },
}
</script>