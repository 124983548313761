<template>
  <div v-if="showcontenedor"  class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <Menu></Menu>
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <Header></Header>
        <div  id="kt_content_container" class="container-xxl">
          <div class="card">
            <div class="card-header border-0 pt-6">
              <div class="card-title">
                <div class="d-flex align-items-center position-relative my-1">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6 mt-9">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"/>
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"/>
                    </svg>
                  </span>
                  <input type="text" class="form-control form-control-solid w-250px ps-14 mt-9" placeholder="Buscar usuario" v-model="BuscarUsuario"/> 
                </div>
              </div>
              <div class="card-toolbar flex-row-fluid  gap-5">
                  <div class="align-items-center position-relative ms-5  text-start  w-300px">
										<label class="required fw-semibold fs-6  mb-2">Sucursal:</label>
										<select class="form-select form-select-solid" data-dropdown-parent="#kt_wrapper" data-placeholder="Seleccione Sucursal" multiple="multiple"  data-close-on-select="false"
										name="sucursalP[]" id="sucursal" v-model="sucursalP" disabled>
										<option></option>
										<option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
											{{ lstsucursal.Nombre }}
										</option>
										</select>
									</div>
                <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
              	<button type="button" class="btn btn-primary mt-8 me-5"  @click="obtenerUsuario()">
									<span class="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                  </svg>
                  </span>
									Filtrar
									</button>
                  <button type="button" class="btn btn-primary mt-8" @click="AbrirModal('', 'mAgregar'),obtenerPermiso()">
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
                    <span class="svg-icon svg-icon-2 mt-8">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                      </svg>
                    </span>
                    Agregar Usuario
                  </button>
                </div>
              </div>
              <!-- Inicio modal -->
              <div class="modal fade" id="kt_modal_edit_user" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                  <div class="modal-dialog modal-dialog-centered mw-650px">
                    <Usermodal
                      v-if="showModal"
                      :Modal="this.TipoModal"
                      :idduser="idduser"
                      :UserNombre="NombrePersonal"
                      :Sucursal="Sucursal"
                      :Codigo="Codigo"
                      :Password="Password"
                      :RolID="RolID"
                      :RolNombre="RolNombre"
                    ></Usermodal>
                  </div>
                </div>
                <!-- Fin modal -->
            </div>
            <div class="card-body py-4">
              <div class="table-responsive">
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                  <thead>
                    <tr class=" text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
                      <th class="min-w-130px">Usuario</th>
                      <th class="min-w-115px">Rol</th>
                      <th class="min-w-115px">Código (DNI)</th>
                      <th class="min-w-115px">Contraseña</th>
                      <th class="min-w-115px">Fecha Creación</th>
                      <th class="min-w-90px">Sucursal</th>
                      <th class="text-center min-w-100px">Acciones</th>
                    </tr>
                  </thead>
                  <tbody class="text-gray-600 fw-semibold">
                    <tr v-for="(lstuser, index) in BuscarUser" :key="index">
                      <td class="d-flex align-items-center">
                        <div class=" symbol symbol-circle symbol-50px overflow-hidden me-3">
                          <a>
                            <div class="symbol-label">
                              <img v-if="lstuser.Genero == 'M'" src="resources/assets/media/avatars/masculino.png" alt="Emma Smith" class="w-100"/>
                              <img v-if="lstuser.Genero == 'F'" src="resources/assets/media/avatars/femenino.png" alt="Emma Smith" class="w-100"/>
                              <img v-if="lstuser.Genero == 'N'" src="resources/assets/media/avatars/indefinido.png" alt="Emma Smith" class="w-100"/>
                            </div>
                          </a>
                        </div>
                        <div class="d-flex flex-column">
                          <a class="text-gray-800 text-hover-primary mb-1">{{ lstuser.NombreCompleto }}</a>
                        </div>
                      </td>
                      <td>{{ lstuser.RolNombre }}</td>
                      <td>{{ lstuser.CodigoUsuario }}</td>
                      <td>{{ lstuser.Contraseña }}</td>
                      <td>{{getDateFormat(lstuser.Fecha)}}</td>
                      <td><div class="badge badge-light-success fw-bold">{{ lstuser.SucursalNombre }}</div></td>
                      <td class="text-end">
                        <div class="d-flex justify-content-center flex-shrink-0">
                          <button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1" @click="AbrirModal(lstuser, 'mEditar')">
                          <i class="fas fa-edit"></i></button>
                          <button type="button" @click="AlerEliminarUsuario(lstuser)" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1">
                           <i class="fas fa-trash"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="js">
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
import Usermodal from '@/components/Usermodal.vue'
import axios from 'axios'

export default defineComponent( {
  name: 'Usuario',
  components: {
    Menu,
    Header,
    Usermodal
  },

   data() {return{
      BASE_URL: process.env.VUE_APP_BASE_URL,
    // Variables que se van a enviar al modal UserModal.vue
      showModal: false,
        TipoModal:"",
        sucursalP:[],
        Sucursal:"",
        idduser:"",
        NombrePersonal:"",
        Codigo:"",
        Password:"",
        RolID:"",
        RolNombre:"", 
     // fin Variables para modal
        listado_user:[],  
        BuscarUsuario:'', 
        TOKEN:'',
        MODIFICADOR:'',
        showcontenedor:false,
        listado_sucursal:[],
        RolUser:'',
       
 
        
    }},
    mounted() {
      this.obtenerPermiso();
      this.obtenerSucursal();
    },
      created() {
      this.validacionStorage();       
  },
    computed:{
       BuscarUser() {
         return this.listado_user.filter(item => {
          return item.NombreCompleto.toLowerCase().includes(this.BuscarUsuario.toLowerCase());
          });   
       }
    },
        methods: {
           obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
          	validacionStorage(){
                this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ==  null || this.MODIFICADOR== null){
                window.location.href = "/login";
                }
              },
          getDateFormat(fecha) {
            return moment(fecha).format("DD/MM/YYYY hh:mm a");
          },
          obtenerUsuario: function () {
            var sucursalString = this.sucursalP.toString()
            axios.post(this.BASE_URL+'/usuario/obtener/porsucursal',{SucursalID: sucursalString},{headers:{'Authorization': this.TOKEN}})
                .then((response) => {
                    this.listado_user = response.data.respuesta
                });
        },
         
      AbrirModal({UsuarioID,PersonalID,NombreCompleto,Genero,SucursalID,SucursalNombre,RolID,RolNombre,CodigoUsuario,Contraseña},mEditar){
        this.TipoModal = mEditar
        this.Sucursal = SucursalID
        this.idduser = UsuarioID
        this.NombrePersonal = NombreCompleto
        this.Codigo = CodigoUsuario
        this.Password = Contraseña
        this.RolID = RolID
        this.RolNombre= RolNombre
        this.showModal=true
         $('#kt_modal_edit_user').modal('show')
      },
      EliminarUsuario: function({UsuarioID}){
        this.idduser = UsuarioID
        axios.post(this.BASE_URL+"/usuario/eliminar", {
          UsuarioID: this.idduser,
          UsuarioActualizacionID: this.MODIFICADOR,
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
            window.location.href = "/usuario"
        });

      },

      AlerEliminarUsuario({UsuarioID}){
        Swal.fire({
        text: "Estás seguro de que deseas Eliminar? ",
        icon: "error",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-danger",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.EliminarUsuario({UsuarioID});
          Swal.fire({
            text: "Se Eliminó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
            
           window.location.href = "/usuario"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se eliminó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
      },
       obtenerSucursal() {
			this.sucursalP.push(JSON.parse(localStorage.getItem('sucursal-ID')));
      axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
          this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));

					if (this.RolUser ==="Administrador") {
						 $('#sucursal').removeAttr('disabled');
				}
        this.obtenerUsuario();
          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
			        	multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
 }
})
</script>