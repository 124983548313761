<template>
     <div class="modal-content">
    <!--begin::Modal header-->
    <div class="modal-header" id="kt_modal_edit_user_header">
      <!--begin::Modal title-->
      <h2 v-if="dTipoModal == ModalEditar" class="fw-bold">Modificar Proveedor</h2>
      <h2 v-if="dTipoModal == ModalAgregar" class="fw-bold">Agregar Proveedor</h2>
      <!--end::Modal title-->
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Close-->
    </div>
    <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
      <!--begin::Form-->
      <form id="kt_modal_edit_user_form" class="form" action="#">
        <!--begin::Scroll-->
        <div
          class="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_edit_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_edit_user_header"
          data-kt-scroll-wrappers="#kt_modal_edit_user_scroll"
          data-kt-scroll-offset="300px"
          style="max-height: 300px !important"
        >
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
          <select class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione Sucursal"
              name="sucursalcbo"
              id="sucursalcbo"
              v-model="this.Proveedor.SucursalID"
            >
              <option></option>
              <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                {{ lstsucursal.Nombre }}
              </option>
            </select> 
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Tipo de Documento:</label>
          <select
              class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione Tipo Documento"
              name="documento"
              id="documento"
              v-model=" this.Proveedor.TipoDocumentoID"
            >
              <option></option>
              <option v-for="(lstdocumento, index) in listado_tipo_documento" :key="index" :value="lstdocumento.TipoDocumentoID">
                {{ lstdocumento.Nombre }}
              </option>
            </select> 
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="required fw-semibold fs-6 mb-2">Numero Documento:</label>
              <input   type="text" class="form-control form-control-solid " v-model="this.Proveedor.NumeroDocumento"  />
          </div>
            <div class="fv-row mb-7 text-start">
            <label v-if="this.Proveedor.TipoDocumentoID==''" class="required fw-semibold fs-6 mb-2">Nombre o Razón Social:</label>
            <label v-if="this.Proveedor.TipoDocumentoID=='1'|| this.Proveedor.TipoDocumentoID=='2' || this.Proveedor.TipoDocumentoID=='3'" class="required fw-semibold fs-6 mb-2">Nombres:</label>
             <label v-if="this.Proveedor.TipoDocumentoID=='4'" class="required fw-semibold fs-6 mb-2">Razón social:</label>
            <input type="text" class="form-control form-control-solid" v-model="this.Proveedor.Nombre" />
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="fw-semibold fs-6 mb-2">Dirección:</label>
            <input type="text" class="form-control form-control-solid" v-model="this.Proveedor.Direccion"/>
          </div>
          <div class="fv-row mb-7 text-start">
            <label class="fs-6 fw-semibold form-label mt-3">
            <span>Telefono:</span>
            </label>
            <input type="text" class="form-control form-control-solid" v-model="this.Proveedor.Telefono"/>
          </div>
            <div class="fv-row mb-7 text-start">
            <label class="fs-6 fw-semibold form-label mt-3">
              <span>Correo:</span>
            </label>
            <input type="text" class="form-control form-control-solid" v-model="this.Proveedor.Correo"/>
          </div>
       
        </div>
        <div class="text-center pt-15">
          <button @click="cerrarModal()" type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">
            Cancelar
          </button>
          <button v-if="dTipoModal == ModalEditar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Modificar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button v-if="dTipoModal == ModalAgregar" type="button" class="btn btn-primary" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Registrar</span>
            <span class="indicator-progress" >Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Modal body-->
  </div>
</template>
<script>
    import { defineComponent } from "vue";
    import axios from "axios";
    //import Usuario from '@/views/Usuario.vue'
    export default defineComponent({
    name: "Proveedormodal",
    props: [
        "ProveedorID",
        "TipoModal",
        "TipoDocumentoID",
        "SucursalID",
        "Nombre",
        "NumeroDocumento",
        "Telefono",
        "Direccion",
        "Correo",
        ],
        data() {
            return {
        BASE_URL: process.env.VUE_APP_BASE_URL,
        dTipoModal: "",
        ModalAgregar: "mAgregar",
        ModalEditar: "mEditar",
        listado_sucursal:[],
        listado_tipo_documento:[],

        TOKEN:'',
        MODIFICADOR:'',
        Proveedor: {
          ProveedorID: null,
          SucursalID: null,
          TipoDocumentoID: null,
          NumeroDocumento: null,
          Nombre: null,
          Telefono: null,
          Direccion: null,
          Correo: null,
          UsuarioID: null,
          },
            }
        },
        mounted() {
          this.validacionStorage();
            this.obtenerSucursal();
            this.obtenerTipoDocumento();
            this.asignacionVariable();
        },
        methods: {
          validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
        validacionCampos(){
        if (this.TipoModal == this.ModalAgregar) {
            if(this.Proveedor.SucursalID == ""||this.Proveedor.NumeroDocumento == ""||this.Proveedor.Nombre == "" || this.Proveedor.UsuarioID==""){
            this.alertValidacion();
            }else{
            this.alerDialogRegistrar();
             }
        }else if(this.TipoModal == this.ModalEditar){
            if(this.Proveedor.SucursalID == ""||this.Proveedor.NumeroDocumento == ""||this.Proveedor.Nombre == "" || this.Proveedor.UsuarioID==""){
               this.alertValidacion();
             }else{
            this.alerDialogModificar();
        }
        }
     
    },
     alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
    alerDialogRegistrar() {
      Swal.fire({
        text: "Estás seguro de que deseas Registrar? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Registrar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.registrarProveedor();
          Swal.fire({
            text: "Se Registró correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
          
             if (result.isConfirmed) {
              this.cerrarModal()
            // window.location.href = "/proveedor"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
            // not Remove current row

          });
        }
      });
    },
     registrarProveedor: function () {
      axios
        .post(this.BASE_URL+"/proveedor/insertar", this.Proveedor,{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.cerrarModal();
        });
    },
     obtenerTipoDocumento(){
      axios.get(this.BASE_URL+'/tipodocumento/listar',{headers:{'Authorization': this.TOKEN}})
                .then((response) => {
                    this.listado_tipo_documento = response.data.respuesta
            $("#documento")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
            // 
                });
    },
    obtenerSucursal(){
        axios
        .get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
          $("#sucursalcbo")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
        
        });
    },
    asignacionVariable(){
        this.dTipoModal =this.TipoModal;
        this.Proveedor.UsuarioID= this.MODIFICADOR;
        if(this.TipoModal==this.ModalEditar){
                  this.Proveedor.ProveedorID= this.ProveedorID;
                  this.Proveedor.SucursalID= this.SucursalID;
                  this.Proveedor.TipoDocumentoID= this.TipoDocumentoID;
                  this.Proveedor.NumeroDocumento= this.NumeroDocumento;
                  this.Proveedor.Nombre= this.Nombre;
                  this.Proveedor.Telefono= this.Telefono;
                  this.Proveedor.Direccion= this.Direccion;
                  this.Proveedor.Correo= this.Correo;
                  this.Proveedor.UsuarioID= this.MODIFICADOR;
           console.log(this.Proveedor);
            $('#sucursalcbo').prop('disabled', true);

        }

    }, 
     alerDialogModificar() {    
      Swal.fire({
        text: "Estás seguro de que deseas Modificar? ",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Modificar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-warning",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.modificarProveedor();
          Swal.fire({
            text: "Se modificó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
              this.cerrarModal()
          //  window.location.href = "/proveedor"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
            // not Remove current row
          });
        }
      });
    },
     modificarProveedor: function () {
      axios.post(this.BASE_URL+"/proveedor/actualizar", this.Proveedor,{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
        });
    },
   
    cerrarModal() {
        $("#kt_modal_proveedor").modal("hide");
        this.$parent.showModal = false;
        this.$parent.obtenerPermiso();
        this.$parent.obtenerProveedor();
        },
        },




    })




</script>