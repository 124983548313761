<template>
    <div class="modal-content">
    <div class="modal-header" style="height:60px" id="kt_modal_edit_user_header">
      <h2 v-if="dTipoModal == ModalEditarEntrada" class="fw-bold">Modificar Entrada de Stock</h2>
      <h2 v-if="dTipoModal == ModalEditarSalida" class="fw-bold">Modificar Salida de Stock</h2>
      <h2 v-if="dTipoModal == ModalAgregar" class="fw-bold">Agregar Stock</h2>
      <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
          </svg>
        </span>
      </div>
    </div>
    <div class="modal-body scroll-y mx-5 mx-xl-5 my-0">
      <form id="kt_modal_edit_user_form" class="form" action="#">
        <div class="d-flex flex-column scroll-y me-n0 pe-3"
          id="kt_modal_edit_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_edit_user_header"
          data-kt-scroll-wrappers="#kt_modal_edit_user_scroll"
          data-kt-scroll-offset="450px"
          style="max-height: 450px !important">
        <div class="">
                  <div class="d-flex  flex-column-fluid">
                            	<div class=" align-items-center position-relative  text-start pe-5 ">
                                <label class="fw-semibold fs-6 mb-2">Sucursal:</label>
                                <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_header" data-placeholder="Seleccione Sucursal" name="sucursalm" id="sucursalm" v-model="sucursal" disabled>
                                        <option></option>
                                        <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                                            {{ lstsucursal.Nombre }}
                                        </option>
                                        </select>
                                </div>
                                <div class="align-items-center position-relative  text-start px-5 min-w-50px">
                                <label class="fw-semibold fs-6 mb-2">Proveedor:</label>
                                  <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_header" data-placeholder="Seleccione Proveedor" name="proveedor" id="proveedorm" v-model="proveedor" >
                                    <option></option>
                                    <option v-for="(lstproveedor, index) in listado_proveedor" :key="index" :value="lstproveedor.ProveedorID">
                                        {{ lstproveedor.Nombre }}
                                    </option>
                                </select>
                             </div>
                             <div class="mb-0 text-start ps-5">
                                <label class="form-label ">Fecha:</label>
                                 <div class="input-group">
                                    <flat-pickr v-model="fechamodal" :value="fechamodalvalue"  class="form-control form-control-solid"
                                        placeholder="Seleccione Fecha" name="fechaid" id="fechaid" />
                                        
                                </div>
                            </div>
                           </div>

        </div>
        <div>
            <div class="d-flex flex-column-fluid mt-5 text-start">
                <div class="mb-0 ms-0px ">
                <label class="required form-label"># Jabas</label>
               <input type="text" id="tjaba" :disabled="listado_guia.length>0" v-model="vjaba" placeholder="# Jabas" class="form-control h-35px" >
            </div>
             <div class="mb-0 ms-5">
                <label class="required form-label"># Pollos</label>
              <input type="text" id="tpollo" :disabled="listado_guia.length>0 || vjaba=='' " v-model="vpollo" @keyup="calcularpromedio" placeholder="# Pollos" class="form-control h-35px" >
            </div>
             <div class="mb-0 ms-5">
                <label class="required form-label">Peso Bruto</label>
               <input type="text" id="tbruto" :disabled="listado_guia.length>0 || vpollo==''" v-model="vbruto" @keyup="KeyUpBruto" placeholder="Peso Bruto" class="form-control h-35px" >
            </div>
             <div class="mb-0 ms-5">
                <label class="required form-label">Peso Tara</label>
               <input type="text" id="ttara" :disabled="listado_guia.length>0 || vbruto==''" v-model="vtara" @keyup="KeyUpTara" placeholder="Peso Tara" class="form-control h-35px" >
            </div>
            </div>
             <div class="d-flex flex-column-fluid mt-5 text-start">
                <div class="ms-0">
                    <label class="required form-label">Peso Neto</label>
                    <input type="text" id="tneto" placeholder="Peso Neto" v-model="vneto" class="form-control h-35px" disabled >
                </div>
                 <div class="ms-5">
                    <label class="required form-label">Precio</label>
                    <input type="text" id="tprecio" v-model="vprecio" @keyup="KeyUpPrecio" placeholder="Precio" class="form-control h-35px" >
                </div>
                <div class="ms-5">
                    <label class="required form-label">Importe</label>
                    <input type="text" id="timporte" placeholder="Importe" v-model="vimporte" class="form-control h-35px " disabled>
                </div>
                <div class="ms-5">
                    <label class="required form-label">Promedio</label>
                    <input type="text" id="tpromedio" placeholder="Promedio" v-model="vpromedio" class="form-control h-35px" disabled>
                </div>
                
                
                
            </div>
        </div>

  </div>
        <div class="text-center pt-5">
          <button @click="cerrarModal()" type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">
            Cancelar
          </button>
          <button v-if="dTipoModal != ModalAgregar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="alertDialogMultiple()">
            <span class="indicator-label">Modificar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button v-if="dTipoModal == ModalAgregar" type="button" class="btn btn-primary" data-kt-users-modal-action="button" @click="alertDialogMultiple()">
            <span class="indicator-label">Registrar</span>
            <span class="indicator-progress" >Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from 'vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from 'axios'
export default defineComponent( {
  name: 'Compramodal',
  components: {
    flatPickr,
  },
     props: [
    "TipoModal",
    "lista_stock",
    ],

  data() {
    return {
         BASE_URL: process.env.VUE_APP_BASE_URL,
         TOKEN:'',
         MODIFICADOR:'',
         Buscar:'',
         dTipoModal:"",
         sucursal:"",
        pollo:"",
         proveedor:"",
        listado_sucursal:[],
        listado_proveedor:[],
        RolUser:"",
        ModalAgregar: "mAgregar",
        ModalEditar: "mEditar",
        ModalEditarEntrada:  "mEditar",
        ModalEditarSalida: "mEditarSalida",
        ModalDevolucion:"mDevolucion",
        dCodigoMovimiento:"",
        dCodigoDevolucion:"",
        dDevolucionID:null,
        showcontenedor:false,
        modidev:false,
        guia_final:[],
        precio:0,
        tara:0,
        fechamodal:null,
        fechamodalvalue:null,
        aprobacion:false,
        año:'',
        mes:'',
        dia:'',
        devolucion:[],
        vjaba:'',
        vpollo:'',
        vbruto:'',
        vtara:'',
        vneto:'',
        vprecio:'',
        vimporte:'',
        vpromedio:'',
        
        // listado_guia: [{ id: 0, CantidadJabas: 0 , CantidadPollo:0,PesoBruto:0,PesoTara:0}],
         listado_guia: [],
         listado_imagen:[],
          url_imagen: "",
           url_original:"",
          tipo_imagen:"",
        imgTemporal: false,
        listado_imagenfinal:[],
        listado_pollo:[],
        confirmarGuiaCompra: false,
       
    }
  },
  mounted() {
    this.obtenerPermiso();
    this.asignacionVariable();
    this.obtenerSucursal();
   this.obtenerProveedor();
    this.fechaCompra();
  
  },
 created() {
this.validacionStorage();
    this.año = new Date().getFullYear();
	this.dia = new Date().getDate();
	this.mes = new Date().getMonth() + 1;
	this.fechamodal = this.año + "-" + this.mes + "-" + this.dia;
  
 },
 

methods: {
      	validacionStorage(){
              // this.storage_sucursal=JSON.parse(localStorage.getItem('sucursal-ID'));
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
               obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
    asignacionVariable(){
      
                this.dTipoModal =this.TipoModal;
                console.log(this.dTipoModal);
                   if(this.TipoModal==this.ModalEditar){
                      var fecha_hoy = moment().format("YYYY-MM-DD ")
                      var fecha_compra= moment(this.Fecha).format("YYYY-MM-DD ")
                      var RolUser = JSON.parse(localStorage.getItem('RolNombre'));
                    console.log(this.lista_stock);

                    this.sucursal = this.lista_stock.SucursalID;
                    this.proveedor=this.lista_stock.ProveedorID;
                    this.fechamodalvalue=moment(this.lista_stock.Fecha).format("YYYY-MM-DD")
                    this.vjaba=this.lista_stock.CantidadJabasEntrada;
                    this.vpollo=this.lista_stock.CantidadPolloEntrada;
                    this.vbruto=this.lista_stock.PesoBrutoEntrada;
                    this.vtara=this.lista_stock.PesoTaraEntrada;
                    this.vneto=this.lista_stock.PesoNetoEntrada;
                    this.vprecio=this.lista_stock.PrecioEntrada;
                    this.vimporte=this.lista_stock.ImporteEntrada;
                    this.vpromedio=this.lista_stock.PromedioEntrada;
                   
                    // console.log(this.vbruto);
                   }else if(this.TipoModal=="mEditarSalida"){
                    this.sucursal = this.lista_stock.SucursalID;
                    this.proveedor=this.lista_stock.ProveedorID;
                    this.fechamodalvalue=moment(this.lista_stock.FechaSalida).format("YYYY-MM-DD")
                    this.vjaba=this.lista_stock.CantidadJabasSalida;
                    this.vpollo=this.lista_stock.CantidadPolloSalida;
                    this.vbruto=this.lista_stock.PesoBrutoSalida;
                    this.vtara=this.lista_stock.PesoTaraSalida;
                    this.vneto=this.lista_stock.PesoNetoSalida;
                    this.vprecio=this.lista_stock.PrecioSalida;
                    this.vimporte=this.lista_stock.ImporteSalida;
                    this.vpromedio=this.lista_stock.PromedioSalida;
                   
                   }
 
               },
    
                alertDialogMultiple() {
                  var text, icon, txticon, coloricon=""
                 
                  if(this.dTipoModal== "mAgregar"){
                    text="Estás seguro de que deseas Registrar?";
                    icon="question";
                    txticon="Si, Registrar!";
                    coloricon="btn fw-bold btn-primary";

                  }else if(this.dTipoModal != "mAgregar"){
                     text="Estás seguro de que deseas Modificar?";
                    icon="warning";
                    txticon="Si, Modificar!";
                    coloricon="btn fw-bold btn-warning";

                  }
      Swal.fire({
        text: text,
        icon: icon,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: txticon,
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: coloricon,
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.TipoModal==this.ModalAgregar) {
           this.registrarStock();
          }else if (this.dTipoModal== "mEditar") {
            this.modificarStockEntrada()
          }else if (this.dTipoModal== "mEditarSalida") {
            this.modificarStockSalida()
          }
             
        
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
           
          });
        }
      });
    },
  
   fechaCompra(){
            	$("#fechaid").flatpickr({
						maxDate: this.fechamodal,
					})
           },
    	    obtenerSucursal() {
		if (this.TipoModal==this.ModalAgregar) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal-ID'))
    }
      axios
        .get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
				if (this.RolUser==="Administrador") {
						 $('#sucursalm').removeAttr('disabled');
					
				}
    
          $("#sucursalm")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
                this.obtenerProveedor(sucursal);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            })  
        });
    },
     obtenerProveedor() {
      axios
        .post(this.BASE_URL+"/proveedor/obtener/porsucursal",{
          SucursalID: this.sucursal},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_proveedor = response.data.respuesta;
 		
    
    $("#proveedorm")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
   
    calculoInput(){
 var totaljabas=0, totalpollos=0,totalbruto=0,totaltara=0, totalneto=0;
            document.querySelectorAll('.tabla-guia tbody tr').forEach(function(e){
            var columnas=e.querySelectorAll("td");
             var jabas=parseFloat(columnas[0].textContent);
             var pollos=parseFloat(columnas[1].textContent);
               var bruto=parseFloat(columnas[2].textContent);
             var tara=parseFloat(columnas[3].textContent);
             totaljabas+=jabas
             totalpollos+=pollos
             totalbruto+=bruto
             totaltara+=tara
             totalneto= totalbruto-totaltara
        });
         this.vjaba=totaljabas;
         this.vpollo= totalpollos;
         this.vbruto=totalbruto.toFixed(2);
         this.vtara=totaltara.toFixed(2);
         this.vneto = totalneto.toFixed(2);
         this.vimporte= Math.round(this.vneto*this.vprecio).toFixed(2);
         this.vpromedio= (this.vneto/this.vpollo).toFixed(2);
    },
    KeyUpPrecio(){
        this.calcularImporte()
        this.calcularpromedio()
    },
      KeyUpTara(){
        this.vneto= this.vbruto-this.vtara 
        this.promedio= ( this.vneto/this.vpollo).toFixed(2)
        this.calcularImporte()
        this.calcularpromedio()
      },
       KeyUpBruto(){
       this.vneto= this.vbruto-this.vtara 
       this.promedio= ( this.vneto/this.vpollo).toFixed(2)
       this.calcularImporte()
       this.calcularpromedio()
      },
      calcularpromedio(){
        this.vpromedio= (this.vneto/this.vpollo).toFixed(2);  
       this.calcularImporte()
      },
    calcularImporte(){
        this.vimporte=Math.round(this.vneto*this.vprecio).toFixed(2);
    },
   
     registrarStock: function () {
let Stock ={
  SucursalID: this.sucursal,
  ProveedorID: this.proveedor,
  Fecha: this.fechamodal,
  CantidadJabas: this.vjaba,
  CantidadPollo: this.vpollo,
  PesoBruto: this.vbruto,
  PesoTara: this.vtara,
  PesoNeto: this.vneto,
  Promedio: this.vpromedio,
  Precio: this.vprecio,
  Importe: this.vimporte,
  UsuarioID: this.MODIFICADOR
}
      axios.post(this.BASE_URL+"/stock/insertar", Stock,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }})
        .then((response) => {
            console.log(response.data.respuesta.Valor);
            if (response.data.respuesta[0].Valor == 0) {
                this.alertaError();
            }else{
                 this.alertaExitosa();
            }
            
      
        });
     
    },
    modificarStockEntrada(){
    let Stock ={
        StockID: this.lista_stock.StockID,
        CantidadJabas: this.vjaba,
        CantidadPollo: this.vpollo,
        PesoBruto: this.vbruto,
        PesoTara: this.vtara,
        PesoNeto: this.vneto,
        Promedio: this.vpromedio,
        Precio: this.vprecio,
        Importe: this.vimporte,
        UsuarioID: this.MODIFICADOR
        }
        axios.post(this.BASE_URL+"/stock/actualizar/entrada", Stock,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
            if (response.data.respuesta[0].Valor == 1) {
                  this.alertaExitosa();
            }
        //   if (!confirmarGuiaCompra) {
        //        this.alertaExitosa();
        //   }

        });
    },
      modificarStockSalida(){
    let Stock ={
        StockID: this.lista_stock.StockID,
        CantidadJabas: this.vjaba,
        CantidadPollo: this.vpollo,
        PesoBruto: this.vbruto,
        PesoTara: this.vtara,
        PesoNeto: this.vneto,
        Promedio: this.vpromedio,
        Precio: this.vprecio,
        Importe: this.vimporte,
        UsuarioID: this.MODIFICADOR
        }
        axios.post(this.BASE_URL+"/stock/actualizar/salida", Stock,{ headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
          }})
        .then((response) => {
             if (response.data.respuesta[0].Valor == 1) {
                  this.alertaExitosa();
            }
        //   if (!confirmarGuiaCompra) {
        //        this.alertaExitosa();
        //   }

        });
    },
    
     alertaExitosa(){
       Swal.fire({
            text: "Operación Exitosa!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            if (this.TipoModal !=this.ModalDevolucion) {
            this.cerrarModal()
             if (result.isConfirmed) {
            this.cerrarModal() 
            // window.location.href = "/compra"
             }
            }
           
          });
    },
    alertaError(){
        Swal.fire({
            text: "El stock con ese proveedor y esa fecha ya existe!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            if (this.TipoModal !=this.ModalDevolucion) {
            this.cerrarModal()
             if (result.isConfirmed) {
            this.cerrarModal() 
            // window.location.href = "/compra"
             }
            }
           
          });
    },
    cerrarModal() {
      $("#kt_modal_stock").modal("hide");
      this.modidev= false;
      this.$parent.showModal = false;
      this.$parent.obtenerPermiso();
      this.$parent.obtenerStock();
      // window.location.href = "/compra"
    },
	

}
})
</script>
