<template>
    	   <div class="modal-content">
    <!--begin::Modal header-->
    <div class="modal-header" id="kt_modal_edit_user_header">
      <!--begin::Modal title-->
      <h2 v-if="dTipoModal == ModalEditar" class="fw-bold">Modificar Zona</h2>
      <h2 v-if="dTipoModal == ModalAgregar" class="fw-bold">Agregar Zona</h2>
      <!--end::Modal title-->
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Close-->
    </div>
    <!--end::Modal header-->
    <!--begin::Modal body-->
    <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
      <!--begin::Form-->
      <form id="kt_modal_edit_user_form" class="form" action="#">
        <!--begin::Scroll-->
        <div
          class="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_edit_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_edit_user_header"
          data-kt-scroll-wrappers="#kt_modal_edit_user_scroll"
          data-kt-scroll-offset="300px"
          style="max-height: 300px !important"
        >
      
  
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->

            <label class="required fw-semibold fs-6 mb-2">Nombre:</label>
            <!--end::Label-->
            <!--begin::Input-->
         <input  type="text" class="form-control form-control-solid" name="txtnombre" id="txtnombre" value=""/>
            <!-- <input v-if="TipoModal == this.ModalAgregar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" v-bind:value="CodigoUsuario"/> -->
            <!-- <input v-if="TipoModal == this.ModalEditar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" :value="this.CodigoUsuario"/> -->
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="fs-6 fw-semibold form-label mt-3">
              <span class="required">Descripción:</span>
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <input type="text" class="form-control form-control-solid" name="txtdescripcion" id="txtdescripcion" value=""/>
             <!--end::Input-->
          </div>
        </div>
        <!--end::Scroll-->
        <!--begin::Actions-->
        <div class="text-center pt-15">
          <button @click="cerrarModal()" type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">
            Cancelar
          </button>
          <button v-if="dTipoModal == ModalEditar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Modificar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button v-if="dTipoModal == ModalAgregar" type="button" class="btn btn-primary" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Registrar</span>
            <span class="indicator-progress" >Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Modal body-->
  </div>
</template>
<script>
    import { defineComponent } from "vue";
    import axios from "axios";
    //import Usuario from '@/views/Usuario.vue'
    export default defineComponent({
    name: "Personalmodal",
    props: [
    "TipoModal",
    "ZonaID",
    "Nombre",
    "Descripcion",
    "UsuarioID",
    ],
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            dTipoModal:"",
            dZonaID:"",
            dNombre:"",
            dDescripcion:"",
            ModalAgregar: "mAgregar",
            ModalEditar: "mEditar",
            TOKEN:'',
             MODIFICADOR:'',
            txtnombre:"",
            txtdescripcion:"",
        }
    },
    created() {
        this.validacionStorage();
     
    },
    mounted(){
   this.asignacionVariable();
    },
    methods: {
        	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
               asignacionVariable(){
                this.dTipoModal =this.TipoModal;
                this.dZonaID = this.ZonaID;
                this.dNombre = this.Nombre;
                this.dDescripcion = this.Descripcion;
                   if(this.TipoModal==this.ModalEditar){
                     document.getElementById("txtnombre").value=this.Nombre;
                    document.getElementById("txtdescripcion").value=this.Descripcion;
                    $('#txtnombre').prop('disabled', true);
                   }
               },
               validacionCampos(){
        this.txtnombre = document.getElementById("txtnombre").value
        this.txtdescripcion=document.getElementById("txtdescripcion").value
         if (this.TipoModal == this.ModalAgregar) {
                if (this.txtnombre == "") {
                    this.alertValidacion();
                }else{
                     this.alerDialogRegistrar();
                }
         }else if(this.TipoModal == this.ModalEditar){
            if (this.txtnombre == "") {
                 this.alertValidacion();
                 }else{
                  this.alerDialogModificar();
             }
         }
               },
        alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
     alerDialogRegistrar() {
      Swal.fire({
        text: "Estás seguro de que deseas Registrar? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Registrar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.registrarZona();
          Swal.fire({
            text: "Se Registró correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
            window.location.href = "/zona"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    registrarZona: function () {
      axios
        .post(this.BASE_URL+"/zona/insertar", {
          Nombre: this.txtnombre,
          Descripcion: this.txtdescripcion,   
          UsuarioID: this.MODIFICADOR,
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
        });

    },
     alerDialogModificar() {
          Swal.fire({
        text: "Estás seguro de que deseas Modificar? ",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Modificar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-warning",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.modificarZona();
          Swal.fire({
            text: "Se modificó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
           window.location.href = "/zona"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    modificarZona: function () {
      axios.post(this.BASE_URL+"/zona/actualizar", {
          ZonaID: this.dZonaID,
          Nombre: this.txtnombre,
          Descripcion: this.txtdescripcion,
          UsuarioID: this.MODIFICADOR,

        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          
             
        });
    },
                   cerrarModal() {
      $("#kt_modal_edit_zona").modal("hide");
      this.$parent.showModal = false;
    },
    },




    })

</script>