<template>
    <div class="modal-content">
        <div class="modal-header" id="kt_modal_rol_header">
            <h2 class="fw-bold">Editar Saldo</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
                <span class="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                            transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                            fill="currentColor" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <form id="kt_modal_edit_saldo" class="form" action="#">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_edit_user_scroll" data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_edit_user_header"
                    data-kt-scroll-wrappers="#kt_modal_edit_user_scroll" data-kt-scroll-offset="300px"
                    style="max-height: 300px !important">
                    <div class="fv-row mb-7 text-start">
                        <label class="required fw-semibold fs-6 mb-2">Nombre Sucursal:</label>
                        <span class="input-group-text">{{this.DatosSaldo.NombreSucursal}}</span>
                    </div>
                    <div class="fv-row mb-7 text-start">
                        <div class="col fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Nombre:</label>
                            <span class="input-group-text">{{this.DatosSaldo.Nombre}}</span>
                        </div>
                    </div>
                    <div class="fv-row mb-7 text-start">
                        <div class="col fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Fecha Primer Saldo:</label>
                            <span class="input-group-text">{{this.getDateFormat(this.DatosSaldo.FechaSaldoInicial)}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Primer Saldo:</label>
                            <div class="input-group input-group-solid mb-5">
                                <span class="input-group-text">S/.</span>
                                <span class="form-control">{{this.DatosSaldo.PrimerSaldo}}</span>
                            </div>
                        </div>
                        <div class="col fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Editar Primer Saldo:</label>
                            <div class="input-group input-group-solid mb-5">
                                <span class="input-group-text">S/.</span>
                                <input type="number" id="txteditarsaldo" class="form-control"
                                    aria-label="Amount (to the nearest dollar)" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center pt-15">
                    <button @click="cerrarModal()" type="reset" class="btn btn-light me-3"
                        data-kt-users-modal-action="cancel">
                        Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" data-kt-users-modal-action="button"
                        @click="validacioncampos()">
                        <span class="indicator-label">Editar</span>
                        <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default ({
    name: "EditarSaldo",
    props: [
        "DatosSaldo",
    ],
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            TOKEN: '',
            MODIFICADOR: '',
            snombre: "",
            ssucursal: "",
            sfecha: "",
            sprimersaldo: "",
            saldo: "",
        }
    },
    components: {

    },
    created() {
        this.validacionStorage();
    },
    mounted() {
        this.asignacionVariable();
    },
    methods: {
        validacionStorage() {
            this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
            this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
            if (this.TOKEN === null) {
                this.$router.push('/login');
            }
        },
        getDateFormat(fecha) {
            return moment(fecha).format("DD/MM/YYYY hh:mm a");
        },
        asignacionVariable() {
            this.snombre = this.DatosSaldo.Nombre;
            this.ssucursal = this.DatosSaldo.NombreSucursal;
            this.sfecha = this.DatosSaldo.Fecha;
            this.sprimersaldo = this.DatosSaldo.PrimerSaldo;
        },
        validacioncampos() {
            this.saldo = document.getElementById("txteditarsaldo").value;
            if (this.saldo == "") {
                this.alertValidacion();
            } else {
                this.alerDialogEditar();
            }
        },
        alertValidacion() {
            Swal.fire({
                text: "Por favor llenar todos los campos",
                icon: "info",
                buttonsStyling: false,
                confirmButtonText: "Aceptar!",
                customClass: {
                    confirmButton: "btn fw-bold btn-info",
                },
            })
        },
        alerDialogEditar() {
            Swal.fire({
                text: "Estás seguro de que deseas Editar? ",
                icon: "question",
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Si, Editar!",
                cancelButtonText: "No, Cancelar",
                customClass: {
                    confirmButton: "btn fw-bold btn-primary",
                    cancelButton: "btn fw-bold btn-active-light-primary",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.editarSaldo();
                    Swal.fire({
                        text: "Se Editó correctamente",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then((result) => {
                        this.cerrarModal()
                        if (result.isConfirmed) {
                            window.location.href = "/saldo"
                        }
                    });
                } else if (result.dismiss === "cancel") {
                    Swal.fire({
                        text: "No se editó",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then(function () {
                    });
                }
            });
        },
        editarSaldo() {
            axios
                .post(this.BASE_URL + "/saldo/actualizar", {
                    SaldoID: this.DatosSaldo.SaldoInicialID,
                    SaldoInicial: this.saldo,
                    UsuarioID: this.MODIFICADOR,
                }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                });
        },
        cerrarModal() {
            $("#kt_modal_editar").modal("hide");
            this.$parent.showModal = false;
        },
    },
});
</script>