<template>
    <div v-if="showcontenedor" id="modulorol" class="d-flex flex-column flex-root">
        <div class="page d-flex flex-row flex-column-fluid">
            <!-- INICIO::PARTE LATERAL DEL MENU -->
            <Menu ref="menu"></Menu>
            <!-- FIN::PARTE LATERAL DEL MENU -->
            <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <!-- INICIO::PARTE SUPERIOR DEL MENU -->
                <Header></Header>
                <!-- FIN::PARTE SUPERIOR DEL MENU -->
                <div class="container-xxl">
                    <!-- INICIO::CARD EN DONDE VA EL CONTENIDO DE LA VISTA -->
                    <div class="card">
                        <!-- INICIO::DIV EN DONDE SE ENCUENTRE EL TITULO -->
                        <!-- <div class="border-0 pt-6">
                            <h1>Modulos</h1>
                        </div> -->
                        <!-- FIN::DIV EN DONDE SE ENCUENTRE EL TITULO -->
                    
                        <!-- INICIO::TABLA MODULO ROL -->
                        <div class="card-body py-4">
                            <div class="table-responsive container" style="height:500px">
                                <table class="table" id="kt_table_modulorol">
                                    <!-- INICIO::CONTENIDO DE LA TABLA-->
                                    <thead>
                                        <tr>
                                            <th class="min-w-125px text-start text-muted fw-bold fs-7 text-uppercase gs-0"
                                                scope="col">Modulo/Rol</th>
                                            <th v-for="datorol in Rol" :key="datorol.RolID"
                                                class="text-center min-w-120px text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <td class="min-w-120px justify-content-center d-flex" >{{ datorol.Nombre }}</td>
                                            </th>
                                        </tr>
                                        <!-- FIN:PARTE SUPERIOR DE LA TABLA -->
                                    </thead>
                                    <tbody>
                                        <tr v-for="datopadre in Padre" :key="datopadre.ModuloID" 
                                            class="text-start text-muted  fs-7  gs-0 " >
                                          
                                            <th v-if="datopadre.ModuloPadreID == null" class="min-w-140px "  scope="row" >
                                              <div style="height: 30px" class="fw-bold"> {{ datopadre.Nombre }}</div> 
                                              <div style="margin-left: 20px">
                                                <tr class="text-hijo " v-for="datohijo in datopadre.Hijo" :key="datohijo.ModuloID">
                                                    <th  v-if="datopadre.ModuloID == datohijo.ModuloPadreID">
                                                        {{ datohijo.Nombre }}
                                                     </th>
                                                </tr>
                                                </div>
                                            </th>
                                              
                                            <th v-for="datorol in Rol" :key="datorol.RolID" class="min-w-120px  text-center " scope="col"> 
                                                <td v-if="datopadre.ModuloPadreID == null" class="min-w-120px  text-center border-group"  scope="col">
                                                        <div v-for="(datoacceso, index) in datopadre.Acceso" :key="index"  class="centrado-opcion" >
                                                            <div v-if="datoacceso.idrol == datorol.RolID">
                                                                    <div class="form-check form-switch" style="height:30px">
                                                                        <input v-if="datoacceso.estado === '1'"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="flexSwitchCheckDefault"
                                                                            @click="this.actualizaracceso(datoacceso, 0)" checked>
                                                                        <input v-else class="form-check-input" type="checkbox"
                                                                            id="flexSwitchCheckDefault"
                                                                            @click="this.actualizaracceso(datoacceso, 1)">
                                                                    </div>
                                                                
                                                            </div>
                                                        </div>
                                                    <div v-for="datohij in datopadre.Hijo" :key="datohij.ModuloID">
                                                        <div v-for="(datoacceso, index) in datohij.Acceso" :key="index">
                                                            <div v-if="datoacceso.idrol == datorol.RolID" class="centrado-opcion" style="height:30px">
                                                                    <div class="form-check form-switch">
                                                                    <input v-if="datoacceso.estado === '1'" class="form-check-input"
                                                                        type="checkbox" id="flexSwitchCheckDefault"
                                                                        @click="this.actualizaracceso(datoacceso, 0)" checked>
                                                                    <input v-else class="form-check-input" type="checkbox"
                                                                        id="flexSwitchCheckDefault"
                                                                        @click="this.actualizaracceso(datoacceso, 1)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </th>
                                        </tr>
                                    </tbody>
                                    <!-- FIN::CONTENIDO DE LA TABLA-->
                                </table>
                            </div>
                        </div>
                        <!-- FIN::TABLA MODULO ROL -->
                    </div>
                    <!-- FIN::CARD EN DONDE VA EL CONTENIDO DE LA VISTA -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
import axios from 'axios';

export default {
    data() {
        return {
            Rol: [],
            Modulo: [],
            Acceso: [],
            Padre: [],
            TOKEN:'',
            MODIFICADOR:'',
            showcontenedor:false,
        }
    },
    components: {
        Menu,
        Header
    },
    mounted() {
        // this.listarrol();
        // this.listaraccesos();
        // this.listarmodulo();
        // this.arraygeneral();
        this.obtenerPermiso();
    },
    created() {
        this.validacionStorage();
     this.listarrol();
        this.listaraccesos();
        this.listarmodulo();
        this.arraygeneral();
    },
    methods: {
             obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
        	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                 this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
        // INICIO::METODO DE LISTAR ROLES
        listarrol() {
            let direccion = "https://apiavicola.csg.com.pe/avicolaAPI/rol/listar";
            axios.get(direccion, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }
            }).then(response => {
                this.Rol = response.data.respuesta;
            })
        },
        // FIN::METODO DE LISTAR ROLES
        arraygeneral() {

            let direccion = "https://apiavicola.csg.com.pe/avicolaAPI/modulo/listar";
            axios.get(direccion, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEyMzQ1Njc4OSIsImlhdCI6MTY2MDE3MTU5MH0.Co_frhxfp1SJ71BvBZ-RTpnhuJU3l_gyVAueyf5YyuI'
                }
            }).then(response => {
                for (let index = 0; index < response.data.respuesta.length; index++) {
                    if (response.data.respuesta[index].ModuloPadreID == null) {
                        this.Padre.push(response.data.respuesta[index]);
                        this.Padre[index].Hijo = [];
                        this.Padre[index].Acceso = [];
                        this.padreacceso(response.data.respuesta[index], this.Rol, this.Acceso);
                    } else {
                        this.padrehijo(response.data.respuesta[index].ModuloPadreID, response.data.respuesta[index]);
                    }
                }
            })
        },
        padrehijo(idpadre, datahijo) {
            var results = this.Padre.filter(function (idmodulo) { return idmodulo.ModuloID == idpadre; });
            results[0].Hijo.push(datahijo);
            this.hijoacceso(datahijo.ModuloID);
        },
        padreacceso(data, roll, accesooo) {
            roll.forEach(element => {
                var accessomodulo = accesooo.filter(function (acesso) { return acesso.ModuloID == data.ModuloID; });
                var padre = this.Padre.filter(function (padre) { return padre.ModuloID == data.ModuloID; });
                accessomodulo.forEach(elementa => {
                    if (elementa.ModuloID == data.ModuloID) {
                        if (data.Acceso.length == 0) {
                            data.Acceso.push({ idrol: elementa.RolID, idmodulo: elementa.ModuloID, estado: elementa.Estado });
                        } else {
                            var filt = data.Acceso.filter(function (acesso) { return acesso.idrol == elementa.RolID; });
                            if (filt.length == 0) {
                                data.Acceso.push({ idrol: elementa.RolID, idmodulo: elementa.ModuloID, estado: elementa.Estado });
                            }
                        }
                    }
                });
                var filt = data.Acceso.filter(function (acesso) { return acesso.idrol == element.RolID; });
                if (filt.length == 0) {
                    data.Acceso.push({ idrol: element.RolID, idmodulo: data.ModuloID, estado: '0' });
                }
            });
        },
        hijoacceso(idmodulohijo) {
            this.Padre.forEach(elementp => {
                var accessomodulo = this.Acceso.filter(function (acesso) { return acesso.ModuloID == idmodulohijo; });
                var filterhijo = elementp.Hijo.filter(function (hijo) { return hijo.ModuloID == idmodulohijo; });
                filterhijo.forEach(elementfh => {
                    elementfh.Acceso = [];
                    this.Rol.forEach(elementr => {
                        accessomodulo.forEach(elementah => {
                            if (elementfh.Acceso.length == 0) {
                                elementfh.Acceso.push({ idrol: elementah.RolID, idmodulo: elementah.ModuloID, estado: elementah.Estado });
                            } else {
                                var filt = elementfh.Acceso.filter(function (acesso) { return acesso.idrol == elementah.RolID; });
                                if (filt.length == 0) {
                                    elementfh.Acceso.push({ idrol: elementah.RolID, idmodulo: elementah.ModuloID, estado: elementah.Estado });
                                }
                            }
                        });
                        var filt = elementfh.Acceso.filter(function (acesso) { return acesso.idrol == elementr.RolID; });
                        if (filt.length == 0) {
                            elementfh.Acceso.push({ idrol: elementr.RolID, idmodulo: idmodulohijo, estado: '0' });
                        }
                    });

                });
            });
        },
        // INICIO::METODO DE LISTAR MODULOS
        listarmodulo() {
            let direccion = "https://apiavicola.csg.com.pe/avicolaAPI/modulo/listar";
            axios.get(direccion, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }
            }).then(response => {
                this.Modulo = response.data.respuesta;
            })
        },
        // FIN::METODO DE LISTAR MODULOS
        // INICIO:METODO DE LISTAR ACCESOS
        listaraccesos() {
            let direccion = "https://apiavicola.csg.com.pe/avicolaAPI/modulo/rol/listar";
            axios.get(direccion, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }
            }).then(response => {
                this.Acceso = response.data.respuesta;
            })
        },
        // FIN:METODO DE LISTAR ACCESOS
        // /INICIO:PRENDER O APAGAR INTERRUPTOR
        actualizaracceso(dato, estado) {
       
            let direccion = "https://apiavicola.csg.com.pe/avicolaAPI/modulo/rol/actualizar";
            axios.post(direccion, { RolID: dato.idrol, ModuloID: dato.idmodulo, Estado: estado, UsuarioID: this.MODIFICADOR }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  this.TOKEN
                }
            }).then(response => {
                 this.$refs.menu.obtenerModulos()
            })
  
        }
        // /INICIO:PRENDER O APAGAR INTERRUPTOR
    },
}
</script>
<style scoped lang="css">
#miTablaPersonalizada th{
  width: 350px;
  border: 1px solid;
}
.centrado-opcion{
    justify-content: center;
    display: flex;
    width: 120px;
}
.text-hijo{
height: 30px;
}
.text-hijo th{
    display: flex;
    align-items: center;
}
.border-group{
    border-bottom: 1px  dashed #80808057 !important;
}
</style>