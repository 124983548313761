<template>
  <div v-if="showcontenedor"  class="d-flex flex-column flex-root">
    
    <div class="page d-flex flex-row flex-column-fluid">
            <Menu></Menu>
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header></Header>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div id="kt_content_container" class="container-xxl">

            <div class="card bg-body-white card-xl-stretch mb-xl-8">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                        <div class="align-items-center position-relative my-1 mr-2">
                            <label class="fs-6 fw-semibold mb-2">Sucursal</label>
                             <select class="form-select form-select-solid" name="sucursal" id="sucursal" v-model="sucursal" disabled  data-control="select2" data-placeholder="Seleccione Sucursal" data-hide-search="true">
                          <option></option>
                          <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
                                {{ lstsucursal.Nombre }}
                              </option>
                          </select>
                        </div>
                        <div class="align-items-center position-relative my-1 mr-2 ms-5">
                                <!--begin::Label-->
                                <label class="required fw-semibold fs-6 mb-2">Tipo Saldo:</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <select class="form-select form-select-solid" data-dropdown-parent="#kt_content"
                                    data-placeholder="Seleccione Tipo Saldo" name="tiposaldo" id="tiposaldom"
                                    v-model="tiposaldo">
                                    <option></option>
                                    <option v-for="(lsttipo, index) in listado_tipo" :key="index"
                                        :value="lsttipo.TipoSaldoID">
                                        {{ lsttipo.Nombre }}
                                    </option>
                                </select>
                                <!--end::Input-->
                            </div>
                        <!-- <div class="align-items-center position-relative my-1 mr-2 ms-5">
                           <label class="fs-6 fw-semibold mb-2">Filtrar por</label>
                          <select class="form-select form-select-solid" id="tipoFecha" v-model="TipoFecha"  data-control="select2" data-placeholder="Seleccione" data-hide-search="true">
                          <option></option>
                          <option value="1">Dia</option>
                          <option value="2">Mes</option>
                          </select>
                       
                        </div> -->
                        <div class="align-items-center position-relative  text-start px-5 min-w-50px" v-if="cboProveedor">
                                <label class="fw-semibold fs-6 mb-2">Proveedor:</label>
                                  <select class="form-select form-select-solid" data-dropdown-parent="#kt_content" data-placeholder="Seleccione Proveedor" name="proveedor" id="proveedorm" v-model="proveedor" >
                                    <option></option>
                                    <option v-for="(lstproveedor, index) in listado_proveedor" :key="index" :value="lstproveedor.CodigoProveedor">
                                        {{ lstproveedor.Nombre }}
                                    </option>
                                </select>
                             </div>
                        <div class="align-items-center position-relative  text-start px-5 min-w-50px" v-if="cboCliente">
                                <label class="fw-semibold fs-6 mb-2">Cliente:</label>
                                  <select class="form-select form-select-solid" data-dropdown-parent="#kt_content" data-placeholder="Seleccione Cliente" name="cliente" id="clientem" v-model="cliente" >
                                    <option></option>
                                    <option v-for="(lstcliente, index) in listado_cliente" :key="index" :value="lstcliente.CodigoCliente">
                                        {{ lstcliente.Nombre }}
                                    </option>
                                </select>
                             </div>
                         <!-- <div class="align-items-center position-relative my-1 mr-2 ms-5 text-left" v-if="selectCombo">
                            <label class="fs-6 fw-semibold mb-2 ">Fecha</label>
                            <div v-if="FechaMes" >
                                  <Calendar   :value="FechaMesValue" placeholder="Seleccione Fecha" v-model="FechaMesValue" view="month" dateFormat="mm/yy" />
                            </div>
                            <div v-if="FechaDia">
                               <Calendar inputId="basic" v-model="FechaDiaValue" placeholder="Seleccione Fecha"  dateFormat="dd/mm/yy" autocomplete="off" />
                            </div>
                        </div> -->
                      
                        <div class="align-items-center position-relative my-1 ms-5">
                            <button type="button" @click="generarReporte()"  class="btn btn-light-primary mt-9" tooltipPosition="top" pTooltip="Buscar"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                
                     <div class="card-toolbar" v-if="vistaReporte">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                            <button type="button" @click="DescargarPDF()"  class="btn btn-light-primary me-3"><i class="fa-sharp fa-solid fa-download"></i>Descargar Reporte</button>
                        </div>
                    </div>
                   
                </div>
            </div> 



            <ProgressSpinner aria-label="Basic ProgressSpinner" v-if="loading"/>
            <div id="id-elemento-pdf" class="card" v-if="vistaReporte && !loading">
               
         
                 <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>KARDEX</h5>
                    </div>
                </div>
                        <div class="row ms-10 me-10"  v-if="!VistaSinData">
                            <div class="col-md-6 fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2" >Nombre:</label>
                            <div class="input-group input-group-solid mb-5">
                                <input type="text"  disabled class="form-control" v-model="txtcliente" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-md-2 fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Saldo Inicial:</label>
                            <div class="input-group input-group-solid mb-5">
                                <span class="input-group-text">S/.</span>
                                <input type="text"  disabled class="form-control" v-model="txtinicial" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-md-2 fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Saldo Final:</label>
                            <div class="input-group input-group-solid mb-5">
                                <span class="input-group-text">S/.</span>
                                <input type="text" disabled class="form-control" v-model="txtfinal" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-md-2 fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Primer Saldo:</label>
                            <div class="input-group input-group-solid mb-5">
                                <span class="input-group-text">S/.</span>
                                <input type="text" disabled class="form-control" v-model="txtprimer" autocomplete="off" />
                            </div>
                        </div>
                        </div>

                   <div class="card-body pt-0">
                    <DataTable :value="Reportekardex" :scrollable="true"  style="width: 100%" v-if="TablaMesVenta">
                        <Column field="Fecha"  header="Fecha" style="width:30%;"></Column>
                        <Column field="SaldoInicial"  header="Saldo Inicial" style="text-aligne: center;width:10%;"></Column>
                        <Column field="Importe"  header="Importe" style="width:10%;"></Column>
                        <Column field="Devolucion"  header="Devolución" style="width:10%;"></Column>
                        <Column field="Pago"  header="Pago" style="width:10%;"></Column>
                        <Column field="SaldoFinal"  header="Saldo Final" style="width:10%;"></Column>
                  </DataTable>
                   <!-- <DataTable :value="ReporteKardex" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="Nombre" footer="TOTAL VENTAS:" header="Nombre" style="width:30%;"></Column>
                        <Column field="CantidadJabas" :footer="VentaTotalJaba" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPollo" :footer="VentaTotalPollo" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBruto" :footer="(VentaTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTara" :footer="(VentaTotalPesoTara).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNeto" :footer="(VentaTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="Precio" :footer="(VentaTotalPrecio).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(VentaTotalImporte).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable> -->
                   </div>
             
            </div>
            <div class="card" style="height: 400px" v-if="!vistaReporte">
           <h3 style="margin: auto;">Seleccione los filtros</h3>
            </div>
            <div class="card" style="height: 400px" v-if="vistaReporte && VistaSinData">
                     <h3 style="margin: auto;">No se ha encontrado información para estos datos Seleccionados,
                                              Por favor intentar con otros datos!</h3></div>
     
					</div>
				</div>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
// import Examplemodal from '@/components/Examplemodal.vue'
import axios from 'axios'




export default {
  name: 'rpt_kardex',
  components: {
    Menu,
    Header,
    Calendar,
    DataTable,
    Column,
    ProgressSpinner,

    // Examplemodal
  },
  data() {
    return {
         BASE_URL: process.env.VUE_APP_BASE_URL,
         TipoModal:"",
         TOKEN:'',
         MODIFICADOR:'',
         Buscar:'',
         UsuarioID:"",
         showModal: false,
         showcontenedor:false,
         listado_sucursal:[],
         sucursal:null,
         proveedor:"",
         cliente:null,
         listado_proveedor:[],
         listado_cliente:[],
         RolUser:'',
         fechastart:"",
         fechaend:"",

        selectCombo:true,
         TipoFecha:'',
         FechaMes:true,
         FechaDia:false,
         FechaMesValue:null,
         FechaDiaValue:null,
         ReporteGeneral:{
            SucursalID:null,
            Codigo:null,
            TipoSaldoID:null,
         },
         ReporteMes:{
            SucursalID:null,
            Mes:null,
            Anio:null,
            ProveedorID:null,
         },
         ReporteDia:{
          SucursalID:null,
            Fecha:null,
            ProveedorID:null
         },
         loading:false,
         vistaReporte:false,
         VistaSinData:true,
         TablaMesCompra: false,
         TablaMesVenta:false,

         TablaDiaCompra:false,
         TablaDiaventa:false,

         ReporteDataCompra:[],
         ReporteDataVenta:[],
         ReporteDataStock:[],
         Reportekardex:[],
        CompraTotalJaba:null,
        CompraTotalPollo:null,
        CompraTotalPesoBruto:null,
        CompraTotalPesoTara:null,
        CompraTotalPesoNeto:null,
        CompraTotalPrecio:null,
        CompraTotalImporte:null,

        VentaTotalJaba:null,
        VentaTotalPollo:null,
        VentaTotalPesoBruto:null,
        VentaTotalPesoTara:null,
        VentaTotalPesoNeto:null,
        VentaTotalPrecio:null,
        VentaTotalImporte:null,


        VentaTotalJabaHoy:null,
        VentaTotalPolloHoy:null,
        VentaTotalPesoBrutoHoy:null,
        VentaTotalPesoTaraHoy:null,
        VentaTotalPesoNetoHoy:null,
        VentaTotalPrecioHoy:null,
        VentaTotalImporteHoy:null,
        
         TotalTotalJaba:null,
        TotalTotalPollo:null,
        TotalTotalPesoBruto:null,
        TotalTotalPesoTara:null,
        TotalTotalPesoNeto:null,
        TotalTotalPrecio:null,
        TotalTotalImporte:null,

        mermareporte:null,
        FechaEntrada:null,
        FechaSalida:null,
        tablafinal:[],
        totalFinalJaba:0,
        totalFinalPollo : 0,
        totalFinalNeto:0,
        totalFinalImporte : 0,

        TipoSucursal:null,
        totalSubFinalJaba:0,
        totalSubFinalPollo:0,
        totalSubFinalNeto:0,
        totalSubFinalImporte:0,

         tiposaldo:null,
         listado_tipo:[],
         cboCliente:false,
         cboProveedor:false,
         txtcliente:"",
         txtinicial:"",
         txtfinal:"",
         txtprimer:"",
    }
  },
  mounted() {
    this.obtenerPermiso();
    this.obtenerSucursal();
    this.obtenerTipoSaldo();

  },
watch: {
    // whenever question changes, this function will run
    FechaMesValue: function (newValue) {
      this.ReporteMes.Mes=moment(newValue).month()+1
      this.ReporteMes.Anio=moment(newValue).year()
      },
      FechaDiaValue: function (newValue) {
        this.ReporteDia.Fecha=moment(newValue).format("YYYY-MM-DD")
      }
    },
  

 created() {
 this.validacionStorage();
 },
 
computed:{

    },

methods: {

getDateFormat(date){
return moment(date).format("DD-MM-YYYY")
}, obtenerTipoSaldo() {
            axios.get(this.BASE_URL + "/tiposaldo/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_tipo = response.data.respuesta;
                    $("#tiposaldom")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                            this.seleccionTipo(event);
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                });
        },
        DescargarPDF(){
          this.loading = true;
          var element = document.getElementById('id-elemento-pdf');
          var opt = {
            margin:       0,
            filename:     'kardex.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 4 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
          };
          html2pdf().from(element).set(opt).save();
          // html2pdf(element);
          this.loading=false;
        },
   obtenerSucursal() {
    this.sucursal= JSON.parse(localStorage.getItem('sucursal-ID'));
    this.ReporteGeneral.SucursalID= this.sucursal;
    // this.ReporteMes.SucursalID = this.sucursal;
    // this.ReporteDia.SucursalID = this.sucursal;
      axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
        //   this.listado_sucursal = response.data.respuesta;
        this.listado_sucursal=[];
        this.listado_sucursal = response.data.respuesta

 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
					if (this.RolUser ==="Administrador") {
						 $('#sucursal').removeAttr('disabled');
				}
                // this.obtenerProveedor();

          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
				multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            this.ReporteMes.SucursalID= this.sucursal
            this.ReporteDia.SucursalID= this.sucursal
            // this.obtenerProveedor();
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);

            });
         
            $("#tipoFecha")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
			        	multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.seleccionTipo(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
        
        });
       
         
      
    },
       obtenerProveedor() {
      axios
        .post(this.BASE_URL+"/proveedor/obtener/porsucursal",{
          SucursalID: this.sucursal},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
            this.proveedor=null;
            this.listado_proveedor=[];
          this.listado_proveedor = response.data.respuesta;
 		
    
    $("#proveedorm")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.ReporteGeneral.Codigo= this.proveedor;
            //    this.ReporteMes.ProveedorID= this.proveedor;
            // this.ReporteDia.ProveedorID= this.proveedor;
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
       obtenerCliente() {
      axios
        .post(this.BASE_URL+"/cliente/obtener/porsucursal",{
          SucursalID: this.sucursal},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
            this.cliente=null;
            this.listado_cliente=[];
          this.listado_cliente = response.data.respuesta;
 		
    
    $("#clientem")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.ReporteGeneral.Codigo= this.cliente;
            //    this.ReporteMes.ProveedorID= this.proveedor;
            // this.ReporteDia.ProveedorID= this.proveedor;
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
        generarReporte(){
       
          if (this.FechaMes) {
            if (this.ReporteGeneral.SucursalID != null  && this.ReporteGeneral.TipoSaldoID != null && this.ReporteGeneral.Codigo != null) {
               this.vistaReporte=true;
               this.loading=true;
               axios.post(this.BASE_URL + "/reporte/kardex/tiposaldo",this.ReporteGeneral, { headers: { 'Authorization': this.TOKEN } })
              .then((response) => {
                        this.Reportekardex=[];
                        this.Reportekardex=response.data.kardex;
                      if (response.data && this.Reportekardex.length>0) {
                        let valor = this.Reportekardex.length;
                        this.txtcliente=this.Reportekardex[0].Nombre;
                        this.txtinicial=this.Reportekardex[valor-1].SaldoInicial;
                        this.txtprimer=this.Reportekardex[0].PrimerSaldo;
                        this.txtfinal=this.Reportekardex[valor-1].SaldoFinal;
                        this.VistaSinData=false;
                        this.TablaDiaCompra = false;
                        this.TablaDiaVenta = false;
                        this.TablaMesCompra = true;
                        this.TablaMesVenta = true;
                        this.calculoReporte();
                        this.loading=false;
                      }else{
                        this.TablaMesCompra=false,
                        this.TablaMesVenta=false,
                        this.TablaDiaCompra=false,
                        this.TablaDiaVenta=false,
                        this.VistaSinData=true;
                        this.loading=false;
                      }

                  
              });
            }else{
              this.TablaMesCompra=false,
              this.TablaMesVenta=false,
              this.TablaDiaCompra=false,
              this.TablaDiaVenta=false,
              this.vistaReporte=false;
              this.alertaErrorPeticion();
            }
          
       
          }else{
             this.alertaErrorPeticion();
          }
    
    },
    calculoReporte(){
                          this.CompraTotalJaba=0,this.CompraTotalPollo=0,this.CompraTotalPesoBruto=0,this.CompraTotalPesoTara=0,this.CompraTotalPesoNeto=0,this.CompraTotalPrecio=0,this.CompraTotalImporte=0;
                          this.VentaTotalJaba=0,this.VentaTotalPollo=0,this.VentaTotalPesoBruto=0,this.VentaTotalPesoTara=0,this.VentaTotalPesoNeto=0,this.VentaTotalPrecio=0,this.VentaTotalImporte=0;
                          this.VentaTotalJabaHoy=0,this.VentaTotalPolloHoy=0,this.VentaTotalPesoBrutoHoy=0,this.VentaTotalPesoTaraHoy=0,this.VentaTotalPesoNetoHoy=0,this.VentaTotalPrecioHoy=0,this.VentaTotalImporteHoy=0;
                          this.TotalTotalJaba=0,this.TotalTotalPollo=0,this.TotalTotalPesoBruto=0,this.TotalTotalPesoTara=0,this.TotalTotalPesoNeto=0,this.TotalTotalPrecio=0,this.TotalTotalImporte=0;
                          this.mermareporte=null;

                            this.Reportekardex.forEach(ele=>{
                                    ele.Fecha= this.getDateFormat(ele.Fecha);
                            })


                        //  this.ReporteDataVenta.forEach(ele => {
                        //   ele.Fecha= this.getDateFormat(ele.Fecha);
                        //   this.VentaTotalJaba =  this.VentaTotalJaba + parseInt(ele.CantidadJabas)
                        //   this.VentaTotalPollo = this.VentaTotalPollo + parseInt(ele.CantidadPollo)
                        //   this.VentaTotalPesoBruto = this.VentaTotalPesoBruto + parseFloat(ele.PesoBruto)
                        //   this.VentaTotalPesoTara = this.VentaTotalPesoTara + parseFloat(ele.PesoTara)
                        //   this.VentaTotalPesoNeto = this.VentaTotalPesoNeto + parseFloat(ele.PesoNeto)
                        //   this.VentaTotalPrecio = this.VentaTotalPrecio + parseFloat(ele.Precio)
                        //   this.VentaTotalImporte = this.VentaTotalImporte + parseFloat(ele.Importe)
                        // });


                        //    this.ReporteDataStock.forEach(ele => {
                        //   this.FechaEntrada= ele.Fecha= this.getDateFormat(ele.Fecha);
                        //   this.VentaTotalJabaHoy =  this.VentaTotalJabaHoy + parseInt(ele.CantidadJabasEntrada)
                        //   this.VentaTotalPolloHoy = this.VentaTotalPolloHoy + parseInt(ele.CantidadPolloEntrada)
                        //   this.VentaTotalPesoBrutoHoy = this.VentaTotalPesoBrutoHoy + parseFloat(ele.PesoBrutoEntrada)
                        //   this.VentaTotalPesoTaraHoy = this.VentaTotalPesoTaraHoy + parseFloat(ele.PesoTaraEntrada)
                        //   this.VentaTotalPesoNetoHoy = this.VentaTotalPesoNetoHoy + parseFloat(ele.PesoNetoEntrada)
                        //   this.VentaTotalPrecioHoy = this.VentaTotalPrecioHoy + parseFloat(ele.PrecioEntrada)
                        //   this.VentaTotalImporteHoy = this.VentaTotalImporteHoy + parseFloat(ele.ImporteEntrada)
                        // });
                        

                        // this.VentaTotalPrecio = this.VentaTotalPrecio / this.ReporteDataVenta.length
                        // // calculando el resultado del reporte
                        // this.TotalTotalJaba=this.CompraTotalJaba- this.VentaTotalJaba
                        // this.TotalTotalPollo=this.CompraTotalPollo-this.VentaTotalPollo
                        // this.TotalTotalPesoBruto=this.CompraTotalPesoBruto-this.VentaTotalPesoBruto
                        // this.TotalTotalPesoTara=this.CompraTotalPesoTara-this.VentaTotalPesoTara
                        // this.TotalTotalPesoNeto=this.CompraTotalPesoNeto- this.VentaTotalPesoNeto
                        // this.TotalTotalPrecio= this.VentaTotalPrecio-this.CompraTotalPrecio
                        // this.TotalTotalImporte=this.VentaTotalImporte-this.CompraTotalImporte
                             this.listado_sucursal.forEach(ele=>{
                              if (ele.SucursalID == this.sucursal) {
                                this.TipoSucursal = ele.TipoSucursal;
                              }
                            })
                          var finalJaba = 0;
                          var finalPollo = 0;
                          var finalNeto=0;
                          var finalImporte=0;
                                if (this.TipoSucursal ==0) {
                                  finalJaba = this.VentaTotalJaba
                                   finalPollo =this.VentaTotalPollo;
                                  finalNeto = this.VentaTotalPesoNeto;
                                  finalImporte = this.VentaTotalImporte;
                                }else{
                                   finalPollo =this.VentaTotalPollo + this.VentaTotalPolloHoy;
                                  finalNeto = this.VentaTotalPesoNeto + this.VentaTotalPesoNetoHoy;
                                  finalImporte = this.VentaTotalImporte + this.VentaTotalImporteHoy;
                                }
                          

                          this.totalFinalJaba=0; this.totalFinalPollo = 0; this.totalFinalNeto=0; this.totalFinalImporte = 0;
                          var valorvariable=""; var valorVariableVenta=""; var valorVariableSubTotal="";
                            if (this.TablaMesVenta) {
                              valorvariable = "SALIDA";
                            }else if(this.TablaDiaVenta){
                              valorvariable = this.FechaSalida
                            }
                          
                           var valorjaba=0; var valorpollo = 0; var valorneto=0; var valorimporte=0;
                            if (this.TipoSucursal == 0) {
                              valorvariable = "COMPRA";
                              valorVariableVenta = "VENTA CLIENTES"
                              valorVariableSubTotal="VENTA TOTAL"
                              valorjaba = this.VentaTotalJabaHoy;
                              valorpollo = this.VentaTotalPolloHoy;
                              valorneto = this.VentaTotalPesoNetoHoy;
                              valorimporte = this.VentaTotalImporteHoy;
                            }
                              this.totalSubFinalPollo=0;
                              this.totalSubFinalNeto=0;
                              this.totalSubFinalImporte=0;
                            if (this.TipoSucursal == 0) {
                              this.totalSubFinalJaba =  (finalJaba - valorjaba)
                               this.totalSubFinalPollo = (finalPollo - valorpollo);
                              this.totalSubFinalNeto = (finalNeto - valorneto);
                              this.totalSubFinalImporte =   (finalImporte - valorimporte);
                            }

                          this.tablafinal=[
                                        {variable: valorVariableVenta, CantidadJaba:finalJaba, CantidadPollo:finalPollo , PesoNeto:(finalNeto).toFixed(2), Importe: finalImporte},
                                        {variable: "STOCK " + valorvariable, CantidadJaba:valorjaba, CantidadPollo: valorpollo, PesoNeto: valorneto, Importe: valorimporte} ,
        
                          ]
                          this.totalFinalJaba = this.totalSubFinalJaba ;
                          this.totalFinalPollo = this.totalSubFinalPollo;
                          this.totalFinalNeto = this.totalSubFinalNeto;
                          this.totalFinalImporte =   this.totalSubFinalImporte;
                            this.mermareporte= (this.totalFinalNeto/valorneto)*100
    },
    seleccionTipo(event){
      this.selectCombo = true;
      var TipoID = event.target.value;
      if (TipoID == 1) {
        this.ReporteGeneral.TipoSaldoID = TipoID;
           this.cboCliente = false;
           this.cboProveedor = true;
           this.obtenerProveedor();
           
      }else if (TipoID == 2) {
         this.ReporteGeneral.TipoSaldoID = TipoID;
           this.cboProveedor = false;
           this.cboCliente = true;
           this.obtenerCliente()
      }
    },
 
  	validacionStorage(){
      this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
      this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
        if(this.TOKEN ===  null){
          this.$router.push('/login');
      }
	
    },
    obtenerPermiso(){
        var pathname = window.location.pathname,firstObj=null, lista_mod=[];
        lista_mod= JSON.parse(localStorage.getItem('Modulos'))
        var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
        firstObj = (results.length > 0) ? results[0] : null;
        if(firstObj== null || firstObj== ""){
         this.showcontenedor=false
         window.location.href = "/home"
         }else{
                  this.showcontenedor=true
          }
           },
	alertaErrorPeticion(){
    Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })

},

}
}
 
</script>
<script>



</script>
<style>
.p-calendar .p-component{
  background: #f5f8fa !important;
  border: none !important;
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

</style>
