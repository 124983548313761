<template>
    <div v-if="showcontenedor"  id="pruebajs" class="d-flex flex-column flex-root">
        <div class="page d-flex flex-row flex-column-fluid">
            <Menu></Menu>

            <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <!--begin::Header-->
                <Header></Header>
                <!--end::Header-->
                <!--begin::Container-->
                <div class="container-xxl">
                    <div class="card">
                        <!-- INICIO::PARTE DE ARRIBA DE LA TABLA (BUSCAR ROL, FILTAR, AGREGAR)-->
                        <div class="card-header border-0 pt-6">
                            <!-- INICIO::CUADRO DE BUSCAR ROL -->
                            <div class="card-title">
                                <!--begin::Search-->
                                <div class="d-flex align-items-center position-relative my-1">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                                                rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                    <input type="text" data-kt-user-table-filter="search"
                                        class="form-control form-control-solid w-250px ps-14" v-model="buscarrol"
                                        placeholder="Buscar Rol" />
                                </div>
                                <!--end::Search-->
                            </div>
                            <!-- FIN::CUADRO DE BUSCAR ROL -->
                            <!-- INICIO::FILTRAR Y AGREGAR USUARIO -->
                            <div class="card-toolbar">
                                <!--begin::Toolbar-->
                                <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                    <!--begin::Boton Agregar Usuario-->
                                    <button type="button" class="btn btn-primary" @click="accionrol('', 'Agregar Rol')">
                                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
                                        <span class="svg-icon svg-icon-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                    fill="currentColor" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->Agregar Rol
                                    </button>
                                    <!--end::Agregar Usuario-->

                                </div>
                                <!--end::Toolbar-->
                               
                    
                                <div class="modal fade" id="kt_modal_export_users" tabindex="-1" aria-hidden="true">
                                    <!--begin::Modal dialog-->
                                    <div class="modal-dialog modal-dialog-centered mw-650px">
                                        <!--begin::Modal content-->
                                        <div class="modal-content">
                                            <!--begin::Modal header-->
                                            <div class="modal-header">
                                                <!--begin::Modal title-->
                                                <h2 class="fw-bold">Export Users</h2>
                                                <!--end::Modal title-->
                                                <!--begin::Close-->
                                                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                                                    @click="cancelButton()">
                                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                                                    <span class="svg-icon svg-icon-1">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2"
                                                                rx="1" transform="rotate(-45 6 17.3137)"
                                                                fill="currentColor" />
                                                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                                                transform="rotate(45 7.41422 6)" fill="currentColor" />
                                                        </svg>
                                                    </span>
                                                    <!--end::Svg Icon-->
                                                </div>
                                                <!--end::Close-->
                                            </div>
                                            <!--end::Modal header-->
                                     
                                        </div>
                                        <!--end::Modal content-->
                                    </div>
                                    <!--end::Modal dialog-->
                                </div>
                                <!--end::Modal - New Card-->
                                <!-- INICIO::MODAL AGREGAR O EDITAR ROL CON ENVIO DE PROPS (DATA) -->
                                <!-- FORMA 1 INICIO::CREANDO VARIABLES PARA ENVIO -->
                                <div class="modal fade" id="kt_modal_rol" tabindex="-1" aria-hidden="true"
                                    data-bs-backdrop="static" data-bs-keyboard="false">
                                    <div class="modal-dialog modal-dialog-centered mw-650px">
                                        <Rolmodal v-if="showModal" :vrolId="rolId" vusuarioId="1" :vnombre="unombre"
                                            :vtipomodal="tipomodal" :NombreUsuario="NombreUsuario">
                                        </Rolmodal>
                                    </div>
                                </div>
                                <!-- FORMA 1 FINAL::CREANDO VARIABLES PARA ENVIO -->
                                <!-- FORMA 2 INICIO::MANDANDO DIRECTO -->
                                <!-- FINAL::MODAL AGREGAR O EDITAR ROL CON ENVIO DE PROPS (DATA) -->
                                <!-- <Rolmodal :vrolId="nombre.RolID" vusuarioId="1" :vnombre="nombre.Nombre"></Rolmodal> -->
                                <!-- FORMA 2 FINAL::MANDANDO DIRECTO -->
                                <!--end::Modal - Add task-->
                            </div>
                            <!-- FIN::FILTRAR Y AGREGAR USUARIO -->
                        </div>
                        <!-- FIN::PARTE DE ARRIBA DE LA TABLA (BUSCAR ROL, FILTAR, AGREGAR)-->
                        <!-- INICIO::TABLA ROL -->
                        <div class="card-body py-4">
                            <div class="table-responsive container">
                                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                    <thead>
                                        <tr class="text-center text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <!-- <th class="min-w-125px" scope="col">RolId</th> -->
                                            <th class="min-w-115px">Nombre Rol</th>
                                            <th class="min-w-200px">Fecha de Creacion</th>
                                            <th class="min-w-150px">Usuario Creador</th>
                                            <th class="min-w-200px">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 fw-semibold">
                                        <tr class="text-center"  v-for="datorol in BuscarRol" :key="datorol.RolID">
                                            <!-- <td class="text-start">{{ datorol.RolID }}</td> -->
                                            <td >{{ datorol.Nombre }}</td>
                                            <td >{{getDateFormat(datorol.FechaRegistro)}}</td>
                                            <td >{{ datorol.NombreUsuario }}</td>
                                            <!--INICIO::BOTON DE ACCIONES=-->
                                            <td class="text-start">
                                            <div class="d-flex justify-content-center flex-shrink-0">
                                            <!-- <button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 " @click="accionrol(datorol, 'Modificar Rol')">
                                            <i class="fas fa-edit"></i>
                                            </button> -->
                                            <button type="button" @click="AlertEliminarRol(datorol)" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" >
                                            <i class="fas fa-trash"></i>
                                            </button >
                                        </div>
                                
                                            </td>
                                            <!--FIN::BOTON DE ACCIONES=-->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- FIN::TABLA ROL -->
                    </div>
                </div>

                <!--end::Container-->

            </div>
        </div>
    </div>
</template>

<script lang="js">
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import Rolmodal from '@/components/Rolmodal.vue'
import axios from 'axios';

export default {
    name: "Rol",
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            Rol: [],
            Roldatos: [],
            objetoRol: null,
            rolId: null,
            usuarioId: null,
            unombre: null,
            tipomodal: null,
            showModal: false,
            NombreUsuario:"",
            buscarrol: '',
            TOKEN:'',
            MODIFICADOR:'',
            showcontenedor:false,
        }
    },
    components: {
        Header,
        Menu,
        Rolmodal,
        Rolmodal
    },
    created(){
this.validacionStorage();
    },
    mounted: function () {
        this.obtenerPermiso();
        this.listarrol();
    },
    computed: {
    
        BuscarRol() {
            return this.Rol.filter(item => {
                return item.Nombre.toLowerCase().includes(this.buscarrol.toLowerCase())
                //    item.NombreCompleto.toLowerCase().includes(this.BuscarUsuario.toLowerCase())


            });
        },
    },
    methods: {
        obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
         	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
               window.location.href = "/login"
                }
              },
            getDateFormat(fecha) {
    return moment(fecha).format("DD/MM/YYYY hh:mm a");
  },
        //INICIO:FUNCION DE LISTAR ROL
        listarrol: function () {
           // this.objetoRol = new Rolll();
            let direccion = this.BASE_URL+"/rol/listar";
            axios.get(direccion,{headers:{'Authorization': this.TOKEN}}).then(response => {
                this.Rol = response.data.respuesta;
            })
        },
        //FIN:FUNCION DE LISTAR ROL
        //INICIO:FUNCION DE ELIMINAR ROL
         AlertEliminarRol({RolID}){
          Swal.fire({
        text: "Estás seguro de que deseas Eliminar? ",
        icon: "error",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-danger",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.eliminarrol({RolID});
          Swal.fire({
            text: "Se Eliminó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
            
           window.location.href = "/rol"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se eliminó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
      },
        eliminarrol: function ({RolID}) {
            let direccion = this.BASE_URL+"/rol/eliminar";
            axios.post(direccion, { RolID: RolID, UsuarioID: this.MODIFICADOR  },{headers:{'Authorization': this.TOKEN}}).then(response => {
            })
        },
        //FIN:FUNCION DE ELIMINAR ROL
        //INICIO:FUNCION DE EDITAR ROL
        accionrol: function (Rol, modal) {
            this.NombreUsuario = Rol.NombreUsuario
            this.rolId = Rol.RolID;
            this.usuarioId = '1';
            this.unombre = Rol.Nombre;
            this.tipomodal = modal;
            this.showModal = true;
            $('#kt_modal_rol').modal('show')
        }
    },
}
</script>