<template>
    <div class="modal-content">
    <!--begin::Modal header-->
    <div class="modal-header" id="kt_modal_edit_user_header">
      <!--begin::Modal title-->
      <h2 v-if="dTipoModal == ModalEditar" class="fw-bold">Modificar Personal</h2>
      <h2 v-if="dTipoModal == ModalAgregar" class="fw-bold">Agregar Personal</h2>
      <!--end::Modal title-->
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Close-->
    </div>
    <!--end::Modal header-->
    <!--begin::Modal body-->
    <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
      <!--begin::Form-->
      <form id="kt_modal_edit_user_form" class="form" action="#">
        <!--begin::Scroll-->
        <div
          class="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_edit_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_edit_user_header"
          data-kt-scroll-wrappers="#kt_modal_edit_user_scroll"
          data-kt-scroll-offset="300px"
          style="max-height: 300px !important"
        >
          <!--begin::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
            <!--end::Label-->
            <!--begin::Input-->
          <select class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione Sucursal"
              name="sucursalcbo"
              id="sucursalcbo"
              v-model="sucursal"
              
            >
              <option></option>
              <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                {{ lstsucursal.Nombre }}
              </option>
            </select> 
            <!--end::Input-->
          </div>
          <!--end::Input group-->
            <!--begin::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2">Cargo:</label>
            <!--end::Label-->
            <!--begin::Input-->
          <select
             
              class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione Cargo"
              name="cargo"
              id="cargo"
              v-model="cargo"
            >
              <option></option>
              <option v-for="(lstcargo, index) in listado_cargo" :key="index" :value="lstcargo.CargoID">
                {{ lstcargo.Nombre }}
              </option>
            </select> 
            <!-- <select v-if="dTipoModal == ModalEditar" class="form-select form-select-solid" data-control="select2" data-allow-clear="true" disabled>
              <option selected>{{ dNombreCargo }}</option>
            </select> -->
            <!--end::Input-->
          </div>
               <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2">Tipo de Documento:</label>
            <!--end::Label-->
            <!--begin::Input-->
          <select
              class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione Tipo Documento"
              name="documento"
              id="documento"
              v-model="documento"
            >
              <option></option>
              <option v-for="(lstdocumento, index) in listado_tipo_documento" :key="index" :value="lstdocumento.TipoDocumentoID">
                {{ lstdocumento.Nombre }}
              </option>
            </select> 
            <!-- <select v-if="dTipoModal == ModalEditar" class="form-select form-select-solid" data-control="select2" data-allow-clear="true" disabled>
              <option selected>{{ dNombreCargo }}</option>
            </select> -->
            <!--end::Input-->
          </div>
           <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2">Número Documento:</label>
            <!--end::Label-->
            <!--begin::Input-->
         <input type="text" class="form-control form-control-solid" name="txtnumerodocumento" id="txtnumerodocumento" value=""/>
            <!-- <input v-if="TipoModal == this.ModalAgregar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" v-bind:value="CodigoUsuario"/> -->
            <!-- <input v-if="TipoModal == this.ModalEditar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" :value="this.CodigoUsuario"/> -->
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2">Nombre:</label>
            <!--end::Label-->
            <!--begin::Input-->
         <input  type="text" class="form-control form-control-solid" @keyup="validacionLetra()" name="txtnombre" id="txtnombre" value=""/>
            <!-- <input v-if="TipoModal == this.ModalAgregar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" v-bind:value="CodigoUsuario"/> -->
            <!-- <input v-if="TipoModal == this.ModalEditar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" :value="this.CodigoUsuario"/> -->
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="fs-6 fw-semibold form-label mt-3">
              <span class="required">Apellidos:</span>
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <input type="text" class="form-control form-control-solid" name="txtapellidos" id="txtapellidos" value=""/>
             <!--end::Input-->
          </div>
              <!--begin::Input group-->
            <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2">Genero:</label>
            <!--end::Label-->
            <!--begin::Input-->
          <select
              class="form-select form-select-solid"
              data-dropdown-parent="#kt_modal_edit_user_form"
              data-placeholder="Seleccione Género"
              name="genero"
              id="genero"
              v-model="genero" >
              <option></option>
              <option v-for="(lstgenero, index) in listado_genero" :key="index" :value="lstgenero.Simbolo">
                {{ lstgenero.NombreGenero }}
              </option>
            </select> 
            <!-- <select v-if="dTipoModal == ModalEditar" class="form-select form-select-solid" data-control="select2" data-allow-clear="true" disabled>
              <option selected>{{ dNombreCargo }}</option>
            </select> -->
            <!--end::Input-->
          </div>
        <!--begin::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="fw-semibold fs-6 mb-2">Dirección:</label>
            <!--end::Label-->
            <!--begin::Input-->
         <input type="text" class="form-control form-control-solid" name="txtdireccion" id="txtdireccion" value=""/>
            <!-- <input v-if="TipoModal == this.ModalAgregar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" v-bind:value="CodigoUsuario"/> -->
            <!-- <input v-if="TipoModal == this.ModalEditar" type="text" class="form-control form-control-solid" name="txtcodigo" id="txtcodigo" :value="this.CodigoUsuario"/> -->
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <div class="fv-row mb-7 text-start">
            <!--begin::Label-->
            <label class="fs-6 fw-semibold form-label mt-3">
              <span>Telefono:</span>
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <input type="text"  class="form-control form-control-solid" name="txttelefono" @keyup="validacionTelefono()" id="txttelefono" value=""/>
             <!--end::Input-->
          </div>
              <!--begin::Input group-->
          <!--end::Input group-->
       
        </div>
        <!--end::Scroll-->
        <!--begin::Actions-->
        <div class="text-center pt-15">
          <button @click="cerrarModal()" type="reset" class="btn btn-light me-3" data-kt-users-modal-action="cancel">
            Cancelar
          </button>
          <button v-if="dTipoModal == ModalEditar" type="button" class="btn btn-warning" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Modificar</span>
            <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <button v-if="dTipoModal == ModalAgregar" type="button" class="btn btn-primary" data-kt-users-modal-action="button" @click="validacionCampos()">
            <span class="indicator-label">Registrar</span>
            <span class="indicator-progress" >Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Modal body-->
  </div>
</template>
<script>
    import { defineComponent } from "vue";
    import axios from "axios";
    //import Usuario from '@/views/Usuario.vue'
    export default defineComponent({
    name: "Personalmodal",
    props: [
    "TipoModal",
    "PersonalID",
    "SucursalID",
    "NombreSucursal",
    "UsuarioID",
    "CargoID",
    "NombreCargo",
    "Nombre",
    "Apellidos",
    "Genero",
    "Direccion",
    "Telefono",
    "TipoDocumentoID",
    "NumeroDocumento",
    ],
    data() {
    return {
    BASE_URL: process.env.VUE_APP_BASE_URL,
    dTipoModal: "",
    dPersonalID:"",
    dSucursalID:"",
    dNombreSucursal:"",
    dUsuarioID: "",
    dCargoID: "",
    dNombreCargo:"",
    dNombre: "",
    dApellidos: "",
    dGenero: "",
    dDireccion: "",
    dTelefono: "",
    dTipoDocumentoID: "",
    dNumeroDocumento: "",
    ModalAgregar: "mAgregar",
    ModalEditar: "mEditar",
    modoConsulta:false,


    // 
    listado_tipo_documento:[],
    listado_genero:[{GeneroID:1,NombreGenero:"Masculino",Simbolo:"M"},{GeneroID:2,NombreGenero:"Femenino",Simbolo:"F"},{GeneroID:3,NombreGenero:"Sin Especificar",Simbolo:"N"}],
    listado_cargo:[],
    listado_sucursal:[],
    sucursal:"",
    cargo:"",
    genero:"",
    documento:"",
    txtnumerodocumento:"",
    txtnombre:"",
    txtapellidos:"",
    txtdireccion:"",
    txttelefono:"",
    TOKEN:'',
    MODIFICADOR:'',
    expresionnumero : /[^+\d]/g,
  //  expresionletra: ^[0-9]+$,
    }
},created() {
  this.validacionStorage();
    this.obtenerSucursal();
   this.obtenerTipoDocumento();
   this.obtenerCargo();
},computed:{

},
mounted() {
    this.asignacionVariable();

}, methods: {
        	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
    validacionLetra(){
        var regex = new RegExp("^[0-9]+$");
var nombre = document.getElementById("txtnombre")
    nombre.value = nombre.value.replace(regex,"");
    },
    validacionTelefono(){
  
    var numTel = document.getElementById("txttelefono");
    numTel.maxLength = 9;

    numTel.value = numTel.value.replace(this.expresionnumero,"");
    },
    asignacionVariable(){
        this.dTipoModal =this.TipoModal;
        this.dPersonalID=this.PersonalID
         this.sucursal= this.SucursalID
        this.dSucursalID=this.SucursalID
        this.dNombreSucursal=this.NombreSucursal
        this.dUsuarioID=this.UsuarioID
        this.dCargoID=this.CargoID
        this.cargo=this.CargoID
        this.dNombreCargo=this.NombreCargo
        this.dNombre=this.Nombre
        this.dApellidos=this.Apellidos
        this.dGenero=this.Genero
        this.genero=this.Genero
        this.dDireccion=this.Direccion
        this.dTelefono=this.Telefono
        this.dTipoDocumentoID=this.TipoDocumentoID
        this.documento=this.TipoDocumentoID
        this.dNumeroDocumento=this.NumeroDocumento

        if(this.TipoModal==this.ModalEditar){
              this.modoConsulta = true;
          
            document.getElementById("txtnumerodocumento").value=this.NumeroDocumento
            document.getElementById("txtnombre").value=this.Nombre;
            document.getElementById("txtapellidos").value=this.Apellidos
            document.getElementById("txtdireccion").value=this.Direccion
            document.getElementById("txttelefono").value=this.Telefono
            $('#sucursalcbo').prop('disabled', true);

        }
    },
    obtenerTipoDocumento(){
      axios.get(this.BASE_URL+'/tipodocumento/listar',{headers:{'Authorization': this.TOKEN}})
                .then((response) => {
                    this.listado_tipo_documento = response.data.respuesta.filter(ele=>ele.Nombre != "RUC")
                    
                });
    },
    obtenerCargo(){
      axios.get(this.BASE_URL+'/cargo/listar',{headers:{'Authorization': this.TOKEN}})
                .then((response) => {
                    this.listado_cargo = response.data.respuesta
                });
    },
    obtenerSucursal(){
        axios
        .get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
          $("#sucursalcbo")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
            // 
            $("#cargo")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
            // 
              $("#genero")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
            // 
             $("#documento")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
            // 
        
        });
    },
    validacionCampos(){
            this.txtnumerodocumento = document.getElementById("txtnumerodocumento").value;
            this.txtnombre = document.getElementById("txtnombre").value;
            this.txtapellidos = document.getElementById("txtapellidos").value;
            this.txtdireccion = document.getElementById("txtdireccion").value;
            this.txttelefono = document.getElementById("txttelefono").value;
  
 
        if (this.TipoModal == this.ModalAgregar) {
            if(this.sucursal == ""||this.cargo == ""||this.genero == ""||this.documento == ""||this.txtnumerodocumento == ""|| this.txtnombre == "" || this.txtapellidos == "" ){
            this.alertValidacion();
            }else{
            this.alerDialogRegistrar();
             }
        }else if(this.TipoModal == this.ModalEditar){
            if(this.cargo == ""||this.genero == ""||this.documento == ""||this.txtnumerodocumento == ""|| this.txtnombre == "" || this.txtapellidos == ""){
               this.alertValidacion();
             }else{
            this.alerDialogModificar();
        }
        }
     
    },
    alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
     alerDialogRegistrar() {
      Swal.fire({
        text: "Estás seguro de que deseas Registrar? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Registrar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.registrarPersonal();
          Swal.fire({
            text: "Se Registró correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
            window.location.href = "/personal"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    registrarPersonal: function () {
      axios
        .post(this.BASE_URL+"/personal/insertar", {
          CargoID: this.cargo,
          SucursalID: this.sucursal,
          Nombre: this.txtnombre,
          Apellidos:this.txtapellidos ,
          Genero: this.genero,
          Direccion:this.txtdireccion,
          Telefono:this.txttelefono,
          TipoDocumentoID:this.documento,
          NumeroDocumento: this.txtnumerodocumento,
          UsuarioID: this.MODIFICADOR,
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
        });
    },
     alerDialogModificar() {
 
         
      Swal.fire({
        text: "Estás seguro de que deseas Modificar? ",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Modificar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-warning",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.modificarPersonal();
          Swal.fire({
            text: "Se modificó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            
           window.location.href = "/personal"
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    modificarPersonal: function () {
      axios.post(this.BASE_URL+"/personal/actualizar", {
          PersonalID: this.dPersonalID,
          CargoID: this.cargo,
          Nombre: this.txtnombre,
          Apellidos: this.txtapellidos,
          Genero: this.genero,
          Direccion:this.txtdireccion,
          Telefono:this.txttelefono,
          TipoDocumentoID:this.documento,
          NumeroDocumento:this.txtnumerodocumento,
          UsuarioID:this.MODIFICADOR,

        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {   
        });
    },
      cerrarModal() {
      $("#kt_modal_edit_personal").modal("hide");
      this.$parent.showModal = false;
    },
},

    })
</script>