import { createStore } from 'vuex'

export default createStore({
  state: {
    loggedIn: false
  },
  getters: {
  },
  mutations: {
    loggedIn(state){
      state.loggedIn=true
    }
  },
  actions: {
    mockLogin(context){
      setTimeout(function () {
        context.commit('loggedIn')
      }, 1000)
    }
  },
  modules: {
  }
})
