<template>
  <div v-if="showcontenedor"  class="d-flex flex-column flex-root">
    
    <div class="page d-flex flex-row flex-column-fluid">
            <Menu></Menu>
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header></Header>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div id="kt_content_container" class="container-xxl">

            <div class="card bg-body-white card-xl-stretch mb-xl-8">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                        <div class="align-items-center position-relative my-1 mr-2">
                            <label class="fs-6 fw-semibold mb-2">Sucursal</label>
                             <select class="form-select form-select-solid" name="sucursal" id="sucursal" v-model="sucursal" disabled  data-control="select2" data-placeholder="Seleccione Sucursal" data-hide-search="true">
                          <option></option>
                          <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
                                {{ lstsucursal.Nombre }}
                              </option>
                          </select>
                        </div>
                        <div class="align-items-center position-relative my-1 mr-2 ms-5">
                           <label class="fs-6 fw-semibold mb-2">Filtrar por</label>
                          <select class="form-select form-select-solid" id="tipoFecha" v-model="TipoFecha"  data-control="select2" data-placeholder="Seleccione" data-hide-search="true">
                          <option></option>
                          <option value="1">Dia</option>
                          <option value="2">Mes</option>
                          </select>
                       
                        </div>
                         <div class="align-items-center position-relative my-1 mr-2 ms-5 text-left" v-if="selectCombo">
                            <label class="fs-6 fw-semibold mb-2 ">Fecha</label>
                             <!-- <input class="form-control form-control-solid" placeholder="Pick date rage" id="fecharango" name="fecharango"/> -->
                            <div v-if="FechaMes" >
                               <flat-pickr v-model="FechaMesValue" :value="FechaMesValue" :config="configs.monthSelect" class="form-control text-center"
                                        placeholder="Seleccione Fecha" name="month" id="month"> </flat-pickr>
                                  <!-- <Calendar   :value="FechaMesValue" placeholder="Seleccione Fecha" v-model="FechaMesValue" view="month" dateFormat="mm/yy" /> -->
                            </div>
                            <div v-if="FechaDia">
                               <flat-pickr v-model="FechaDiaValue" :config="config" class="form-control text-center"
                                        placeholder="Seleccione Fecha" name="date" id="date" />
                               <!-- <Calendar inputId="basic" v-model="FechaDiaValue" placeholder="Seleccione Fecha"  dateFormat="dd/mm/yy" autocomplete="off" /> -->
                            </div>
                        
                        </div>
                      
                        <div class="align-items-center position-relative my-1 ms-5">
                            <button type="button" @click="generarReporte()"  class="btn btn-light-primary mt-9" tooltipPosition="top" pTooltip="Buscar"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                
                    <div class="card-toolbar" v-if="vistaReporte">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                             <button type="button"  @click="DescargarPDF(),this.descargar=true"  class="btn btn-light-primary me-3"><i class="fa-sharp fa-solid fa-download"></i>Descargar Reporte</button>
                        </div>
                    </div>
                   
                </div>
            </div> 



            <ProgressSpinner aria-label="Basic ProgressSpinner" v-if="loading"/>
            <div class="card" v-if="vistaReporte && !loading">
               
                <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>REPORTE DE COMPRA</h5>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <DataTable :value="ReporteDataCompra" :scrollable="true" scrollHeight="400px" v-if="TablaMesCompra" >
                        <Column field="Fecha" footer="TOTAL COMPRAS:" header="Fecha"></Column>
                        <Column field="CantidadJabas" :footer="CompraTotalJaba" header="# Jabas" style="text-aligne: center;"></Column>
                        <Column field="CantidadPollo" :footer="CompraTotalPollo" header="# Pollos"></Column>
                        <Column field="PesoBruto" :footer="(CompraTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)"></Column>
                        <Column field="PesoTara" :footer="(CompraTotalPesoTara).toFixed(2)" header="Peso Tara (kg)"></Column>
                        <Column field="PesoNeto" :footer="(CompraTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)"></Column>
                        <Column field="Precio" :footer="(CompraTotalPrecio).toFixed(2)" style="font-weight: bold;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(CompraTotalImporte).toFixed(2)" style="font-weight: bold;" header="Importe S/."></Column>
                  </DataTable>
                  <DataTable :value="ReporteDataCompra" :scrollable="true" scrollHeight="400px" v-if="TablaDiaCompra" >
                        <Column field="Nombre" footer="TOTAL COMPRAS:" header="Nombre"></Column>
                        <Column field="CantidadJabas" :footer="CompraTotalJaba" header="# Jabas" style="text-aligne: center;"></Column>
                        <Column field="CantidadPollo" :footer="CompraTotalPollo" header="# Pollos"></Column>
                        <Column field="PesoBruto" :footer="(CompraTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)"></Column>
                        <Column field="PesoTara" :footer="(CompraTotalPesoTara).toFixed(2)" header="Peso Tara (kg)"></Column>
                        <Column field="PesoNeto" :footer="(CompraTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)"></Column>
                        <Column field="Precio" :footer="(CompraTotalPrecio).toFixed(2)" style="font-weight: bold;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(CompraTotalImporte).toFixed(2)" style="font-weight: bold;" header="Importe S/."></Column>
                  </DataTable>

                   </div>
                
                 <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>REPORTE DE VENTA</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                    <DataTable :value="ReporteDataVenta" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaMesVenta">
                        <Column field="Fecha" footer="TOTAL VENTAS:" header="Fecha" style="width:30%;"></Column>
                        <Column field="CantidadJabas" :footer="VentaTotalJaba" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPollo" :footer="VentaTotalPollo" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBruto" :footer="(VentaTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTara" :footer="(VentaTotalPesoTara).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNeto" :footer="(VentaTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="Precio" :footer="(VentaTotalPrecio).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(VentaTotalImporte).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   <DataTable :value="ReporteDataVenta" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="Nombre" footer="TOTAL VENTAS:" header="Nombre" style="width:30%;"></Column>
                        <Column field="CantidadJabas" :footer="VentaTotalJaba" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPollo" :footer="VentaTotalPollo" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBruto" :footer="(VentaTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTara" :footer="(VentaTotalPesoTara).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNeto" :footer="(VentaTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="Precio" :footer="(VentaTotalPrecio).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(VentaTotalImporte).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   </div>
   <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5 v-if="TablaMesVenta">STOCK ENTRADA</h5>
                        <h5 v-if="TablaDiaVenta">STOCK ({{this.FechaEntrada}})</h5>
                    </div>
                </div>
                   <div class="card-body pt-5">
                         <DataTable :value="ReporteDataStockHoy" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaMesVenta">
                        <Column field="Fecha" footer="TOTAL STOCK:" header="Fecha" style="width:30%;"></Column>
                        <Column field="CantidadJabasEntrada" :footer="VentaTotalJabaHoy" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloEntrada" :footer="VentaTotalPolloHoy" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoEntrada" :footer="(VentaTotalPesoBrutoHoy).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraEntrada" :footer="(VentaTotalPesoTaraHoy).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoEntrada" :footer="(VentaTotalPesoNetoHoy).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioEntrada" :footer="(VentaTotalPrecioHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteEntrada" :footer="(VentaTotalImporteHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   <DataTable :value="ReporteDataStockHoy" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="NombreProveedor" footer="TOTAL STOCK:" header="Nombre" style="width:30%;"></Column>
                        <Column field="CantidadJabasEntrada" :footer="VentaTotalJabaHoy" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloEntrada" :footer="VentaTotalPolloHoy" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoEntrada" :footer="(VentaTotalPesoBrutoHoy).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraEntrada" :footer="(VentaTotalPesoTaraHoy).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoEntrada" :footer="(VentaTotalPesoNetoHoy).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioEntrada" :footer="(VentaTotalPrecioHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteEntrada" :footer="(VentaTotalImporteHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   </div>
                    <div class="card-header border-0 pt-6" v-if="!VistaSinData && this.TipoSucursal !=0">
                    <div class="card-title">
                        <h5 v-if="TablaMesVenta">STOCK SALIDA</h5>
                        <h5 v-if="TablaDiaVenta">STOCK ({{this.FechaSalida}})</h5>
                    </div>
                </div>
                   <div class="card-body pt-5" v-if="this.TipoSucursal !=0">
                    <!-- <DataTable  :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaMesCompra && TablaMesVenta">
                        <Column  header="# Jabas x vender"  :footer="TotalTotalJaba" style="width:10%;"></Column>
                        <Column  header="# Pollos"  :footer="TotalTotalPollo" style="width:10%;"></Column>
                        <Column  header="# Compra P.N."  :footer="(CompraTotalPesoNeto).toFixed(2)+'Kg'" style="width:10%;"></Column>
                        <Column  header="# Venta P.N"  :footer="(VentaTotalPesoNeto).toFixed(2)+'Kg'" style="width:10%;"></Column>
                        <Column  header="Diferencia Kg"  :footer="(TotalTotalPesoNeto).toFixed(2)+'Kg'" style="width:10%;"></Column>
                         <Column  header="Merma"         :footer="(this.mermareporte).toFixed(2) + '%'" style="width:30%; color:red;"></Column>
                        <Column  header="Margen Precio"  :footer="'S/.'+(TotalTotalPrecio).toFixed(2)" style="width:10%;"></Column>
                  </DataTable>
                  <DataTable  :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaCompra && TablaDiaVenta">
                        <Column  header="# Jabas x vender"  :footer="TotalTotalJaba" style="width:10%;"></Column>
                        <Column  header="# Pollos"  :footer="TotalTotalPollo" style="width:10%;"></Column>
                        <Column  header="# Compra P.N"  :footer="(CompraTotalPesoNeto).toFixed(2)+'Kg'" style="width:10%;"></Column>
                        <Column  header="# Venta P.N"  :footer="(VentaTotalPesoNeto).toFixed(2)+'Kg'" style="width:10%;"></Column>
                        <Column  header="Diferencia Kg"  :footer="(TotalTotalPesoNeto).toFixed(2)+'Kg'" style="width:10%;"></Column>
                        <Column  header="Merma"         :footer="(this.mermareporte).toFixed(2) + '%'" style="width:30%; color:red;"></Column>
                        <Column  header="Margen Precio"  :footer="'S/.'+(TotalTotalPrecio).toFixed(2)" style="width:10%;"></Column>
                  </DataTable> -->

                    <DataTable :value="ReporteDataStockAyer" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaMesVenta">
                        <Column field="Fecha" footer="TOTAL VENTAS:" header="Fecha" style="width:30%;"></Column>
                        <Column field="CantidadJabasSalida" :footer="VentaTotalJabaAyer" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloSalida" :footer="VentaTotalPolloAyer" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoSalida" :footer="(VentaTotalPesoBrutoAyer).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraSalida" :footer="(VentaTotalPesoTaraAyer).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoSalida" :footer="(VentaTotalPesoNetoAyer).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioSalida" :footer="(VentaTotalPrecioAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteSalida" :footer="(VentaTotalImporteAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   <DataTable :value="ReporteDataStockAyer" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="NombreProveedor" footer="TOTAL VENTAS:" header="Nombre" style="width:30%;"></Column>
                        <Column field="CantidadJabasSalida" :footer="VentaTotalJabaAyer" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloSalida" :footer="VentaTotalPolloAyer" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoSalida" :footer="(VentaTotalPesoBrutoAyer).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraSalida" :footer="(VentaTotalPesoTaraAyer).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoSalida" :footer="(VentaTotalPesoNetoAyer).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioSalida" :footer="(VentaTotalPrecioAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteSalida" :footer="(VentaTotalImporteAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   </div>
                <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>REPORTE DE GASTOS</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                    <DataTable :value="ReporteDataPago" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaMesPago">
                        <Column field="Fecha" footer="TOTAL GASTOS:" header="Fecha" style="width:50%;"></Column>
                        <Column field="Monto" :footer="(PagoTotalMonto).toFixed(2)" header="Monto S/." style="text-aligne: center;width:50%;"></Column>
                       
                  </DataTable>
                   <DataTable :value="ReporteDataPago" :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaPago">
                        <Column field="Fecha" footer="TOTAL GASTOS:" header="Fecha" style="width:30%;"></Column>
                        <Column field="Descripcion"  header="Descripción" style="text-aligne: center;width:10%;"></Column>
                        <Column field="Monto" :footer="(PagoTotalMonto).toFixed(2)" header="Monto S/." style="text-aligne: center;width:50%;"></Column>
                  </DataTable>
                   </div>

                    <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>CÁLCULO</h5>
                    </div>
                </div>
                   <div class="card-body pt-5">
                    <DataTable  :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaMesCompra && TablaMesVenta">
                        <Column  header="Importe total de la venta"  :footer="'S/.'+(VentaTotalImporteCalculado).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Importe total de la compra" :footer="'S/.'+(CompraTotalImporte).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad bruta"  :footer="'S/.'+(TotalTotalImporte).toFixed(2)" style="width:10%; color:green;"></Column>
                        <Column  header="Importe total de los gastos"  :footer="'S/.'+(PagoTotalMonto).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad Neta"   :footer="'S/.'+(TotalTotalUtilidadNeta).toFixed(2)" style="width:10%;"></Column> 
                  </DataTable>
                  <DataTable  :scrollable="true" scrollHeight="400px" style="width: 100%" v-if="TablaDiaCompra && TablaDiaVenta">
                        <Column  header="Importe total de la venta"  :footer="'S/.'+(VentaTotalImporteCalculado).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Importe total de la compra"  :footer="'S/.'+(CompraTotalImporte).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad bruta"  :footer="'S/.'+(TotalTotalImporte).toFixed(2)" style="width:10%; color:green;"></Column>
                        <Column  header="Importe total de los gastos"  :footer="'S/.'+(PagoTotalMonto).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad Neta"  :footer="'S/.'+(TotalTotalUtilidadNeta).toFixed(2)" style="width:10%;"></Column>
                  </DataTable>
                   </div>
             
            </div>
            <div class="card" id="id-elemento-pdf" v-if="descargar">
               
                <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>REPORTE DE COMPRA</h5>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <DataTable :value="ReporteDataCompra"  v-if="TablaMesCompra" >
                        <Column field="Fecha" footer="TOTAL COMPRAS:" header="Fecha"></Column>
                        <Column field="CantidadJabas" :footer="CompraTotalJaba" header="# Jabas" style="text-aligne: center;"></Column>
                        <Column field="CantidadPollo" :footer="CompraTotalPollo" header="# Pollos"></Column>
                        <Column field="PesoBruto" :footer="(CompraTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)"></Column>
                        <Column field="PesoTara" :footer="(CompraTotalPesoTara).toFixed(2)" header="Peso Tara (kg)"></Column>
                        <Column field="PesoNeto" :footer="(CompraTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)"></Column>
                        <Column field="Precio" :footer="(CompraTotalPrecio).toFixed(2)" style="font-weight: bold;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(CompraTotalImporte).toFixed(2)" style="font-weight: bold;" header="Importe S/."></Column>
                  </DataTable>
                  <DataTable :value="ReporteDataCompra"  v-if="TablaDiaCompra" >
                        <Column field="Nombre" footer="TOTAL COMPRAS:" header="Nombre"></Column>
                        <Column field="CantidadJabas" :footer="CompraTotalJaba" header="# Jabas" style="text-aligne: center;"></Column>
                        <Column field="CantidadPollo" :footer="CompraTotalPollo" header="# Pollos"></Column>
                        <Column field="PesoBruto" :footer="(CompraTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)"></Column>
                        <Column field="PesoTara" :footer="(CompraTotalPesoTara).toFixed(2)" header="Peso Tara (kg)"></Column>
                        <Column field="PesoNeto" :footer="(CompraTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)"></Column>
                        <Column field="Precio" :footer="(CompraTotalPrecio).toFixed(2)" style="font-weight: bold;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(CompraTotalImporte).toFixed(2)" style="font-weight: bold;" header="Importe S/."></Column>
                  </DataTable>

                   </div>
                
                 <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>REPORTE DE VENTA</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                    <DataTable :value="ReporteDataVenta"  style="width: 100%" v-if="TablaMesVenta">
                        <Column field="Fecha" footer="TOTAL VENTAS:" header="Fecha" style="width:30%;"></Column>
                        <Column field="CantidadJabas" :footer="VentaTotalJaba" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPollo" :footer="VentaTotalPollo" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBruto" :footer="(VentaTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTara" :footer="(VentaTotalPesoTara).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNeto" :footer="(VentaTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="Precio" :footer="(VentaTotalPrecio).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(VentaTotalImporte).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   <DataTable :value="ReporteDataVenta"  style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="Nombre" footer="TOTAL VENTAS:" header="Nombre" style="width:30%;"></Column>
                        <Column field="CantidadJabas" :footer="VentaTotalJaba" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPollo" :footer="VentaTotalPollo" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBruto" :footer="(VentaTotalPesoBruto).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTara" :footer="(VentaTotalPesoTara).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNeto" :footer="(VentaTotalPesoNeto).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="Precio" :footer="(VentaTotalPrecio).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="Importe" :footer="(VentaTotalImporte).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   </div>
   <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5 v-if="TablaMesVenta">STOCK ENTRADA</h5>
                        <h5 v-if="TablaDiaVenta">STOCK ({{this.FechaEntrada}})</h5>
                    </div>
                </div>
                   <div class="card-body pt-5">
                         <DataTable :value="ReporteDataStockHoy"  style="width: 100%" v-if="TablaMesVenta">
                        <Column field="Fecha" footer="TOTAL STOCK:" header="Fecha" style="width:30%;"></Column>
                        <Column field="CantidadJabasEntrada" :footer="VentaTotalJabaHoy" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloEntrada" :footer="VentaTotalPolloHoy" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoEntrada" :footer="(VentaTotalPesoBrutoHoy).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraEntrada" :footer="(VentaTotalPesoTaraHoy).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoEntrada" :footer="(VentaTotalPesoNetoHoy).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioEntrada" :footer="(VentaTotalPrecioHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteEntrada" :footer="(VentaTotalImporteHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   <DataTable :value="ReporteDataStockHoy"  style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="NombreProveedor" footer="TOTAL STOCK:" header="Nombre" style="width:30%;"></Column>
                        <Column field="CantidadJabasEntrada" :footer="VentaTotalJabaHoy" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloEntrada" :footer="VentaTotalPolloHoy" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoEntrada" :footer="(VentaTotalPesoBrutoHoy).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraEntrada" :footer="(VentaTotalPesoTaraHoy).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoEntrada" :footer="(VentaTotalPesoNetoHoy).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioEntrada" :footer="(VentaTotalPrecioHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteEntrada" :footer="(VentaTotalImporteHoy).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   </div>
                    <div class="card-header border-0 pt-6" v-if="!VistaSinData && this.TipoSucursal !=0">
                    <div class="card-title">
                        <h5 v-if="TablaMesVenta">STOCK SALIDA</h5>
                        <h5 v-if="TablaDiaVenta">STOCK ({{this.FechaSalida}})</h5>
                    </div>
                </div>
                   <div class="card-body pt-5" v-if="this.TipoSucursal !=0">
                    <DataTable :value="ReporteDataStockAyer"  style="width: 100%" v-if="TablaMesVenta">
                        <Column field="Fecha" footer="TOTAL STOCK:" header="Fecha" style="width:30%;"></Column>
                        <Column field="CantidadJabasSalida" :footer="VentaTotalJabaAyer" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloSalida" :footer="VentaTotalPolloAyer" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoSalida" :footer="(VentaTotalPesoBrutoAyer).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraSalida" :footer="(VentaTotalPesoTaraAyer).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoSalida" :footer="(VentaTotalPesoNetoAyer).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioSalida" :footer="(VentaTotalPrecioAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteSalida" :footer="(VentaTotalImporteAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   <DataTable :value="ReporteDataStockAyer"  style="width: 100%" v-if="TablaDiaVenta">
                        <Column field="NombreProveedor" footer="TOTAL STOCK:" header="Nombre" style="width:30%;"></Column>
                        <Column field="CantidadJabasSalida" :footer="VentaTotalJabaAyer" header="# Jabas" style="text-aligne: center;width:10%;"></Column>
                        <Column field="CantidadPolloSalida" :footer="VentaTotalPolloAyer" header="# Pollos" style="width:10%;"></Column>
                        <Column field="PesoBrutoSalida" :footer="(VentaTotalPesoBrutoAyer).toFixed(2)" header="Peso Bruto (kg)" style="width:10%;"></Column>
                        <Column field="PesoTaraSalida" :footer="(VentaTotalPesoTaraAyer).toFixed(2)" header="Peso Tara (kg)" style="width:10%;"></Column>
                        <Column field="PesoNetoSalida" :footer="(VentaTotalPesoNetoAyer).toFixed(2)" header="Peso Neto (kg)" style="width:10%;"></Column>
                        <Column field="PrecioSalida" :footer="(VentaTotalPrecioAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Precio S/."></Column>
                        <Column field="ImporteSalida" :footer="(VentaTotalImporteAyer).toFixed(2)" style="font-weight: bold;width:10%;" header="Importe S/."></Column>
                  </DataTable>
                   </div>
                <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>REPORTE DE GASTOS</h5>
                    </div>
                </div>
                   <div class="card-body pt-0">
                    <DataTable :value="ReporteDataPago"  style="width: 100%" v-if="TablaMesPago">
                        <Column field="Fecha" footer="TOTAL GASTOS:" header="Fecha" style="width:50%;"></Column>
                        <Column field="Monto" :footer="(PagoTotalMonto).toFixed(2)" header="Monto S/." style="text-aligne: center;width:50%;"></Column>
                       
                  </DataTable>
                   <DataTable :value="ReporteDataPago"  style="width: 100%" v-if="TablaDiaPago">
                        <Column field="Fecha" footer="TOTAL GASTOS:" header="Fecha" style="width:30%;"></Column>
                        <Column field="Descripcion"  header="Descripción" style="text-aligne: center;width:10%;"></Column>
                        <Column field="Monto" :footer="(PagoTotalMonto).toFixed(2)" header="Monto S/." style="text-aligne: center;width:50%;"></Column>
                  </DataTable>
                   </div>

                    <div class="card-header border-0 pt-6" v-if="!VistaSinData">
                    <div class="card-title">
                        <h5>CÁLCULO</h5>
                    </div>
                </div>
                   <div class="card-body pt-5">
                    <DataTable   style="width: 100%" v-if="TablaMesCompra && TablaMesVenta">
                        <Column  header="Importe total de la venta"  :footer="'S/.'+(VentaTotalImporteCalculado).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Importe total de la compra" :footer="'S/.'+(CompraTotalImporte).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad bruta"  :footer="'S/.'+(TotalTotalImporte).toFixed(2)" style="width:10%; color:green;"></Column>
                        <Column  header="Importe total de los gastos"  :footer="'S/.'+(PagoTotalMonto).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad Neta"   :footer="'S/.'+(TotalTotalUtilidadNeta).toFixed(2)" style="width:10%;"></Column> 
                  </DataTable>
                  <DataTable   style="width: 100%" v-if="TablaDiaCompra && TablaDiaVenta">
                        <Column  header="Importe total de la venta"  :footer="'S/.'+(VentaTotalImporteCalculado).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Importe total de la compra"  :footer="'S/.'+(CompraTotalImporte).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad bruta"  :footer="'S/.'+(TotalTotalImporte).toFixed(2)" style="width:10%; color:green;"></Column>
                        <Column  header="Importe total de los gastos"  :footer="'S/.'+(PagoTotalMonto).toFixed(2)" style="width:10%;"></Column>
                        <Column  header="Utilidad Neta"  :footer="'S/.'+(TotalTotalUtilidadNeta).toFixed(2)" style="width:10%;"></Column>
                  </DataTable>
                   </div>
             
            </div>
            <div class="card" style="height: 400px" v-if="!vistaReporte">
           <h3 style="margin: auto;">Seleccione los filtros</h3>
            </div>
            <div class="card" style="height: 400px" v-if="vistaReporte && VistaSinData">
                     <h3 style="margin: auto;">No se ha encontrado información para estos datos Seleccionados,
                                              Por favor intentar con otros datos!</h3></div>
     
					</div>
				</div>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
// import Examplemodal from '@/components/Examplemodal.vue'
import axios from 'axios'




export default {
  name: 'rpt_utilidad_neta',
  components: {
    Menu,
    Header,
     flatPickr,
    DataTable,
    Column,
    ProgressSpinner,

    // Examplemodal
  },
  data() {
    return {
            configs: {
      monthSelect: {
                plugins: [
                  new monthSelectPlugin({
                    shorthand: true,
                    dateFormat: "m/Y",
                  })
                ]
              }
       },
         BASE_URL: process.env.VUE_APP_BASE_URL,
         TipoModal:"",
         TOKEN:'',
         MODIFICADOR:'',
         Buscar:'',
         UsuarioID:"",
         showModal: false,
         showcontenedor:false,
         listado_sucursal:[],
         sucursal:null,
         RolUser:'',
         fechastart:"",
         fechaend:"",

        selectCombo:false,
         TipoFecha:'',
         FechaMes:false,
         FechaDia:false,
         FechaMesValue:null,
         FechaDiaValue:null,
         ReporteMes:{
            SucursalID:null,
            Mes:null,
            Anio:null,
         },
         ReporteDia:{
          SucursalID:null,
            Fecha:null,
         },
         loading:false,
         vistaReporte:false,
         VistaSinData:true,
         TablaMesCompra: false,
         TablaMesVenta:false,
         TablaMesPago:false,

         TablaDiaCompra:false,
         TablaDiaVenta:false,
         TablaDiaPago:false,

         ReporteDataPago:[],
         ReporteDataCompra:[],
         ReporteDataVenta:[],
         ReporteDataStockHoy:[],
        ReporteDataStockAyer:[],
        CompraTotalJaba:null,
        CompraTotalPollo:null,
        CompraTotalPesoBruto:null,
        CompraTotalPesoTara:null,
        CompraTotalPesoNeto:null,
        CompraTotalPrecio:null,
        CompraTotalImporte:null,

        VentaTotalJaba:null,
        VentaTotalPollo:null,
        VentaTotalPesoBruto:null,
        VentaTotalPesoTara:null,
        VentaTotalPesoNeto:null,
        VentaTotalPrecio:null,
        VentaTotalImporte:null,

        VentaTotalJabaHoy:null,
        VentaTotalPolloHoy:null,
        VentaTotalPesoBrutoHoy:null,
        VentaTotalPesoTaraHoy:null,
        VentaTotalPesoNetoHoy:null,
        VentaTotalPrecioHoy:null,
        VentaTotalImporteHoy:null,

        VentaTotalJabaAyer:null,
        VentaTotalPolloAyer:null,
        VentaTotalPesoBrutoAyer:null,
        VentaTotalPesoTaraAyer:null,
        VentaTotalPesoNetoAyer:null,
        VentaTotalPrecioAyer:null,
        VentaTotalImporteAyer:null,

        FechaEntrada:null,
        FechaSalida:null,
        PagoTotalMonto:0,
        
        TipoSucursal:null,
        
         TotalTotalJaba:null,
        TotalTotalPollo:null,
        TotalTotalPesoBruto:null,
        TotalTotalPesoTara:null,
        TotalTotalPesoNeto:null,
        TotalTotalPrecio:null,
        TotalTotalImporte:null,
        TotalTotalUtilidadNeta: null,
        mermareporte:null,
        VentaTotalImporteCalculado:0,

        descargar:false,
    }
  },
  mounted() {
    this.obtenerPermiso();
    this.obtenerSucursal();

  },
watch: {
    // whenever question changes, this function will run
    FechaMesValue: function (newValue) {
      this.ReporteMes.Mes=moment(newValue).month()+1
      this.ReporteMes.Anio=moment(newValue).year()
      },
      FechaDiaValue: function (newValue) {
        this.ReporteDia.Fecha=moment(newValue).format("YYYY-MM-DD")
      }
    },
  

 created() {
 this.validacionStorage();
 },
 
computed:{

    },

methods: {

getDateFormat(date){
return moment(date).format("DD-MM-YYYY")
},
   obtenerSucursal() {
			this.sucursal=JSON.parse(localStorage.getItem('sucursal-ID'));
      this.ReporteDia.SucursalID= this.sucursal;
      this.ReporteMes.SucursalID= this.sucursal;
      axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
					if (this.RolUser ==="Administrador") {
						 $('#sucursal').removeAttr('disabled');
				}

          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
				multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            this.ReporteMes.SucursalID= this.sucursal
            this.ReporteDia.SucursalID= this.sucursal
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);

            });
         
            $("#tipoFecha")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
			        	multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
              this.seleccionTipo(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
        
        });
       
         
      
    },
      DescargarPDF(){
            this.loading = true;
            setTimeout(function() { 
              var element = document.getElementById('id-elemento-pdf')
                  var opt = {
            margin:       0.2,
            filename:     'UtilidadNeta.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 4 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
          };
          html2pdf().from(element).set(opt).save();
            }, 100);
             setTimeout(() => {
              this.descargar=false;
               this.descargar=false;
               this.loading = false;
             }, 2000);
          
        },
       
        generarReporte(){

          if (this.FechaMes) {
            let fecha = this.FechaMesValue.split('/');
             this.ReporteMes.Mes=fecha[0]
             this.ReporteMes.Anio=fecha[1]
            if (this.ReporteMes.SucursalID != null && this.ReporteMes.Mes != null && this.ReporteMes.Anio != null) {
               this.loading=true;
               axios.post(this.BASE_URL + "/reporte/utilidadneta/mes",this.ReporteMes, { headers: { 'Authorization': this.TOKEN } })
              .then((response) => {
                        this.ReporteDataCompra=[],this.ReporteDataVenta=[],this.ReporteDataPago=[],this.ReporteDataStockHoy=[],this.ReporteDataStockAyer=[];
                        this.ReporteDataCompra=response.data.compra;
                        this.ReporteDataVenta=response.data.venta;
                        this.ReporteDataPago=response.data.pago;
                        this.ReporteDataStockHoy=response.data.stockHoy;
                        this.ReporteDataStockAyer = response.data.stockAyer;
                      if (response.data && this.ReporteDataCompra.length>0 && this.ReporteDataVenta.length>0) {
                        this.vistaReporte=true;
                        this.VistaSinData=false;
                        this.TablaDiaCompra = false;
                        this.TablaDiaVenta = false;
                        this.TablaDiaPago = false;
                        this.TablaMesCompra = true;
                        this.TablaMesVenta = true;
                        this.TablaMesPago = true;
                        this.calculoReporte();
                        this.loading=false;
                      }else{
                        this.TablaMesCompra=false;
                        this.TablaMesVenta=false;
                        this.TablaMesPago=false;
                        this.TablaDiaCompra=false;
                        this.TablaDiaVenta=false;
                        this.TablaDiaPago=false;
                        this.VistaSinData=true;
                        this.loading=false;
                      }

                  
              });
            }else{
              this.TablaMesCompra=false;
              this.TablaMesVenta=false;
              this.TablaMesPago=false;
              this.TablaDiaCompra=false;
              this.TablaDiaVenta=false;
              this.TablaDiaPago=false;
              this.vistaReporte=false;
              this.alertaErrorPeticion();
            }
          
       
          }else if(this.FechaDia){
            if (this.ReporteDia.SucursalID != null && this.ReporteDia.Fecha != null) {
                this.loading=true;
              axios.post(this.BASE_URL + "/reporte/utilidadneta/dia",this.ReporteDia, { headers: { 'Authorization': this.TOKEN } })
              .then((response) => {
                         this.ReporteDataCompra=[],this.ReporteDataVenta=[],this.ReporteDataPago=[],this.ReporteDataStockHoy=[],this.ReporteDataStockAyer=[];
                        this.ReporteDataCompra=response.data.compra;
                        this.ReporteDataVenta=response.data.venta;
                        this.ReporteDataPago=response.data.pago;
                        this.ReporteDataStockHoy=response.data.stockHoy;
                        this.ReporteDataStockAyer = response.data.stockAyer;
                      if (response.data && this.ReporteDataCompra.length>0 && this.ReporteDataVenta.length>0) {
                          this.vistaReporte=true;
                          this.VistaSinData=false;
                          this.TablaMesCompra = false;
                          this.TablaMesVenta = false;
                          this.TablaMesPago = false;
                          this.TablaDiaCompra = true;
                          this.TablaDiaVenta = true;
                          this.TablaDiaPago = true;
                          this.calculoReporte();
                          this.loading=false;      
                      }else{
                        this.TablaMesCompra=false;
                        this.TablaMesVenta=false;
                        this.TablaMesPago=false;
                        this.TablaDiaCompra=false;
                        this.TablaDiaVenta=false;
                        this.TablaDiaPago=false;
                        this.VistaSinData=true;
                        this.loading=false;
                      }
              });
            
            }else{
              this.TablaMesCompra=false;
              this.TablaMesVenta=false;
              this.TablaMesPago=false;
              this.TablaDiaCompra=false;
              this.TablaDiaVenta=false;
              this.TablaDiaPago=false;
              this.vistaReporte=false;
              this.alertaErrorPeticion();
            }
       
          }else{
             this.alertaErrorPeticion();
          }
    
    },
    calculoReporte(){
             this.CompraTotalJaba=null,this.CompraTotalPollo=null,this.CompraTotalPesoBruto=null,this.CompraTotalPesoTara=null,this.CompraTotalPesoNeto=null,this.CompraTotalPrecio=null,this.CompraTotalImporte=null;
                         this.VentaTotalJaba=null,this.VentaTotalPollo=null,this.VentaTotalPesoBruto=null,this.VentaTotalPesoTara=null,this.VentaTotalPesoNeto=null,this.VentaTotalPrecio=null,this.VentaTotalImporte=null;
                         this.TotalTotalJaba=null,this.TotalTotalPollo=null,this.TotalTotalPesoBruto=null,this.TotalTotalPesoTara=null,this.TotalTotalPesoNeto=null,this.TotalTotalPrecio=null,this.TotalTotalImporte=null;
                        this.VentaTotalJabaHoy=0,this.VentaTotalPolloHoy=0,this.VentaTotalPesoBrutoHoy=0,this.VentaTotalPesoTaraHoy=0,this.VentaTotalPesoNetoHoy=0,this.VentaTotalPrecioHoy=0,this.VentaTotalImporteHoy=0;
                        this.VentaTotalJabaAyer=0,this.VentaTotalPolloAyer=0,this.VentaTotalPesoBrutoAyer=0,this.VentaTotalPesoTaraAyer=0,this.VentaTotalPesoNetoAyer=0,this.VentaTotalPrecioAyer=0,this.VentaTotalImporteAyer=0;
                      this.PagoTotalMonto =0, this.VentaTotalImporteCalculado=0;
                          this.mermareporte=null;
                        this.ReporteDataCompra.forEach(ele => {
                          ele.Fecha= this.getDateFormat(ele.Fecha);
                          this.CompraTotalJaba =  this.CompraTotalJaba + parseInt(ele.CantidadJabas)
                          this.CompraTotalPollo = this.CompraTotalPollo + parseInt(ele.CantidadPollo)
                          this.CompraTotalPesoBruto = this.CompraTotalPesoBruto + parseFloat(ele.PesoBruto)
                          this.CompraTotalPesoTara = this.CompraTotalPesoTara + parseFloat(ele.PesoTara)
                          this.CompraTotalPesoNeto = this.CompraTotalPesoNeto + parseFloat(ele.PesoNeto)
                          this.CompraTotalPrecio = this.CompraTotalPrecio + parseFloat(ele.Precio)
                          this.CompraTotalImporte = this.CompraTotalImporte + parseFloat(ele.Importe)
                        });
                        this.CompraTotalPrecio = this.CompraTotalPrecio / this.ReporteDataCompra.length;
                         this.ReporteDataVenta.forEach(ele => {
                        ele.Fecha= this.getDateFormat(ele.Fecha);
                          this.VentaTotalJaba =  this.VentaTotalJaba + parseInt(ele.CantidadJabas);
                          this.VentaTotalPollo = this.VentaTotalPollo + parseInt(ele.CantidadPollo);
                          this.VentaTotalPesoBruto = this.VentaTotalPesoBruto + parseFloat(ele.PesoBruto);
                          this.VentaTotalPesoTara = this.VentaTotalPesoTara + parseFloat(ele.PesoTara);
                          this.VentaTotalPesoNeto = this.VentaTotalPesoNeto + parseFloat(ele.PesoNeto);
                          this.VentaTotalPrecio = this.VentaTotalPrecio + parseFloat(ele.Precio);
                          this.VentaTotalImporte = this.VentaTotalImporte + parseFloat(ele.Importe);
                        });
                          this.ReporteDataStockHoy.forEach(ele => {
                          this.FechaEntrada= ele.Fecha= this.getDateFormat(ele.Fecha);
                          this.VentaTotalJabaHoy =  this.VentaTotalJabaHoy + parseInt(ele.CantidadJabasEntrada)
                          this.VentaTotalPolloHoy = this.VentaTotalPolloHoy + parseInt(ele.CantidadPolloEntrada)
                          this.VentaTotalPesoBrutoHoy = this.VentaTotalPesoBrutoHoy + parseFloat(ele.PesoBrutoEntrada)
                          this.VentaTotalPesoTaraHoy = this.VentaTotalPesoTaraHoy + parseFloat(ele.PesoTaraEntrada)
                          this.VentaTotalPesoNetoHoy = this.VentaTotalPesoNetoHoy + parseFloat(ele.PesoNetoEntrada)
                          this.VentaTotalPrecioHoy = this.VentaTotalPrecioHoy + parseFloat(ele.PrecioEntrada)
                          this.VentaTotalImporteHoy = this.VentaTotalImporteHoy + parseFloat(ele.ImporteEntrada)
                        });
                          this.ReporteDataStockAyer.forEach(ele => {
                          this.FechaSalida= ele.Fecha= this.getDateFormat(ele.Fecha);
                          this.VentaTotalJabaAyer =  this.VentaTotalJabaAyer + parseInt(ele.CantidadJabasSalida)
                          this.VentaTotalPolloAyer = this.VentaTotalPolloAyer + parseInt(ele.CantidadPolloSalida)
                          this.VentaTotalPesoBrutoAyer = this.VentaTotalPesoBrutoAyer + parseFloat(ele.PesoBrutoSalida)
                          this.VentaTotalPesoTaraAyer = this.VentaTotalPesoTaraAyer + parseFloat(ele.PesoTaraSalida)
                          this.VentaTotalPesoNetoAyer = this.VentaTotalPesoNetoAyer + parseFloat(ele.PesoNetoSalida)
                          this.VentaTotalPrecioAyer = this.VentaTotalPrecioAyer + parseFloat(ele.PrecioSalida)
                          this.VentaTotalImporteAyer = this.VentaTotalImporteAyer + parseFloat(ele.ImporteSalida)
                        });
                        
                           this.listado_sucursal.forEach(ele=>{
                              if (ele.SucursalID == this.sucursal) {
                                this.TipoSucursal = ele.TipoSucursal;
                              }
                            })
                        if (this.ReporteDataPago.length>0) {
                        this.ReporteDataPago.forEach(ele => {
                            ele.Fecha= this.getDateFormat(ele.Fecha);
                          this.PagoTotalMonto =  this.PagoTotalMonto + parseFloat(ele.Monto);
                          
                        });
                        }
                  
                        this.VentaTotalPrecio = this.VentaTotalPrecio / this.ReporteDataVenta.length;
                        // calculando el resultado del reporte
                        if (this.TipoSucursal !=0) {
                        this.VentaTotalImporteCalculado = (this.VentaTotalImporte + this.VentaTotalImporteHoy )-this.VentaTotalImporteAyer;
                        }else{
                        this.VentaTotalImporteCalculado = this.VentaTotalImporte + this.VentaTotalImporteHoy;
                        }

                        this.TotalTotalJaba=this.CompraTotalJaba- this.VentaTotalJaba;
                        this.TotalTotalPollo=this.CompraTotalPollo-this.VentaTotalPollo;
                        this.TotalTotalPesoBruto=this.CompraTotalPesoBruto-this.VentaTotalPesoBruto;
                        this.TotalTotalPesoTara=this.CompraTotalPesoTara-this.VentaTotalPesoTara;
                        this.TotalTotalPesoNeto=this.CompraTotalPesoNeto- this.VentaTotalPesoNeto;
                        this.TotalTotalPrecio= this.VentaTotalPrecio-this.CompraTotalPrecio;
                        this.TotalTotalImporte=this.VentaTotalImporteCalculado-this.CompraTotalImporte;
                        this.TotalTotalUtilidadNeta = this.TotalTotalImporte - this.PagoTotalMonto
                        this.mermareporte= (this.TotalTotalPesoNeto/this.CompraTotalPesoNeto)*100;
    },
    seleccionTipo(event){
      this.selectCombo = true;
      var TipoID = event.target.value;
      if (TipoID == 1) {
           this.FechaMes = false;
           this.FechaDia = true;
      }else if (TipoID == 2) {
           this.FechaDia = false;
           this.FechaMes = true;
      }
    },
 
  	validacionStorage(){
      this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
      this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
        if(this.TOKEN ===  null){
          this.$router.push('/login');
      }
	
    },
    obtenerPermiso(){
        var pathname = window.location.pathname,firstObj=null, lista_mod=[];
        lista_mod= JSON.parse(localStorage.getItem('Modulos'))
        var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
        firstObj = (results.length > 0) ? results[0] : null;
        if(firstObj== null || firstObj== ""){
         this.showcontenedor=false
         window.location.href = "/home"
         }else{
                  this.showcontenedor=true
          }
           },
	alertaErrorPeticion(){
    Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })

},

}
}
 
</script>
<script>



</script>
<style>

.p-calendar .p-component{
  background: #f5f8fa !important;
  border: none !important;
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

</style>
