<template>
    <div v-if="showcontenedor"  class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <Menu></Menu>

      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <Header></Header>
        		<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
					<div id="kt_content_container" class="container-xxl">
						<div class="card card-flush">
							<div class="card-header align-items-center py-5 gap-2 gap-md-5">
								<div class="card-title">
									<div class="d-flex align-items-center position-relative mt-9">
										<span class="svg-icon svg-icon-1 position-absolute ms-4">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
										<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
										</svg>
										</span>
										<input type="text" data-kt-ecommerce-order-filter="search" class="form-control form-control-solid w-250px ps-14" placeholder="Buscar por proveedor" v-model="Buscar"/>
									</div>
								</div>
								<div class="card-toolbar flex-row-fluid justify-content-end gap-5">
									 <div class="align-items-center position-relative  text-start  w-250px">
										<label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
										<select class="form-select form-select-solid" data-dropdown-parent="#kt_content" data-placeholder="Seleccione Sucursal" multiple="multiple"  data-close-on-select="false"
										name="sucursal[]" id="sucursal" v-model="sucursal" disabled>
										<option></option>
										<option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
											{{ lstsucursal.Nombre }}
										</option>
										</select>
									</div>
									<div class="fv-row text-start" >
                                        <label class="form-label">Fecha:</label>
										<label id="fechaini" style="display:none" ></label>
										<label id="fechafini" style="display:none"></label>
										 <input class="form-control form-control-solid" placeholder="Pick date rage" id="fecharango" name="fecharango"/>
                                    </div>
								  	<button type="button" class="btn btn-primary mt-8"  @click="obtenerCompra()">
									<span class="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                                    </svg>
                                    </span>
									Filtrar
									</button>
								 	<button type="button" class="btn btn-primary mt-8"  @click="AbrirModal('', 'mAgregar',''),obtenerPermiso()">
									<span class="svg-icon svg-icon-2">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
									<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
									<rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
									</svg>
									</span>
									Agregar Compra
									</button>
							<div class="modal fade" id="kt_modal_compra" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                  				<div class="modal-dialog modal-dialog-centered mw-750px">
									   <Compramodal
									    v-if="showModal"
										:TipoModal="this.TipoModal"
										:ProveedorID="this.ProveedorID"
										:SucursalID="this.SucursalID"
										:TipoPolloID="this.TipoPolloID"
										:CodigoMovimiento="this.CodigoMovimiento"
										:CompraID="this.CompraID"
										:CantidadJabas="this.CantidadJabas"
										:CantidadPollo="this.CantidadPollo"
										:PesoBruto="this.PesoBruto"
										:PesoTara="this.PesoTara"
										:PesoNeto="this.PesoNeto"
										:Promedio="this.Promedio"
										:Precio="this.Precio"
										:Importe="this.Importe"
										:DevolucionID="this.DevolucionID"
										:Fecha="this.Fecha"
										></Compramodal>
								</div>
							</div>
						</div>
					</div>
								<div class="ms-4  pt-0">
									<div class="table-responsive">
									<table class="table align-middle table-row-dashed fs-6 gy-5">
										<thead>
											<tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
												<th class="min-w-150px">Proveedor</th>
												<th class="text-center min-w-75px">Fecha</th>
												<th class="text-center min-w-75px">Modificado por</th>
												<th class="text-center min-w-75px">Tipo de Pollo</th>
												<th class="text-center min-w-75px">Peso Neto</th>
												<th class="text-center min-w-75px">Precio</th>
												<th class="text-center min-w-100px">Importe</th>
												<th class="min-w-100px">Sucursal</th>
												<th class="text-center min-w-100px">Acciones</th>
											</tr>
										</thead>
										<tbody class="fw-semibold text-gray-600" v-for="(lstcompra, index) in buscarCompra" :key="index">
											<tr>
											<td class="d-flex align-items-center" style="border: none;">
												<div class=" symbol symbol-circle symbol-50px overflow-hidden">
												    <button type="button" title="Mostrar Devoluciones"  class="btn btn-sm btn-icon btn-info h-30px w-30px" :id="'mostrar'+index" @click="mostrarDevolucion(index)">
													<i class="fas fa-arrow-down"></i>
													</button>
													<button type="button" title="Ocultar Devoluciones" style="display: none;"  class="btn btn-sm btn-icon btn-info h-30px w-30px"  :id="'ocultar'+index" @click="ocultarDevolucion(index)">
													<i class="fas fa-arrow-up"></i>
													</button>
												</div>
												<div class="d-flex flex-column ps-2 fw-bold">
												{{ lstcompra.Nombre }}
												</div>
											</td>
												<td class="text-center pe-0">{{getDateFormat(lstcompra.Fecha)}}</td>
												<td class="text-center pe-0 fw-bold" style="font-size:12px;"><span  v-if="lstcompra.UsuarioActualizacion==null">{{lstcompra.UsuarioRegistroNombre}}</span> <span v-if="lstcompra.UsuarioActualizacion!=null">{{lstcompra.UsuarioActualizacionNombre}}</span></td>
												<td class="text-center pe-0">{{lstcompra.TipoPollo}}</td>
												<td class="text-center pe-0 fw-bold">{{lstcompra.PesoNeto}}</td>
												<td class="text-center pe-0 fw-bold">{{lstcompra.Precio}}</td>
												<td class="text-center fw-bold">{{lstcompra.Importe}}</td>
												<td ><div class="badge badge-light-success fw-bold text-center">{{lstcompra.NombreSucursal}}</div></td>
												<td class="text-end">
													<div class="d-flex justify-content-center flex-shrink-0">
													<button type="button" title="Editar Compra" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 " @click="AbrirModal(lstcompra, 'mEditar','')">
													<i class="fas fa-edit"></i>
													</button>
													<button type="button" title="Registrar Devolución" class="btn btn-info btn-hover-scale btn-sm btn-icon me-1 " @click="AbrirModal(lstcompra, 'mDevolucion','')">
													<i class="fas fa-boxes-packing"></i>
													</button>
													<button type="button" title="Eliminar Compra" @click="AlertEliminarCompra(lstcompra.CompraID,'compra')" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" >
													<i class="fas fa-trash"></i>
													</button >
												</div>
												</td>
											</tr>
											<tr style="display: none;" :id="'devolucion'+index">
												<td colspan="9">
													<table class="table  table-row-dashed " >
																<thead >
																<th colspan="9"  class="text-center p-2 text-muted fw-bold" v-if="lstcompra.Devolucion.length >0">DEVOLUCIONES</th>
																<th colspan="9"  class="text-center p-2 text-muted fw-bold" v-if="lstcompra.Devolucion.length <1">NO HAY DEVOLUCIONES</th>
																</thead>
														<tbody >
														
												<tr v-for="(lstdevolucion, index2) in lstcompra.Devolucion" :key="index2">
												<td class="text-center min-w-150px">
													<div class="text-dark fs-7">Fecha</div>
    												<div class="text-muted fs-7 fw-bold" >{{getDateFormat(lstdevolucion.FechaRegistro)}}</div>
												</td>
												<td class="text-center min-w-100px">
													<div class="text-dark fs-7"># Jabas</div>
    												<div class="text-muted fs-7 fw-bold" >{{lstdevolucion.CantidadJabas}}</div>													
												</td>
												<td class="text-center min-w-75px">
													<div class="text-dark fs-7"># Pollos</div>
    												<div class="text-muted fs-7 fw-bold" >{{lstdevolucion.CantidadPollo}}</div>													
												</td>
												<td class="text-center min-w-75px">
													<div class="text-dark fs-7">Peso Bruto</div>
    												<div class="text-muted fs-7 fw-bold" >{{lstdevolucion.PesoBruto}}</div>													
												</td>
												<td class="text-center min-w-75px">
													<div class="text-dark fs-7">Tipo Pollo</div>
    												<div class="text-muted fs-7 fw-bold" >{{lstdevolucion.TipoPollo}}</div>													
												</td>
												<td class="text-center min-w-75px">
													<div class="text-dark fs-7">Peso Neto</div>
    												<div class="text-muted fs-7 fw-bold" >{{lstdevolucion.PesoNeto}}</div>													
												</td>
												<td class="text-center min-w-75px">
													<div class="text-dark fs-7">Peso Tara</div>
    												<div class="text-muted fs-7 fw-bold" >{{lstdevolucion.PesoTara}}</div>													
												</td>
												<td class="text-center min-w-100px">
													<div class="text-dark fs-7">Importe</div>
    												<div class="text-muted fs-7 fw-bold" >{{lstdevolucion.Importe}}</div>													
												</td>
												<td class="text-end min-w-100px">
													<div class="d-flex justify-content-center flex-shrink-0">
													<button type="button" title="Editar Devolución" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 " @click="AbrirModal(lstcompra, 'mDevolucion',lstdevolucion.DevolucionID)">
													<i class="fas fa-edit"></i>
													</button>
													<button type="button" title="Eliminar Devolución" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" @click="AlertEliminarCompra(lstdevolucion.DevolucionID,'devolucion')">
													<i class="fas fa-trash"></i>
													</button >
												</div></td>
														
														</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									
								</div>
							</div>
						</div>
					</div>
				</div>
      		</div>
     	</div>
    </div>
</template>
<script lang="js">
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
 import Compramodal from '@/components/Compramodal.vue'
import axios from 'axios'

export default defineComponent( {
  name: 'Example',
  components: {
    Menu,
    Header,
	Compramodal,
  },
  data() {
    return {
        BASE_URL: process.env.VUE_APP_BASE_URL,
        TipoModal:"",
        TOKEN:'',
        MODIFICADOR:'',
        Buscar:'',
        UsuarioID:"",
	  	sucursal:[],
		RolUser:"",
		listado_sucursal:[],
        showModal: false,
        showcontenedor:false,
		CodigoMovimiento:"",
		CompraID:"",
		ProveedorID:"",
		SucursalID:"",
		CantidadJabas:"",
		CantidadPollo:"",
		PesoBruto:"",
		PesoTara:"",
		PesoNeto:"",
		Promedio:"",
		Precio:"",
		Importe:"",
		DevolucionID:null,
		TOKEN:'',
	   	fechastart:"",
		fechaend:"",
		año:'',
		dia:'',
		mes:'',
		listabb:[],
		TipoPolloID:'',
		Fecha:'',
		listado_compra:[],
    }
  },
  mounted() {
	this.obtenerPermiso();
	this.obtenerSucursal();
  },
 created() {

	 this.validacionStorage();
	

 },
 
computed:{
       buscarCompra() {
     return this.listado_compra.filter(item => {
                return item.Nombre.toLowerCase().includes(this.Buscar.toLowerCase());
                 });   
       }
    },

methods: {
	fechafiltro(){
			this.fechastart= document.getElementById('fechaini').innerHTML;
				this.fechaend = document.getElementById('fechafini').innerHTML;
	},
		fechaoriginal(){
			var start = moment().subtract(7, 'days');
			var end = moment();
				
			function cb(start, end) {
				 $('#fechaini').html(start.format('YYYY-MM-DD'));
				  $('#fechafini').html(end.format('YYYY-MM-DD'));
			}
			$('#fecharango').daterangepicker({
				startDate: start,
				endDate: end,
			
			}, cb)

			cb(start, end);
				this.fechastart= document.getElementById('fechaini').innerHTML;
				this.fechaend = document.getElementById('fechafini').innerHTML;
		},
	validacionStorage(){
			
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));

                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
	
              },
       obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },

		   getDateFormat(fecha){
			   return moment(fecha).format("YYYY-MM-DD ");
		   },
		   obtenerCompra(){
			this.fechafiltro();
			var sucursalString = this.sucursal.toString()
			axios.post(this.BASE_URL+"/compra/obtener/todo",{SucursalID: sucursalString, FechaInicial: this.fechastart, FechaFinal: this.fechaend},{headers:{'Authorization': this.TOKEN,'Content-Type':'application/x-www-form-urlencoded'}})
        	.then((response) => {
			this.listado_compra=[]
          	this.listado_compra = response.data.Compra;
		});
		   },
	    obtenerSucursal() {
			this.sucursal.push(JSON.parse(localStorage.getItem('sucursal-ID')));
      axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
			this.fechaoriginal();
			this.obtenerCompra();
					if (this.RolUser ==="Administrador") {
						 $('#sucursal').removeAttr('disabled');
				}
			
          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
				multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
	AlertEliminarCompra(valorID,valor){
		Swal.fire({
        text: "Estás seguro de que deseas Eliminar? ",
        icon: "error",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-danger",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
			if (valor == "compra") {
			this.EliminarCompra(valorID);
			}else if (valor == "devolucion") {
			this.EliminarDevolucion(valorID)
			}
            
          Swal.fire({
            text: "Se Eliminó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
             if (result.isConfirmed) {
            
        //    window.location.href = "/compra"
		
             }
          
           
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se eliminó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
	},
	   EliminarCompra(valorID){
        axios.post(this.BASE_URL+"/compra/eliminar", {CompraID: valorID,UsuarioID: this.MODIFICADOR
        },{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
			this.obtenerCompra();
        });

      },
	  EliminarDevolucion(valorID){
		axios.post(this.BASE_URL+"/devolucion/eliminar", {DevolucionID: valorID,UsuarioID: this.MODIFICADOR
				},{headers:{'Authorization': this.TOKEN}})
				.then((response) => {
					this.obtenerCompra();
				});
	  },
	   AbrirModal({ProveedorID,SucursalID,TipoPolloID,Fecha,CodigoCompra,CompraID,CantidadJabas,CantidadPollo,PesoBruto,PesoTara,PesoNeto,Promedio,Precio,Importe},mTipo,DevolucionID){
      
		    var fecha_hoy = moment().format("YYYY-MM-DD ")
			var fecha_compra= moment(Fecha).format("YYYY-MM-DD ")
			var RolUser = JSON.parse(localStorage.getItem('RolNombre'));
	 	this.TipoModal = mTipo;
		this.SucursalID = SucursalID;
		this.ProveedorID= ProveedorID;
		this.DevolucionID=DevolucionID;
		this.CodigoMovimiento = CodigoCompra;
		this.CompraID = CompraID;
		this.CantidadJabas=CantidadJabas;
		this.CantidadPollo=CantidadPollo;
		this.PesoBruto=PesoBruto;
		this.PesoTara=PesoTara;
		this.PesoNeto=PesoNeto;
		this.Promedio=Promedio;
		this.Precio=Precio;
		this.Fecha=Fecha;
		this.Importe=Importe;
		this.TipoPolloID=TipoPolloID;
		if (mTipo==="mEditar" && fecha_hoy== fecha_compra || RolUser==="Administrador") {
		this.showModal=true
         $('#kt_modal_compra').modal('show')
		}else{
			 Swal.fire({
            text: "No puede modificar, consulte con su Administrador",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
		}

		if(mTipo==="mAgregar"){
        this.showModal=true
         $('#kt_modal_compra').modal('show')
		}
      // this.Sucursal = SucursalID

      },
		mostrarDevolucion(index){
		document.getElementById('mostrar'+index).style.display = 'none';
		document.getElementById('ocultar'+index).style.display = 'block';
		document.getElementById('devolucion'+index).style.display = 'table-row'
		
	  },
	ocultarDevolucion(index){
		document.getElementById('ocultar'+index).style.display = 'none';
		document.getElementById('mostrar'+index).style.display = 'block';
		document.getElementById('devolucion'+index).style.display = 'none'
	  }
	

}
})
</script>

<style scoped>
.nonedevolucion{
	display: none;
}
</style>