<template>
  <div id="pruebajs" class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <Menu></Menu>
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" >
         <!--begin::Header-->
              <Header></Header>
                <!--end::Header-->
                {{dataaa}}
      </div>
    </div>
  </div>
</template>

  
<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
import axios from 'axios'


export default {
  name: 'Home',
  components: {
    Menu,
  Header
  },data() {
    return {
      dataaa:JSON.parse(localStorage.getItem('user-data')),
       BASE_URL: process.env.VUE_APP_BASE_URL,
        TOKEN:'',
        MODIFICADOR:'',
        lista_modulo_general:[],
    }
  },
  created() {
    this.obtenerModulo();
  },
  methods: {
    obtenerModulo(){
                   this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                  this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
      	     axios.post(this.BASE_URL+"/modulo/obtener/porusuario",{UsuarioID:this.MODIFICADOR},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
           //this.listado_modulos = response.data.respuesta;
           this.lista_modulo_general = response.data.respuesta.filter(modulo => modulo.Nivel === 2);
  			 localStorage.setItem('Modulos',JSON.stringify(this.lista_modulo_general))
        });
    }
  },
}
</script>
