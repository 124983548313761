<template>
    <div class="modal-content">
        <div class="modal-header" id="kt_modal_rol_header">
            <h2 class="fw-bold">Agregar Saldo</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
                <span class="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <form id="kt_modal_edit_user_form" class="form" action="#">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_edit_user_scroll" data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_edit_user_header"
                    data-kt-scroll-wrappers="#kt_modal_edit_user_scroll" data-kt-scroll-offset="300px"
                    style="max-height: 300px !important">
                    <div class="fv-row mb-7 text-start">
                        <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form" data-placeholder="Seleccione Sucursal" name="lsucursal" id="lsucursal" v-model="lsucursal">
                            <option></option>
                            <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                                {{ lstsucursal.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="fv-row mb-7 text-start">
                        <label class="required fw-semibold fs-6 mb-2">Tipo Saldo:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
                            data-placeholder="Seleccione Tipo Saldo" name="ltipo" id="ltipo" v-model="ltipo" disabled>
                            <option></option>
                            <option v-for="(lsttipo, index) in listado_tipo" :key="index" :value="lsttipo.TipoSaldoID">
                                {{ lsttipo.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="fv-row mb-7 text-start">
                        <label class="required fw-semibold fs-6 mb-2">Nombre:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
                            data-placeholder="Seleccione el nombre" name="ldata" id="ldata" v-model="ldata" disabled>
                            <option></option>
                            <option v-for="(lstdata, index) in listado_datos" :key="index" :value="lstdata.Codigo">
                                {{ lstdata.Nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Fecha:</label>
                            <div class="input-group">
                                <flat-pickr v-model="date" :config="config" class="form-control" placeholder="Seleccione Fecha" name="date" id="fecha"/>
                            </div>
                        </div>
                        <div class="col fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Saldo Inicial:</label>
                            <div class="input-group input-group-solid mb-5">
                                <span class="input-group-text">S/.</span>
                                <input type="number" id="txtsaldo" class="form-control" aria-label="Amount (to the nearest dollar)" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center pt-15">
                    <button @click="cerrarModal()" type="reset" class="btn btn-light me-3"
                        data-kt-users-modal-action="cancel">
                        Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" data-kt-users-modal-action="button"
                        @click="validacioncampos()">
                        <span class="indicator-label">Registrar</span>
                        <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script >
import { defineComponent } from "vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from 'axios';
export default defineComponent({
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            TOKEN: '',
            MODIFICADOR: '',
            listado_sucursal: [],
            listado_tipo: [],
            listado_datos: [],
            date: null,
            año: '',
            dia: '',
            mes: '',
            saldo: '',
            lsucursal: '',
        }
    },
    components: {
        flatPickr
    },
    created() {
        this.validacionStorage();
        this.año = new Date().getFullYear();
        this.dia = new Date().getDate();
        this.mes = new Date().getMonth() + 1;
        this.date = this.año + "-" + this.mes + "-" + this.dia;
    },
    mounted() {
        if (this.lsucursal == null) {
        } else {
        }
        this.obtenerSucursal();
        this.obtenerTipo();
        this.capturarfecha();
    },
    methods: {
        validacionStorage() {
            this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
            this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
            if (this.TOKEN === null) {
                this.$router.push('/login');
            }
        },
        capturarfecha() {
   
            $("#fecha").flatpickr({
                maxDate: this.date,
            })
        },
        validacioncampos() {
            this.saldo = document.getElementById("txtsaldo").value;
            if (this.lsucursal == null || this.ltipo == null || this.ldata == null || this.date == null || this.saldo == '') {
                this.alertValidacion();
            } else {

                this.alerDialogRegistrar();
            }
        },
        alertValidacion() {
            Swal.fire({
                text: "Por favor llenar todos los campos",
                icon: "info",
                buttonsStyling: false,
                confirmButtonText: "Aceptar!",
                customClass: {
                    confirmButton: "btn fw-bold btn-info",
                },
            })
        },
        alerDialogRegistrar() {
            Swal.fire({
                text: "Estás seguro de que deseas Registrar? ",
                icon: "question",
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Si, Registrar!",
                cancelButtonText: "No, Cancelar",
                customClass: {
                    confirmButton: "btn fw-bold btn-primary",
                    cancelButton: "btn fw-bold btn-active-light-primary",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.registrarSaldo();
                    Swal.fire({
                        text: "Se Registró correctamente",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",

                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then((result) => {
                        this.cerrarModal()
                        if (result.isConfirmed) {

                            window.location.href = "/saldo"
                        }


                    });
                } else if (result.dismiss === "cancel") {
                    Swal.fire({
                        text: "No se registró",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-primary",
                        },
                    }).then(function () {

                    });
                }
            });
        },
        registrarSaldo() {
            axios.post(this.BASE_URL + "/saldo/insertar", {
                    TipoSaldoID: this.ltipo,
                    Codigo: this.ldata,
                    Fecha: this.date,
                    SaldoInicial: this.saldo,
                    UsuarioID: this.MODIFICADOR,
                }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                });
                return true;
        },
        obtenerSucursal() {
            axios.get(this.BASE_URL + "/sucursal/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_sucursal = response.data.respuesta;
                    $("#lsucursal")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                            this.listado_datos = [];
                            $('#ltipo').removeAttr('disabled');
                            if (this.ltipo != null) {
                                this.obtenerDatos();
                            }
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                });
        },
        obtenerTipo() {
            axios.get(this.BASE_URL + "/tiposaldo/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_tipo = response.data.respuesta;
                    $("#ltipo")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                            this.listado_datos = [];
                            $("#ldata").removeAttr("disabled");
                            if (this.lsucursal != null) {
                                this.obtenerDatos();
                            }
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                    $("#ldata")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                });
        },
        obtenerDatos() {
            axios.post(
                this.BASE_URL + "/tiposaldo/obtener",
                { SucursalID: this.lsucursal, TipoSaldoID: this.ltipo }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_datos = response.data.respuesta;
                });
        },
        cerrarModal() {
            $("#kt_modal_saldo").modal("hide");
            this.$parent.showModal = false;
        },
    },
});
</script>