<template>
     <div id="kt_aside" class="aside pb-5 pt-5 pt-lg-0" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'80px', '300px': '100px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
        <div class="aside-logo py-8" id="kt_aside_logo">
          <a href="index.html" class="d-flex align-items-center">
            <img alt="Logo" src="resources/assets/media/logos/demo6.jpg" class="h-45px logo" />
          </a>
        </div>
        <div class="aside-menu flex-column-fluid" id="kt_aside_menu">
          <div class="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" data-kt-scroll-offset="5px">
            <div class="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold" id="#kt_aside_menu" data-kt-menu="true">
              <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start" class="menu-item py-2"
                v-for="(lstpadre, index) in lista_padres" :key="index"
                @mouseenter="obtenerHijos(lstpadre)">
                <span class="menu-link menu-center">
                  <span class="menu-icon me-0">
                    <i :class="lstpadre.Icono"></i>
                  </span>
                  <span class="menu-title ">{{lstpadre.Nombre}}</span>
                </span>
                <div class="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px submenu-avicola   hover-scroll-overlay-y"
                  data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-offset="5px">
                  <div data-kt-menu-trigger="click" class="menu-item menu-accordion" v-for="(lsthijo, index) in lista_hijos" :key="index" >
                    <span class="menu-link">
                           <a class="menu-link"  @click="redirigirRuta(lsthijo)">
                      <span class="menu-icon">
                        <i :class="lsthijo.Icono"></i>
                      </span>
                      <span class="menu-title">{{lsthijo.Nombre}}</span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Menu',
  data() {
    return {
       BASE_URL: process.env.VUE_APP_BASE_URL,
        TOKEN:'',
        UsuarioID:"",
        NombreUser:"",
      listado_modulos:[],
      lista_padres:[],
      lista_hijos:[],
      lista_modulo_general:[],
    }
  
  }, created() {
    this.validacionStorage();
  },
  mounted() {
     this.obtenerModulos();
  },
  methods: {
    	validacionStorage(){
                 this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
                 this.UsuarioID = JSON.parse(localStorage.getItem('user-ID'));
                 this.NombreUser =JSON.parse(localStorage.getItem('user-nombre'));
                if(this.TOKEN ===  null){
                this.$router.push('/login');
                }
              },
        obtenerModulos(){
         axios.post(this.BASE_URL+"/modulo/obtener/porusuario",{UsuarioID:this.UsuarioID},{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
           this.listado_modulos = response.data.respuesta;
            this.lista_padres = this.listado_modulos.filter(modulo => modulo.Nivel == 1);
            this.lista_modulo_general = response.data.respuesta.filter(modulo_hijo => modulo_hijo.Nivel === 2);
  			 localStorage.setItem('Modulos',JSON.stringify(this.lista_modulo_general))
        });
    },
    obtenerHijos({ModuloID,Nombre}){
    this.lista_hijos = this.listado_modulos.filter(modulo => modulo.ModuloPadreID == ModuloID);
         localStorage.setItem('ruta-padre',JSON.stringify(Nombre))
    },
    redirigirRuta({Nombre,Ruta}){
       localStorage.setItem('ruta-hijo',JSON.stringify(Nombre))
     window.location.href = Ruta
    },
  },
}
</script>
<style scoped>
.submenu-avicola {
    max-height: 400px;
    min-height: max-content;
    overflow:auto;
    box-sizing: border-box;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
.submenu-avicola::-webkit-scrollbar {
    -webkit-appearance: none;
}
.submenu-avicola::-webkit-scrollbar:vertical {
    width:10px;
}
.submenu-avicola::-webkit-scrollbar-button:increment,.submenu-avicola::-webkit-scrollbar-button {
    display: none;
} 
.submenu-avicola::-webkit-scrollbar:horizontal {
    height: 10px;
}
.submenu-avicola::-webkit-scrollbar-thumb {
    background-color: #c4bebe;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
.submenu-avicola::-webkit-scrollbar-track {
    border-radius: 10px;  
}
</style>

