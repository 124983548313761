<template>
    <div class="modal-content">
        <div class="modal-header" id="kt_modal_rol_header" style="padding-top: 3px; padding-bottom: 3px;">
            <h3 v-if="modo == ModalAgregar" class="fw-bold">Agregar Pago Personal</h3>
            <h3 v-if="modo == ModalEditar" class="fw-bold">Editar Pago Personal</h3>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="cerrarModal()">
                <span class="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="modal-body scroll-y mx-5 mx-xl-15">
            <form id="kt_modal_edit_user_form" class="form" action="#">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_edit_user_scroll" data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_edit_user_header"
                    data-kt-scroll-wrappers="#kt_modal_edit_user_scroll" data-kt-scroll-offset="300px"
                    style="max-height: 300px !important">
                    <div class="row">
                    <div class="col-sm-6 mb-7 text-start">
                        <label class="form-label">Sucursal:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
                            data-placeholder="Seleccione Sucursal" name="sucursalm" id="sucursalm" v-model="this.pago.SucursalID" disabled>
                            <option></option>
                            <option v-for="(lstsucursal, index) in listado_sucursal" :key="index" :value="lstsucursal.SucursalID">
                                {{ lstsucursal.Nombre }}
                            </option>
                        </select>
                    </div>
                        <div class="col-sm-6 fv-col mb-7 text-start">
                            <label class="form-label">Fecha:</label>
                            <div class="input-group">
                                <flat-pickr v-model="pago.Fecha"  class="form-control form-control-solid"
                                        placeholder="Seleccione Fecha" name="fechaPago" id="fechaPago" />
                            </div>
                        </div>

                    </div>

                    <div class="fv-row mb-7 text-start">
                        <label class="form-label">Personal:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
                            data-placeholder="Seleccione Personal" name="personal" id="personal" v-model="this.pago.Codigo" disabled>
                            <option></option>
                            <option v-for="(lstpersonal, index) in listado_personal" :key="index"
                                :value="lstpersonal.Codigo">
                                {{ lstpersonal.NombreCompleto }}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                    <div class="col-sm-6 mb-7 text-start">
                        <label class="form-label">Modalidad de Pago:</label>
                        <select class="form-select form-select-solid" data-dropdown-parent="#kt_modal_edit_user_form"
                            data-placeholder="Modalidad de Pago" name="modalidad" id="modalidad" v-model="this.pago.ModalidadPagoID">
                            <option></option>
                            <option v-for="(lstmodalidad, index) in listado_modalidad" :key="index" :value="lstmodalidad.ModalidadPagoID">
                                {{ lstmodalidad.Nombre }}
                            </option>
                        </select>
                    </div>
                        <div class="col-sm-6 fv-col mb-7 text-start">
                            <label class="required fw-semibold fs-6 mb-2">Monto a Pagar:</label>
                            <div class="input-group input-group-solid mb-5">
                                <span class="input-group-text">S/.</span>
                                <input type="number" id="txtsaldo" class="form-control" v-model="this.pago.Monto"
                                    aria-label="Amount (to the nearest dollar)" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-9 fv-col mb-7 text-start">
                            <label class="fw-semibold fs-6 mb-2">Descripción:</label>
                            <div class="input-group input-group-solid mb-5">
                                <input type="text" v-model="this.pago.Descripcion" placeholder="Ejemplo: Se paó una parte del total" class="form-control" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-sm-3 fv-col mb-7 text-start">
                            <label class="fw-semibold fs-6 mb-2">N° Operación:</label>
                            <div class="input-group input-group-solid mb-5">
                                <input type="text" v-model="this.pago.NumeroOperacion" placeholder="Ejemplo: 84688" class="form-control" utocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <!--begin::Accordion-->
                <div class="accordion my-10" id="kt_accordion_1">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_1_header_2">
                        <button class="accordion-button fs-4 fw-semibold p-3 " type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_2" aria-expanded="false" aria-controls="kt_accordion_1_body_2">
                        Imagen de Referencia
                        </button>
                    </h2>
                    <div id="kt_accordion_1_body_2" class="accordion-collapse collapse show" aria-labelledby="kt_accordion_1_header_2" data-bs-parent="#kt_accordion_1">
                        <div class="accordion-body">
                               <div class="form-group addfile" v-if="!pago.URL">
                               <img v-bind:src="'data:image/'+this.pago.Extension+';base64,'+this.pago.FileBase64" id="imagenbb" alt="" style="border-radius: 15px !important; width: 100%; height: 100%">
                                    <input type="file" name="myfile" id="file" accept="image/*" @change="seleccionar_archivo($event);" class="input-file" />
                                    <label for="file" id="circulo__add"  class="lbl__foto js-labelFile">
                                        <span class="js-fileName"><i class="fas fa-add icon_add_foto"></i>
                                        </span>
                                    </label>
                                    <span class="circulo__x__foto"  id="btnResFoto" @click="resFoto()">&nbsp;X&nbsp;</span>
                                </div>
                                    <div class="form-group addfile" v-if="pago.URL">
                                    <img :src="pago.URL" id="imagen-url" alt="" style="border-radius: 15px !important; width: 100%; height: 100%">
                                    <span class="circulo__x__foto"  id="btnResFotoUrl" @click="resFotoURL()">&nbsp;X&nbsp;</span>
                                </div>
                        </div>
                    </div>
                    </div>
                </div>


                </div>

                <div class="text-center pt-15">
                    <button @click="cerrarModal()" type="reset" class="btn btn-light me-3"
                        data-kt-users-modal-action="cancel">
                        Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" data-kt-users-modal-action="button" v-if="modo == ModalAgregar"
                        @click="validacioncampos()">
                        <span class="indicator-label">Registrar</span>
                    </button>
                    <button type="button" class="btn btn-warning" data-kt-users-modal-action="button" v-if="modo == ModalEditar"
                     @click="validacioncampos()">
                    <span class="indicator-label">Modificar</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script src="">
import { defineComponent } from "vue";
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default defineComponent( {
       components: {
    flatPickr,
  },
        props:[
        "TipoModal",
        "ListaObjeto",
    ],
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            TOKEN: '',
            MODIFICADOR: '',
            listado_sucursal: [],
            listado_personal:[],
            listado_modalidad:[],
            RolUser:'',
            imgTemporal: false,
            ModalAgregar:"agregar",
            ModalEditar:"editar",
            modo: '',
            pago:{
                PagoID:null,
                SucursalID:null,
                TipoPagoID:3,
                ModalidadPagoID:null,
                Codigo:"",
                Descripcion: "",
                Monto:"",
                NumeroOperacion: "",
                FileBase64:null,
                Extension:null,
                Fecha:"",
                UsuarioID: null,
                URL: null,
            }
        }
    },
    created() {
        this.validacionStorage();
        this.año = new Date().getFullYear();
        this.dia = new Date().getDate();
        this.mes = new Date().getMonth() + 1;
        this.pago.Fecha = this.año + "-" + this.mes + "-" + this.dia;
    },
    mounted() {
        this.obtenerModalidad();
        this.obtenerSucursal();
        
        this.Validator();

    },
    methods: {
          fechaModal(){
            $("#fechaPago").flatpickr({
			maxDate: this.pago.Fecha,
		    })
          },
        validacionStorage() {
            this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
            this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
            this.pago.UsuarioID = this.MODIFICADOR ;
            if (this.TOKEN === null) {
                this.$router.push('/login');
            }
        },
        obtenerSucursal() {
          this.pago.SucursalID = JSON.parse(localStorage.getItem('sucursal-ID'));
          axios.get(this.BASE_URL + "/sucursal/listar", { headers: { 'Authorization': this.TOKEN } })
          .then((response) => {
            this.fechaModal();
            this.listado_sucursal = response.data.respuesta;
            this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
            this.listado_personal = [];
            this.obtenerPersonal();
            if (this.RolUser == "Administrador") {
              $('#sucursalm').removeAttr('disabled');
            }
              $("#sucursalm")
                .select2()
                .on("select2:select", (e) => {
                  const event = new Event("change", {
                  bubbles: true,
                  cancelable: true,
                  });
                  e.params.data.element.parentElement.dispatchEvent(event);
                  this.listado_personal = [];
                  this.obtenerPersonal();                  
                  })
                  .on("select2:unselect", (e) => {
                  const event = new Event("change", {
                  bubbles: true,
                  cancelable: true,
                  });
                  e.params.data.element.parentElement.dispatchEvent(event);
                  });
                    
                });
        },
        obtenerPersonal() {
          axios.post(this.BASE_URL + "/personal/obtener/porsucursal",{ SucursalID: this.pago.SucursalID }, { headers: { 'Authorization': this.TOKEN } })
          .then((response) => {
          this.listado_personal = response.data.respuesta;
           $("#personal")
            .select2()
            .on("select2:select", (e) => {
            const event = new Event("change", {
            bubbles: true,
            cancelable: true,
            });
            e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
            const event = new Event("change", {
            bubbles: true,
            cancelable: true,
            });
            e.params.data.element.parentElement.dispatchEvent(event);
            });
            });
        },
        obtenerModalidad() {
            axios.get(this.BASE_URL + "/modalidadpago/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_modalidad = response.data.respuesta;
                    $("#modalidad")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                    
                });
        },
    seleccionar_archivo(event) {
      const image = $('#imagenbb')
      const btnadd = $('#circulo__add')
      const btnfoto = $('#btnResFoto')
      const file = event.target.files[0];
      const reader = new FileReader();
      // this.imgTemporal = true;
      this.pago.Extension = file.type.replace("image/", "")
      reader.onload = () =>
      this.pago.FileBase64 = reader.result.replace(/^data:image\/[a-z]+;base64,/, "");
      
      reader.readAsDataURL(file)
      btnadd.css({display:'none'})
      image.css({ display: 'block' })
      btnfoto.css({ display: 'block' })
    },
    resFoto() {
      this.pago.FileBase64 =null;
      this.pago.Extension =null;
      const image = $('#imagenbb');
      const btnadd = $('#circulo__add');
      const btnfoto = $('#btnResFoto');
      image.css({ display: 'none' });
      btnadd.css({display:'block'});
      btnfoto.css({ display: 'none' });
    },
        resFotoURL(){
        this.pago.URL = null;
    },
    Validator(){
            if (this.TipoModal == this.ModalEditar) {
                this.modo = this.ModalEditar;
                this.obtenerPago();
            }else if(this.TipoModal == this.ModalAgregar){
                $('#personal').removeAttr('disabled');
                this.modo = this.ModalAgregar;
            }
        },
    validacioncampos(){
        if (this.modo == this.ModalAgregar) {
            if (this.pago.SucursalID != null && this.pago.ModalidadPagoID != null && this.pago.Codigo != ""
                && this.pago.Monto != "" && this.pago.Fecha != "" && this.pago.UsuarioID != null) {
                    this.alertaRegistrar();
                }else{
                    this.alertValidacion();
                }
        }else if (this.modo == this.ModalEditar) {
            if (this.pago.SucursalID != null && this.pago.ModalidadPagoID != null && this.pago.Codigo != ""
                && this.pago.Monto != "" && this.pago.Fecha != "" && this.pago.UsuarioID != null) {
                    this.alertaModificar();
                }else{
                    this.alertValidacion();
                }
        }
        },
    RegistrarPago(){
          axios.post(this.BASE_URL + "/pago/insertar",this.pago, { headers: { 'Authorization': this.TOKEN } })
          .then((response) => {

          })

    },
       ModificarPago(){
          axios.post(this.BASE_URL + "/pago/actualizar",this.pago, { headers: { 'Authorization': this.TOKEN } })
          .then((response) => {

          })

    },
        obtenerPago(){
        this.pago.PagoID = this.ListaObjeto.PagoID;
        this.pago.Codigo = this.ListaObjeto.Codigo;
        this.pago.Fecha = this.ListaObjeto.Fecha;
        this.pago.SucursalID = this.ListaObjeto.SucursalID;
        this.pago.ModalidadPagoID = this.ListaObjeto.ModalidadPagoID;
        this.pago.Monto = this.ListaObjeto.Monto;
        this.pago.Descripcion = this.ListaObjeto.Descripcion;
        this.pago.NumeroOperacion = this.ListaObjeto.NumeroOperacion;
        this.pago.URL = this.ListaObjeto.URL;
    },
    LimpiarPago(){
        this.resFoto();
        this.pago.PagoID = null;
        this.pago.Codigo = null;
        this.pago.ModalidadPagoID = null;
        this.pago.Monto = null;
        this.pago.Descripcion = null;
        this.pago.NumeroOperacion = null;
        this.pago.URL = null;
        this.obtenerPersonal();
        this.obtenerModalidad();
    },
        alertValidacion(){
            Swal.fire({
            text: "Por favor llenar todos los campos",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            
            customClass: {
              confirmButton: "btn fw-bold btn-info",
            },
          })
    },
  alertaRegistrar(){
    Swal.fire({
        text: "Estás seguro de que deseas Registrar? ",
        icon: "question",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Registrar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.RegistrarPago();
            Swal.fire({
            text: "Se Registró correctamente, ¿desea registrar otro pago?",
            icon: "success",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Si, Registrar!",
            cancelButtonText: "No, Cancelar",
            customClass: {
            confirmButton: "btn fw-bold btn-primary",
            cancelButton: "btn fw-bold btn-active-light-primary",
            },
          }).then((result) =>  {
  
             if (result.isConfirmed) {
                this.LimpiarPago();
             }  else if (result.dismiss === "cancel") {
                this.cerrarModal()
                // window.location.href = "/abono"
             } 
          });
          // Swal.fire({
          //   text: "Se Registró correctamente",
          //   icon: "success",
          //   buttonsStyling: false,
          //   confirmButtonText: "Aceptar!",
            
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-primary",
          //   },
          // }).then((result) =>  {
          //   this.cerrarModal()
          //    if (result.isConfirmed) {
            
          //   window.location.href = "/pago_personal"
          //    }
          
           
          // });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se registró",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
            // not Remove current row
          
          });
        }
      });
  },
     alertaModificar() {
      Swal.fire({
        text: "Estás seguro de que deseas Modificar? ",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, Modificar!",
        cancelButtonText: "No, Cancelar",
        customClass: {
          confirmButton: "btn fw-bold btn-warning",
          cancelButton: "btn fw-bold btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
              this.ModificarPago();
          Swal.fire({
            text: "Se modificó correctamente",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then((result) =>  {
            this.cerrarModal()
             if (result.isConfirmed) {
            //  window.location.href = "/pago_personal"
             }   
          });
        } else if (result.dismiss === "cancel") {
          Swal.fire({
            text: "No se modificó",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Aceptar!",
            customClass: {
              confirmButton: "btn fw-bold btn-primary",
            },
          }).then(function () {
          });
        }
      });
    },
    cerrarModal() {
            $("#kt_modal_pago").modal("hide");
            this.$parent.showModal = false;
             this.$parent.obtenerPagos();
        },
    },
})
</script>
<style scoped>
/* input file style */
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + .js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.input-file + .js-labelFile .icon:before {
  content: "";
}
.input-file + .js-labelFile.has-file .icon:before {
  content: "";
  color: #5aac7b;
}


.imagen-acta{
  background: #f9d799;
  position: relative;
  margin: 5px auto;
}

.imagen-acta img{
  width: 50% !important;
}
.imagen-acta   .opciones-upload {
  position: absolute;
  overflow: hidden;
  top: 15px;
  right: 0px;
}
.imagen-acta 
input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.imagen-acta   .btn {
  width: 100% !important;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px !important;
}
#imagenbb {
  display: none;
}
#btnResFoto{
  display: none;
}
#circulo__add{
  margin-top: 50px;
}


.circulo__x__foto{
  cursor: pointer;
  position: relative;
right: 0px;
float: right;
  bottom: 248px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: bold;
  color: #fff;
  background: #5D5D5D;
  font-size: 12px !important;
}
.icon_add_foto{
  border: 1px solid;
  border-radius: 50px !important;
  padding: 1px;
  color: #8a8a8a;
}
.lbl__foto{
  font-size: 80px;
}
.addfile{
    
        width: 100% !important;
        border: 1px solid #8a8a8a;
    height: 240px;
     border-radius: 15px !important;
     cursor: pointer;
    }
</style>