<template>
      <div v-if="showcontenedor"  class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <Menu></Menu>

      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <Header></Header>
        		<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
						<!--begin::Container-->
						<div id="kt_content_container" class="container-xxl">
							<!--begin::Products-->
							<div class="card card-flush">
								<!--begin::Card header-->
								<div class="card-header align-items-center py-5 gap-2 gap-md-5">
									<!--begin::Card title-->
									<div class="card-title">
										<!--begin::Search-->
										<div class="d-flex align-items-center position-relative mt-9">
											<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
											<span class="svg-icon svg-icon-1 position-absolute ms-4">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
													<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
												</svg>
											</span>
											<!--end::Svg Icon-->
											<input type="text" data-kt-ecommerce-order-filter="search" class="form-control form-control-solid w-250px ps-14" placeholder="Search Report" />
										</div>

										<!--end::Search-->
										<!--begin::Export buttons-->
										<div id="kt_ecommerce_report_sales_export" class="d-none"></div>
										<!--end::Export buttons-->
									</div>
									<!--end::Card title-->
									<!--begin::Card toolbar-->
									<div class="card-toolbar flex-row-fluid justify-content-end gap-5">
												<div class="align-items-center position-relative  text-start  w-250px">
										<label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
										
										<select class="form-select form-select-solid"
										data-dropdown-parent="#kt_content"
										data-placeholder="Seleccione Sucursal" multiple="multiple" 
											data-close-on-select="false"
										name="sucursal[]" id="sucursal" v-model="sucursal" disabled>
										<option></option>
										<option v-for="(lstsucursal, index) in listado_sucursal" :key="index" v-bind:value="lstsucursal.SucursalID">
											{{ lstsucursal.Nombre }}
										</option>
										</select>
									</div>
										<!--begin::Daterangepicker-->
									<div class="fv-row text-start" >
                                        <label class="form-label">Fecha:</label>
										<label id="fechaini" style="display:none" ></label>
										<label id="fechafini" style="display:none"></label>
										 <input class="form-control form-control-solid" placeholder="Pick date rage" id="fecharango" name="fecharango"/>
                               
                                    </div>
								  <button type="button" class="btn btn-primary mt-8"  @click="obtenerCompra()">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
							<span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
								<!--end::Svg Icon-->Filtrar
							</button>
										<!--begin::Export dropdown-->
								  <button type="button" class="btn btn-primary mt-8"  @click="AbrirModal('', 'mAgregar'),obtenerPermiso()">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
								<span class="svg-icon svg-icon-2">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
									<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
									<rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
								</svg>
								</span>
								<!--end::Svg Icon-->Agregar Compra
							</button>
								
										<!--end::Export dropdown-->
							<div class="modal fade" id="kt_modal_compra" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                  				<div class="modal-dialog modal-dialog-centered mw-750px">
									   <Compramodal
									    v-if="showModal"
										:TipoModal="this.TipoModal"
										:ProveedorID="this.ProveedorID"
										:SucursalID="this.SucursalID"
										:CodigoMovimiento="this.CodigoMovimiento"
										:CompraID="this.CompraID"
										:CantidadJabas="this.CantidadJabas"
										:CantidadPollo="this.CantidadPollo"
										:PesoBruto="this.PesoBruto"
										:PesoTara="this.PesoTara"
										:PesoNeto="this.PesoNeto"
										:Promedio="this.Promedio"
										:Precio="this.Precio"
										:Importe="this.Importe"
										></Compramodal>
								</div>
							</div>
									</div>
									<!--end::Card toolbar-->
								</div>
								<!--end::Card header-->
								<!--begin::Card body-->
								<div class="card-body pt-0">
									<div class="table-responsive">
									<!--begin::Table-->
									<table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_report_sales_table">
										<!--begin::Table head-->
										<thead>
											<!--begin::Table row-->
											<tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
												<th class="min-w-100px">Proveedor</th>
												<th class="min-w-100px">Sucursal</th>
												<th class="text-center min-w-75px">Fecha</th>
												<th class="text-center min-w-75px">Peso Neto</th>
												<th class="text-center min-w-75px">Precio</th>
												<th class="text-center min-w-100px">Importe</th>
												<th class="text-center min-w-100px">Acciones</th>
											</tr>
											<!--end::Table row-->
										</thead>
										<!--end::Table head-->
										<!--begin::Table body-->
										<tbody class="fw-semibold text-gray-600">
											<!--begin::Table row-->
											<tr v-for="(lstcompra, index) in listado_compra" :key="index">
												<!--begin::Date=-->
												<td class="text-start pe-0">{{lstcompra.Nombre}}</td>
												<td class="text-start pe-0">{{lstcompra.NombreSucursal}}</td>
												<!--end::Date=-->
												<!--begin::No Orders=-->
												<td class="text-center pe-0">{{getDateFormat(lstcompra.Fecha)}}</td>
												<!--end::No Orders=-->
												<!--begin::Products sold=-->
												<td class="text-center pe-0">{{lstcompra.PesoNeto}}</td>
												<!--end::Products sold=-->
												<!--begin::Tax=-->
												<td class="text-center pe-0">{{lstcompra.Precio}}</td>
												<!--end::Tax=-->
												<!--begin::Total=-->
												<td class="text-center">{{lstcompra.Importe}}</td>
												<!--end::Total=-->
												 <td class="text-end">
													<div class="d-flex justify-content-center flex-shrink-0">
													<button type="button" class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1 " @click="AbrirModal(lstcompra, 'mEditar')">
													<i class="fas fa-edit"></i>
													</button>
													<button type="button" @click="AlertEliminarCompra(lstcompra)" class="btn btn-danger btn-hover-scale btn-sm btn-icon me-1" >
													<i class="fas fa-trash"></i>
													</button >
												</div>
												</td>
											</tr>
										
											<!--end::Table row-->
										</tbody>
										<!--end::Table body-->
									</table>
		


									<!--end::Table-->
									</div>
									
								</div>
		
								<!--end::Card body-->
							</div>
							<!--end::Products-->
						</div>
						<!--end::Container-->
					</div>
      </div>
     </div>
    </div>
</template>
<script lang="js">
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
// import Examplemodal from '@/components/Examplemodal.vue'
import axios from 'axios'
export default defineComponent( {
  name: 'Example',
  components: {
    Menu,
    Header,
    // Examplemodal
  },
  data() {
    return {
         BASE_URL: process.env.VUE_APP_BASE_URL,
         TipoModal:"",
         TOKEN:'',
         MODIFICADOR:'',
         Buscar:'',
         UsuarioID:"",
        showModal: false,
        showcontenedor:false,
    }
  },
  mounted() {
    this.obtenerPermiso();
  },
 created() {

 },
 
computed:{

    },

methods: {
       obtenerPermiso(){
               var pathname = window.location.pathname,firstObj=null, lista_mod=[];
               lista_mod= JSON.parse(localStorage.getItem('Modulos'))
               var results = lista_mod.filter(modulo => modulo.Ruta === pathname);
               firstObj = (results.length > 0) ? results[0] : null;
               if(firstObj== null || firstObj== ""){
                this.showcontenedor=false
                window.location.href = "/home"
                }else{
                  this.showcontenedor=true
                }
           },
	

}
})
</script>