<template>
    <div class="d-flex flex-column flex-root">
        <div class="page d-flex flex-row flex-column-fluid">
            <Menu></Menu>
            <div class="wrapper d-flex flex-column flex-row-fluid">
                <Header></Header>
                <div id="Contenedor" class="container-xxl">
                    <div class="card">
                        <div class="row card-header border-0 pt-6">
                            <div class="col-sm-4 text-start">
                                <!--begin::Label-->
                                <label class="required fw-semibold fs-6 mb-2">Tipo Saldo:</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <select class="form-select form-select-solid" data-dropdown-parent="#Contenedor"
                                    data-placeholder="Seleccione Tipo Saldo" name="stiposaldo" id="stiposaldo"
                                    v-model="stiposaldo">
                                    <option></option>
                                    <option v-for="(lsttipo, index) in listado_tipo" :key="index"
                                        :value="lsttipo.TipoSaldoID">
                                        {{ lsttipo.Nombre }}
                                    </option>
                                </select>
                                <!--end::Input-->
                            </div>
                            <div class="col-sm-4 text-start">
                                <label class="required fw-semibold fs-6 mb-2">Fecha:</label>
                                <div class="input-group">
                                    <flat-pickr v-model="date" :config="config" class="form-control text-center"
                                        placeholder="Seleccione Fecha" name="date" id="date" />
                                </div>
                            </div>
                            <div class="col-sm-4 text-bottom my-9">
                                <button type="button" class="btn btn-primary w-200px"
                                    @click="alertValidacion()">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->Filtrar
                                </button>
                            </div>
                        </div>
                        <div class="row card-header border-0 ">
                            <div class="col-sm-4 text-start mt-9">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                                    <span class="svg-icon svg-icon-1 position-absolute ms-2 mt-3">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                                                rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                    <input type="text" data-kt-user-table-filter="search"
                                        class="form-control form-control-solid ps-10" v-model="buscarsaldo"
                                        placeholder="Buscar Nombre" />
                            </div>
                            <div class="col-sm-4 text-start">
                                <!--begin::Label-->
                                <label class="required fw-semibold fs-6 mb-2">Sucursal:</label>
                                <!--end::Label-->
                                <!--begin::Input-->
                                <select class="form-select form-select-solid" data-dropdown-parent="#Contenedor"
                                    data-placeholder="Seleccione Sucursal" name="sucursal" id="sucursal"
                                    v-model="sucursal" multiple="multiple" data-control="select2"
                                    data-close-on-select="false" disabled>
                                    <option></option>
                                    <option v-for="(lstsucursal, index) in listado_sucursal" :key="index"
                                        :value="lstsucursal.SucursalID">
                                        {{ lstsucursal.Nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4 text-bottom my-9">
                                <button type="button" class="btn btn-primary w-200px" @click="accionsaldo()">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->Agregar Saldo
                                </button>
                            </div>
                        </div>
                         <div class="modal fade" id="kt_modal_saldo" tabindex="-1" aria-hidden="true"
                                data-bs-backdrop="static" data-bs-keyboard="false">
                                <div class="modal-dialog modal-dialog-centered mw-650px">
                                    <Saldomodal v-if="showModal">
                                    </Saldomodal>
                                </div>
                            </div>
                            <div class="modal fade" id="kt_modal_editar" tabindex="-1" aria-hidden="true"
                                data-bs-backdrop="static" data-bs-keyboard="false">
                                <div class="modal-dialog modal-dialog-centered mw-650px">
                                    <EditarSaldomodal v-if="showModal"
                                    :DatosSaldo=this.datossaldo>
                                    </EditarSaldomodal>
                                </div>
                            </div>

                        <div class="card-body py-4">
                            <div class="table-responsive container">
                                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                    <thead>
                                        <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th class="min-w-150px text-center" scope="col">Nombre</th>
                                            <th class="min-w-110px text-center" scope="col">Nombre Sucursal</th>
                                            <th v-if="this.stiposaldo == 2" class="min-w-125px text-center" scope="col">Zona</th>
                                            <th class="min-w-100px text-center" scope="col">Primer Saldo</th>
                                            <th class="min-w-100px text-center" scope="col">Saldo Anterior</th>
                                            <th v-if="this.stiposaldo == 2" class="min-w-100px text-center" scope="col">Venta del día</th>
                                            <th v-else class="min-w-100px text-center" scope="col">Compra del día</th>
                                             <th class="min-w-100px text-center" scope="col">Devoluciones</th>
                                            <th class="min-w-100px text-center" scope="col">Pago del día</th>  
                                            <th class="min-w-100px text-center" scope="col">Saldo a la Fecha</th>
                                            <th class="w-100px text-center" scope="col">Accion</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 fw-semibold">
                                        <tr v-for="(listasaldo) in BuscarSaldo" :key="listasaldo.SaldoID">
                                            <td class="text-center">{{ listasaldo.Nombre }}</td>
                                            <td class="text-center">{{ listasaldo.NombreSucursal }}</td>
                                            <td v-if="this.stiposaldo == 2" class="text-center">{{ listasaldo.NombreZona}}</td>
                                            <td class="text-center">S/{{ listasaldo.PrimerSaldo }}</td>
                                            <td class="text-center">S/{{ listasaldo.SaldoInicial }}</td>
                                            <td v-if="this.stiposaldo == 2" class="text-center">S/{{ listasaldo.Venta }}</td>
                                            <td v-if="this.stiposaldo != 2" class="text-center">S/{{ listasaldo.Compra }}</td>
                                             <td class="text-center">S/{{ listasaldo.Devolucion }}</td>
                                            <td class="text-center">S/{{ listasaldo.Pago }}</td>
                                            <td class="text-center">S/{{ listasaldo.SaldoFinal }}</td>
                                            <td>
                                                <div class="">
                                                    <button type="button"
                                                        @click="editarsaldo(listasaldo)"
                                                        class="btn btn-warning btn-hover-scale btn-sm btn-icon me-1">
                                                        <!-- <i class="fas fa-trash"></i> -->
                                                        <i class="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="js">
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Saldomodal from '@/components/Saldomodal.vue'
import EditarSaldomodal from '@/components/EditarSaldomodal.vue'
import axios from 'axios';

export default {
    name: "Saldo",
    data() {
        return {
            BASE_URL: process.env.VUE_APP_BASE_URL,
            TOKEN: '',
            MODIFICADOR: '',
            showModal: false,
            buscarsaldo:'',
            saldo: '"',
            datossaldo:null,
            listado_sucursal: [],
            listado_tipo: [],
            listado_saldo: [],
            date: '',
            año: '',
            dia: '',
            mes: '',

            sucursal:[],
            RolUser:'',
            stiposaldo:'',
        }
    },
    components: {
        Header,
        Menu,
        flatPickr,
        Saldomodal,
        EditarSaldomodal
    },
    created() {
        this.validacionStorage();
        this.año = new Date().getFullYear();
        this.dia = new Date().getDate();
        this.mes = new Date().getMonth() + 1;
        this.date = this.año + "-" + this.mes + "-" + this.dia;
    },
    mounted() {
        this.obtenerSucursal();
        this.obtenerTipoSaldo();
    },
    computed: {
        BuscarSaldo() {
            if (this.listado_saldo.length>0) {
            return this.listado_saldo.filter(item => {
                return item.Nombre.toLowerCase().includes(this.buscarsaldo.toLowerCase())
                //    item.NombreCompleto.toLowerCase().includes(this.BuscarUsuario.toLowerCase())
            });
            }

        },
    },

    methods: {
        validacionStorage() {
            this.TOKEN = JSON.parse(localStorage.getItem('TOKEN'));
            this.MODIFICADOR = JSON.parse(localStorage.getItem('user-ID'));
            if (this.TOKEN === null) {
                window.location.href = "/login"
            }
        },
        capturarfecha() {
            $("#date").flatpickr({
                maxDate: this.date,
            })
        },
        alertValidacion() {
            if (this.stiposaldo =='') {
                 Swal.fire({
                text: "Por favor llenar todos los campos",
                icon: "info",
                buttonsStyling: false,
                confirmButtonText: "Aceptar!",
                customClass: {
                    confirmButton: "btn fw-bold btn-info",
                },
            })
            }else{
                this.obtenerSaldo();
            }
           
        },
        getDateFormat(fecha) {
            return moment(fecha).format("DD/MM/YYYY hh:mm a");
        },
       	    obtenerSucursal() {
			this.sucursal.push(JSON.parse(localStorage.getItem('sucursal-ID')));
      axios.get(this.BASE_URL+"/sucursal/listar",{headers:{'Authorization': this.TOKEN}})
        .then((response) => {
          this.listado_sucursal = response.data.respuesta;
 			this.RolUser = JSON.parse(localStorage.getItem('RolNombre'));
			this.capturarfecha();
			this.obtenerSaldo();
			if (this.RolUser ==="Administrador") {
			$('#sucursal').removeAttr('disabled');
				}
			
          $("#sucursal")
            .select2()
            .on("select2:select", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
				multiple:true
              });
              e.params.data.element.parentElement.dispatchEvent(event);
            })
            .on("select2:unselect", (e) => {
              const event = new Event("change", {
                bubbles: true,
                cancelable: true,
              });
             e.params.data.element.parentElement.dispatchEvent(event);
            });
         
        });
    },
        obtenerTipoSaldo() {
            axios.get(this.BASE_URL + "/tiposaldo/listar", { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_tipo = response.data.respuesta;
                    $("#stiposaldo")
                        .select2()
                        .on("select2:select", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                            this.listado_saldo = [];

                        })
                        .on("select2:unselect", (e) => {
                            const event = new Event("change", {
                                bubbles: true,
                                cancelable: true,
                            });
                            e.params.data.element.parentElement.dispatchEvent(event);
                        });
                });
        },
        obtenerSaldo() {
           var sucursalString = this.sucursal.toString()
            axios.post(this.BASE_URL + "/saldo/obtener", {
                    SucursalID: sucursalString,
                    TipoSaldoID: this.stiposaldo,
                    Fecha: this.date,
                }, { headers: { 'Authorization': this.TOKEN } })
                .then((response) => {
                    this.listado_saldo=[]
                    this.listado_saldo=response.data.respuesta;
                });
        },
        accionsaldo() {
            this.showModal = true;
            $('#kt_modal_saldo').modal('show')
        },
        editarsaldo(datos) {
            this.datossaldo = datos;
            this.showModal = true;
            $('#kt_modal_editar').modal('show')
        },
    },
}
</script>